import React, { useEffect, useState } from 'react'
import * as S from './PromoCodeSelector.styles'
import { translateManager as t } from '../../../services/TranslateManager'
import { withApi } from '../../../api/apiProvider'
import { promoCodeManager } from '../../../services/PromoCodeManager'
import {
  WELCOME_COUPON,
  DISCOUNT_TYPES,
} from '../../../stores/promoCodeSlice/promoCodeConsts'
import {
  calculateDiscountPercent,
  formatPriceWithPrecision,
  isSmallScreen,
} from '../../../utils/utils'
import {
  BookSize,
  MATERIAL_TYPES,
  TILE_SIZES,
} from '@mixtiles/web-backend-shared'
import { usePricing } from '../../Pricing/PricingProvider'
import { analytics, EVENT_NAMES } from '../../../services/Analytics/Analytics'
import Coupon from './Coupon'
import { getSizeName } from '../../../services/SizesManager'
import promoCutLine from '../../../icons/assets/PromoCutLine.png'
import useOnMount from '../../../hooks/useOnMount'
import { getGiftCardsBalance } from '../../../api/giftCards.api'
import { countryManager } from '../../../services/CountryManager'
import SkeletonRow from '../../SkeletonRow/SkeletonRow'
import { isValidGiftCard } from '../../../pages/GiftCard/utils'
import { useProductType } from '../../../services/ProductTypesManager'
import { PRODUCT_TYPES } from '../../../services/ProductTypeState'
import { isValidForProductType } from '../../../stores/promoCodeSlice/promoCodeUtils'
import { experimentManager } from '../../../services/ExperimentManager/ExperimentManager'

function PromoCodeSelector({ onDismiss, setShowPromoCodeForm, source }) {
  const { productType } = useProductType()
  const [selectedDiscountCoupon, setSelectedDiscountCoupon] = useState(
    promoCodeManager.getDiscountCoupon() || ''
  )
  const [defaultDiscount, setDefaultDiscount] = useState(null)
  const [defaultOriginalBundlePrice, setDefaultOriginalBundlePrice] =
    useState(null)
  const discountCoupons = promoCodeManager.getAllDiscountCoupons()
  const tileCreditCoupons = promoCodeManager.getAllTileCredits()
  const [giftCardCredits, setGiftCardCredits] = useState([])
  const { fetchDiscountsForPromoCode, fetchPricingItem, pricingData } =
    usePricing()
  const pricingItem =
    productType === PRODUCT_TYPES.PHOTO_BOOK
      ? null
      : fetchPricingItem({
          tileSize: TILE_SIZES.SQUARE_8X8,
          materialType: MATERIAL_TYPES.CLASSIC,
        })

  useOnMount(() => {
    async function fetchDefaultDiscount() {
      let params = {}
      switch (productType) {
        case PRODUCT_TYPES.PHOTO_BOOK:
          params = {
            promocode: null,
            tileSize: BookSize.BOOK_8X8,
          }
          break
        case PRODUCT_TYPES.BUSINESS:
          params = {
            promocode: null,
            tileSize: TILE_SIZES.SQUARE_8X8,
            materialType: MATERIAL_TYPES.CLASSIC,
            forceProductType: PRODUCT_TYPES.CLASSIC,
          }
          break
        default:
          params = {
            promocode: null,
            tileSize: TILE_SIZES.SQUARE_8X8,
            materialType: MATERIAL_TYPES.CLASSIC,
          }
      }
      const discountForPromoCode = await fetchDiscountsForPromoCode(params)
      setDefaultDiscount(discountForPromoCode)
    }

    fetchDefaultDiscount()
  })

  useOnMount(() => {
    async function loadCurrentBalances() {
      const giftCodes = promoCodeManager.getGiftCardCreditsCodes()
      if (giftCodes.length > 0) {
        const balances = await getGiftCardsBalance(
          giftCodes,
          countryManager.getCountry()
        )
        setGiftCardCredits(
          balances.filter((balance) => {
            if (isValidGiftCard(balance)) {
              return true
            }
            promoCodeManager.removeGiftCardCreditsCode(balance.code)
            return false
          })
        )
        if (experimentManager.isEnabled('checkout-v2')) {
          // TODO call handlePromoCodeChange from store
        } else {
          promoCodeManager.promoCodeSubject.next({ giftCard: true })
        }
      }
    }

    loadCurrentBalances()
  })

  useEffect(() => {
    if (defaultDiscount && !defaultDiscount.isLoading && pricingItem?.value) {
      setDefaultOriginalBundlePrice(
        pricingItem.value * defaultDiscount.value.metadata.bundleTiles
      )
    }
  }, [defaultDiscount, pricingItem?.value])

  function onDone() {
    const activeCoupon = promoCodeManager.getDiscountCoupon()
    analytics.track(EVENT_NAMES.PROMO_CODE_CHANGED, {
      source,
      'Selected Code': selectedDiscountCoupon?.code || WELCOME_COUPON,
      'Selected Type':
        promoCodeManager.getDiscountCouponType(selectedDiscountCoupon) ||
        DISCOUNT_TYPES.BUNDLE,
      'Previous Code': activeCoupon?.code || WELCOME_COUPON,
      'Previous Type':
        promoCodeManager.getDiscountCouponType(activeCoupon) ||
        DISCOUNT_TYPES.BUNDLE,
      'Coupon Change':
        (selectedDiscountCoupon?.code || WELCOME_COUPON) !==
        (activeCoupon?.code || WELCOME_COUPON),
    })
    promoCodeManager.setDiscountCoupon(selectedDiscountCoupon)
    onDismiss()
  }

  function promoCodeTapped(value, selectedValue) {
    if (value.code === selectedValue.code) {
      return
    }
    analytics.track(EVENT_NAMES.PROMO_CODE_SELECTED, {
      Source: source,
      'Selected Code': value?.code || WELCOME_COUPON,
      'Selected Type':
        promoCodeManager.getDiscountCouponType(value) || DISCOUNT_TYPES.BUNDLE,
      'Previous Code': selectedDiscountCoupon?.code || WELCOME_COUPON,
      'Previous Type':
        promoCodeManager.getDiscountCouponType(selectedDiscountCoupon) ||
        DISCOUNT_TYPES.BUNDLE,
    })
    setSelectedDiscountCoupon(value)
  }

  function onAddPromoCodeClicked() {
    analytics.track(EVENT_NAMES.ADD_PROMO_CODE_TAPPED, { Source: source })
    setShowPromoCodeForm(true)
  }

  const price = (value) => formatPriceWithPrecision(value, pricingData.currency)
  // If the it is a MIX coupon we want to use the highest percentage
  const getDiscountTextAndPercentage = (
    discount,
    type,
    productTranslationKey,
    originalBundlePrice
  ) => {
    switch (type) {
      case DISCOUNT_TYPES.BUYOVER:
        if (pricingData.isLoading) {
          return { text: '', value: -1 }
        }
        return {
          text: t.get(
            'order.checkout.payment_summary_rows.buy_over_percent_off_orders_over_price',
            {
              v1: discount.metadata.buyOverDiscount,
              v2: `${price(discount.metadata.buyOverPrice)}`,
            }
          ),
          value: discount.metadata.buyOverDiscount,
        }
      case DISCOUNT_TYPES.BUYXGETY:
        const { buy, get } = discount?.metadata
        const percentOff = Math.round((get / (buy + get)) * 100)
        return {
          text: t.get(
            'general.promo_code_success_dialog.save_up_to_message_shortened',
            { v1: discount?.metadata?.percentOff || percentOff.toFixed(2) }
          ),
          value: discount?.metadata?.percentOff || percentOff,
        }
      case DISCOUNT_TYPES.PERCENT:
        if (discount?.metadata?.productType) {
          return {
            text: t.get(
              'general.promo_code_success_dialog.save_message_on_product',
              {
                v1: `${discount?.discount?.percent_off}%`,
                v2: t.get(productTranslationKey),
              }
            ),
            value: discount?.discount?.percent_off,
          }
        }
        return {
          text: t.get(
            'general.promo_code_success_dialog.save_up_to_message_shortened',
            { v1: discount?.discount?.percent_off }
          ),
          value: discount?.discount?.percent_off,
        }
      case DISCOUNT_TYPES.BUNDLE:
      default:
        return {
          text: t.get(
            'general.promo_code_success_dialog.save_up_to_message_shortened',
            {
              v1: `${calculateDiscountPercent(
                discount?.metadata?.bundlePrice,
                originalBundlePrice
              )}`,
            }
          ),
          value: originalBundlePrice
            ? calculateDiscountPercent(
                discount?.metadata?.bundlePrice,
                originalBundlePrice
              )
            : 0,
        }
    }
  }

  function getDiscountText(discount, originalBundlePrice) {
    if (!discount) {
      return
    }
    const promoCodeType = promoCodeManager.getDiscountCouponType(discount)
    const productTranslationKey =
      productType === PRODUCT_TYPES.PHOTO_BOOK
        ? 'Photo Books'
        : 'product_selection.tiles_name'
    if (!isValidForProductType(discount, productType)) {
      return t.get('general.promo_code_selector.promo_not_applied_to_product', {
        v1: t.get(productTranslationKey).toLowerCase(),
      })
    }
    if (
      !originalBundlePrice &&
      ![
        DISCOUNT_TYPES.PERCENT,
        DISCOUNT_TYPES.BUYOVER,
        DISCOUNT_TYPES.BUYXGETY,
        DISCOUNT_TYPES.MIX,
      ].includes(promoCodeType)
    ) {
      return <S.CouponSkeleton width={120} height={10} />
    }

    if (promoCodeType === DISCOUNT_TYPES.MIX) {
      // get the maximum discount
      // example for a metadata that we want to get the maximum from:
      // {DEFAULT: "{\"buyOverPrice\":10,\"discountType\":\"BUYOVER\",\"buyOverDiscount\":30}",
      // SQUARE_8X8: "{\"buyOverPrice\":10,\"discountType\":\"BUYOVER\",\"buyOverDiscount\":40}",
      // productType: "classic",
      // discountType: "MIX"}
      const bestDiscount = Object.values(discount.metadata).reduce(
        (acc, innerDiscountRaw) => {
          let innerDiscount
          try {
            innerDiscount = JSON.parse(innerDiscountRaw)
          } catch (_) {
            return acc
          }
          const textAndPercentage = getDiscountTextAndPercentage(
            { metadata: innerDiscount },
            innerDiscount.discountType,
            productTranslationKey,
            pricingItem?.value * innerDiscount.bundleTiles
          )

          if (acc.value > textAndPercentage.value) {
            return acc
          } else {
            return textAndPercentage
          }
        },
        { text: '', value: -1 }
      )
      return t.get(
        'general.promo_code_success_dialog.save_up_to_message_shortened',
        {
          v1: bestDiscount.value,
        }
      )
    } else {
      return getDiscountTextAndPercentage(
        discount,
        promoCodeManager.getDiscountCouponType(discount),
        productTranslationKey,
        originalBundlePrice
      ).text
    }
  }

  return (
    <S.Container>
      <S.TopBar>
        {isSmallScreen() && <S.CloseButton onClick={onDismiss} />}
        <S.Title>{t.get('general.menu.promo_code_manager.promo_code')}</S.Title>
        <S.DoneButton onClick={onDone} data-testid="promo-code-done-button">
          {t.get('general.done')}
        </S.DoneButton>
      </S.TopBar>
      {giftCardCredits.map(({ code, balance, initialValue }) => (
        <>
          <SingleCouponSection
            key={code}
            headerTitle={t.get('general.menu.gift_card')}
            title={code}
            subtitle={
              initialValue && !isNaN(balance) && pricingData.currency ? (
                t.get('general.promo_code_selector.gift_card_description', {
                  v1: formatPriceWithPrecision(
                    initialValue,
                    pricingData.currency
                  ),
                  v2: formatPriceWithPrecision(balance, pricingData.currency),
                })
              ) : (
                <SkeletonRow width={50} height={15} />
              )
            }
          />
          <And />
        </>
      ))}
      {tileCreditCoupons.length > 0 &&
        tileCreditCoupons.map((coupon) => (
          <>
            <SingleCouponSection
              key={coupon.code}
              headerTitle={t.get('general.promo_code_selector.free_tiles')}
              title={coupon.sourceCoupon}
              subtitle={t.get(
                'general.promo_code_selector.free_tile_description',
                {
                  v1: coupon.amount,
                  v2:
                    coupon?.metadata?.tileSizesList?.length !== 1
                      ? ''
                      : getSizeName(coupon.metadata.tileSizesList[0]),
                }
              )}
              dataTestId={coupon.sourceCoupon}
            />
            <And />
          </>
        ))}
      <S.CouponContainer>
        <S.DiscountCouponCategoryHeader>
          <S.CouponCategoryTitle>
            <S.BoldText>
              {discountCoupons.length
                ? t.get('general.promo_code_selector.select_volume_discount')
                : t.get('general.promo_code_selector.volume_discount_applied')}
            </S.BoldText>
            <S.Text>{t.get('general.promo_code_selector.disclaimer')}</S.Text>
          </S.CouponCategoryTitle>
        </S.DiscountCouponCategoryHeader>
        <S.PromoCutLine src={promoCutLine} />
        <Coupon
          key={WELCOME_COUPON}
          title={`${WELCOME_COUPON}  (${t.get(
            'general.promo_code_selector.default'
          )})`}
          subtitle={getDiscountText(
            defaultDiscount?.value,
            defaultOriginalBundlePrice
          )}
          onClick={() => promoCodeTapped('', selectedDiscountCoupon)}
          value={undefined}
          selectedValue={selectedDiscountCoupon.code}
          isLastCoupon={discountCoupons.length === 0}
          dataTestId={WELCOME_COUPON}
        />
        {discountCoupons.map((discount, idx) => {
          const originalBundlePrice =
            pricingItem?.value * discount.metadata.bundleTiles
          return (
            <Coupon
              key={discount.pseudonym || discount.code}
              title={discount.pseudonym || discount.code}
              subtitle={getDiscountText(discount, originalBundlePrice)}
              onClick={() => promoCodeTapped(discount, selectedDiscountCoupon)}
              value={discount.code}
              selectedValue={selectedDiscountCoupon.code}
              isLastCoupon={idx === discountCoupons.length - 1}
              isDisabled={!isValidForProductType(discount, productType)}
              dataTestId={discount.pseudonym || discount.code}
            />
          )
        })}
        <S.AddPromoCode
          onClick={onAddPromoCodeClicked}
          data-testid="add-promo-code"
        >
          {t.get('general.menu.add_promo_code.modal.title_with_gift_card')}
        </S.AddPromoCode>
      </S.CouponContainer>
    </S.Container>
  )
}

// A section with a single coupon which is enabled
function SingleCouponSection({ headerTitle, title, subtitle, dataTestId }) {
  return (
    <S.CouponContainer>
      <S.FreeTilesCouponCategoryHeader>
        <S.CouponCategoryTitle>
          <S.BoldText>{headerTitle}</S.BoldText>
        </S.CouponCategoryTitle>
      </S.FreeTilesCouponCategoryHeader>
      <S.PromoCutLine src={promoCutLine} />
      <Coupon
        title={title}
        subtitle={subtitle}
        value=""
        selectedValue=""
        isLastCoupon
        dataTestId={dataTestId}
      />
    </S.CouponContainer>
  )
}

function And() {
  return (
    <S.Ands key="and">
      <S.AndLineLeft />
      <S.And>{t.get('login.terms_of_use.and')}</S.And>
      <S.AndLineRight />
    </S.Ands>
  )
}

export default withApi(PromoCodeSelector)
