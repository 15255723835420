import StorageManager from './StorageManager'
import { v4 as uuid } from 'uuid'
import { countryManager } from './CountryManager'
import { COUNTRIES, getDefaultCountryId } from '../config/countries-data'
import UserManager from './UserManager'
import { ORDER_TYPE_ART } from '../utils/OrderType'
import { mixtilesAxios } from '../utils/ApiUtils'
import { experimentManager } from './ExperimentManager/ExperimentManager'
import { normalizeAddress } from '../stores/addressSlice/addressUtils'

const KEY_ORDER_LOCAL_ID = 'orderLocalId'

export function getOrderLocalId() {
  let localId = StorageManager.get(KEY_ORDER_LOCAL_ID)
  if (!localId) {
    localId = uuid()
    StorageManager.set(KEY_ORDER_LOCAL_ID, localId)
  }
  return localId
}

export function resetOrderLocalId() {
  StorageManager.set(KEY_ORDER_LOCAL_ID, null)
}

export async function fetchDeliveryPromise(
  address,
  orderType,
  topQueue,
  uploads,
  variantsIds,
  productType
) {
  const params = {
    userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    tileType: uploads[0]?.tileType,
    pricingCountry: countryManager.getPricingCountry(),
    destinationCountry:
      COUNTRIES[
        address && address.countryId ? address.countryId : getDefaultCountryId()
      ].code,
    address: address ? normalizeAddress(address) : {},
    topQueue: !!topQueue,
    uploads,
    customerEmail: UserManager.getUserEmail(),
    variantsIds,
    productType,
    isPickupEnabled: experimentManager.isEnabled('hubbox-rollout'),
  }

  if (orderType === ORDER_TYPE_ART) {
    params.artOrder = true
    params.wallDecorationOrder = true
    params.pricingCountry = 'US' // @TODO(ART): Remove when art supports different currencies
  }
  return mixtilesAxios
    .post('v4/shippingOptions', params)
    .then((response) => response.data)
}
