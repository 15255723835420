import {
  isCenterpiece,
  PHOTO_STYLES,
  FILTERS,
  MAT_COLORS,
  MATTING,
  MATERIAL_TYPES,
} from '@mixtiles/web-backend-shared'
import uniq from 'lodash/uniq'
import { translateManager as t } from './TranslateManager'
import { isFramedMaterial } from '../utils/materialTypeUtils'
import { FRAME_COLORS } from '../core/Tile/consts'

export function getTileStyleData(tile) {
  let tileStyleData

  if (tile.customStyle) {
    tileStyleData = tile.customStyle
  } else {
    if (typeof tile.style === 'object') {
      tileStyleData = tile.style
    } else {
      tileStyleData = PHOTO_STYLES[tile.style] || {}
    }
  }

  if (!isMattingEnabledForMaterial({ materialType: tile.materialType })) {
    tileStyleData = {
      mattingColor: MAT_COLORS.NONE,
      matting: MATTING.NONE,
      filter: tileStyleData.filter,
      frameColor: tileStyleData.frameColor,
      lastFramedColor: tileStyleData.lastFramedColor,
    }
  }
  return tileStyleData
}

export function tilesHasMixOfStyling(tiles, propertyPredicate) {
  const tilesProperty = tiles
    .filter((t) => !isCenterpiece(t))
    .map((tile) => getTileStyleData(tile))
    .map(propertyPredicate)
    .filter(Boolean)
  return uniq(tilesProperty).length > 1
}

export function tilesHaveMixedMaterials(tiles) {
  return uniq(tiles.map((t) => t.materialType)).length > 1
}

export function tilesHaveMixedSizes(tiles) {
  return (
    uniq(tiles.filter((t) => !isCenterpiece(t)).map((t) => t.tileSize)).length >
    1
  )
}

export function tilesHaveWoodFrame({ tiles, material, mixColorPrice }) {
  if (!mixColorPrice) {
    return false
  }
  if (material !== MATERIAL_TYPES.CLASSIC) {
    return false
  }
  const uniqColors = uniq(
    tiles
      .filter((tile) => !isCenterpiece(tile))
      .map((tile) => tile?.customStyle?.frameColor || tile?.style?.color)
  )
  return uniqColors.includes(FRAME_COLORS.WOOD)
}

export function tilesHaveMixedColors({ tiles, material, mixColorPrice }) {
  const uniqColors = uniq(
    tiles.filter((t) => !isCenterpiece(t)).map((t) => t.customStyle.frameColor)
  )
  return (
    uniqColors.length > 1 &&
    tilesHaveWoodFrame({ tiles, material, mixColorPrice })
  )
}

export function isMattingEnabledForMaterial({ materialType }) {
  return isFramedMaterial(materialType)
}

export function getFrameName(frame) {
  return t.get(`order.frame_colors.${frame}`)
}

export const FILTER_GROUPS = [
  [{ id: FILTERS.NONE, displayName: 'Original' }],
  [
    { id: FILTERS.SILVER, displayName: 'Silver' },
    { id: FILTERS.NOIR, displayName: 'Noir' },
  ],
  [
    { id: FILTERS.VIVID, displayName: 'Vivid' },
    { id: FILTERS.DRAMATIC, displayName: 'Dramatic' },
  ],
]
