import React, { type ReactNode } from 'react'
import {
  ThemeProvider as StyledThemeProvider,
  StyleSheetManager,
} from 'styled-components'
import {
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
} from '@mui/material'
import SnackbarProvider from './elements/Snackbar/SnackbarProvider'
import { AppErrorFallbackWrapper } from './components/AppErrorFallback/AppErrorFallback'
import { muiTheme, theme } from 'mixtiles-web-common/elements/Theme/photosTheme'
import { type Theme } from '@material-ui/core'
import {
  TilesSourceContext,
  TilesSourceProvider,
  loadTilesSource,
  type TilesSourceContextValue,
} from 'components/TilesProvider/TilesSourceContext'
import { TILES_SOURCE } from 'components/TilesProvider/TilesProvider.consts'
import { domAnimation, LazyMotion } from 'framer-motion'

import SideMenuProvider from 'components/SideMenu/SideMenuProvider'
import { AuthProvider } from './services/AuthProvider/AuthProvider'
import { ApiProvider } from './api/apiProvider'
import { DialogProvider } from './elements/Dialog/DialogProvider'
import { PricingProvider } from './components/Pricing/PricingProvider'
import { AccountProvider } from './components/Account/AccountProvider'
import EmbeddedLoginProvider from './components/EmbeddedLogin/EmbeddedLoginProvider'
import ProductTypeProvider from './services/ProductTypesManager'
import { type PricingData } from './components/Pricing/types/PricingProvider.types'
import { StoreProvider } from './stores/store'
import { JUMBLE_URL_PREFIX } from 'config/config'
import { useLocation } from '@remix-run/react'
import { useUserSettings } from 'services/UserSettingsProvider'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const tileSourceDefaultValue: TilesSourceContextValue = {
  tilesSource: TILES_SOURCE.PHOTO_STYLER,
  updateTilesSource: () => {},
}

export default function DefaultProviders({
  children,
  initialPricingData,
}: {
  children: ReactNode
  initialPricingData: PricingData
}) {
  const location = useLocation()
  const userSettings = useUserSettings()

  let tilesInitialSource = loadTilesSource(userSettings.settings.tilesSource)
  if (location.pathname.startsWith(JUMBLE_URL_PREFIX)) {
    tilesInitialSource = TILES_SOURCE.JUMBLE
  }

  return (
    <StyleSheetManager enableVendorPrefixes>
      <TilesSourceContext.Provider value={tileSourceDefaultValue}>
        <StyledThemeProvider theme={theme}>
          <MuiThemeProvider theme={muiTheme}>
            <StyledEngineProvider injectFirst>
              <ProductTypeProvider>
                <AuthProvider>
                  <ApiProvider>
                    <DialogProvider>
                      <StoreProvider initialPricingData={initialPricingData}>
                        <PricingProvider
                          initialPricingData={initialPricingData}
                        >
                          <AccountProvider>
                            <AppErrorFallbackWrapper>
                              <LazyMotion features={domAnimation} strict>
                                <EmbeddedLoginProvider>
                                  <SideMenuProvider>
                                    <SnackbarProvider>
                                      <TilesSourceProvider
                                        initialSource={tilesInitialSource}
                                      >
                                        {children}
                                      </TilesSourceProvider>
                                    </SnackbarProvider>
                                  </SideMenuProvider>
                                </EmbeddedLoginProvider>
                              </LazyMotion>
                            </AppErrorFallbackWrapper>
                          </AccountProvider>
                        </PricingProvider>
                      </StoreProvider>
                    </DialogProvider>
                  </ApiProvider>
                </AuthProvider>
              </ProductTypeProvider>
            </StyledEngineProvider>
          </MuiThemeProvider>
        </StyledThemeProvider>
      </TilesSourceContext.Provider>
    </StyleSheetManager>
  )
}
