import { TILE_SIZES } from '@mixtiles/web-backend-shared'

export const TILE_ANIMATION_DURATION_SECONDS = 0.25

const PIXELS_PER_INCH = 96 // It takes 96 pixes to represent an inch
const LIFE_TO_WEB_TILE_SCALE = 0.31 // How much smaller a web component of a tile is compared to real life. This is the magic number.
export const TILE_PIXEL_SCALE = PIXELS_PER_INCH * LIFE_TO_WEB_TILE_SCALE

export const TILE_VERSIONS = {
  FLAT: 'flat',
  THREE_DIMENSIONS: '3d',
  MINIMAL: 'minimal',
}

export const SPLIT_SIZES = [
  TILE_SIZES.SPLIT_24X24,
  TILE_SIZES.SPLIT_24X32,
  TILE_SIZES.SPLIT_27X40,
  TILE_SIZES.SPLIT_32X24,
  TILE_SIZES.SPLIT_40X27,
]

export const TILE_TYPES = {
  FRAMED: 'framed',
  FRAMELESS: 'frameless',
}

export const FRAME_TYPES = {
  EDGE: 'Edge',
  BOLD: 'Bold',
  CLASSIC: 'Classic',
  CLEAN: 'Clean',
  EVER: 'Ever',
  NATURAL: 'Natural',
  CLEVER: 'Clever',
}

export const FILTER_NAMES = {
  EDGE: 'frameless',
  BOLD: 'candid',
  CLASSIC: 'classic',
  CLEAN: 'clean',
  EVER: 'ever',
  NATURAL: 'natural',
  CLEVER: 'clever',
}

export const FRAME_COLORS = {
  BLACK: 'black',
  WHITE: 'white',
  WOOD: 'wood',
  NONE: 'none',
}

export const IMAGE_FILTER_TYPES = {
  MONOCHROME: 'monochrome',
  SEPIA: 'sepia',
  FUNK: 'funk',
  MOON: 'moon',
  NONE: 'none',
}

export const LEGACY_FRAME_COLOR_MAPPER = {
  FRAMELESS: FRAME_COLORS.NONE,
  BLACK: FRAME_COLORS.BLACK,
  WHITE: FRAME_COLORS.WHITE,
  WOOD: FRAME_COLORS.WOOD,
}

export const TILESIZES_TO_REVIEW_SCREEN_PIXELS: Partial<
  Record<TILE_SIZES, { width: number; height: number }>
> = {
  [TILE_SIZES.SQUARE_4X4]: { width: 152, height: 152 },
  [TILE_SIZES.RECTANGLE_4X6]: { width: 152, height: 176 },
  [TILE_SIZES.RECTANGLE_6X4]: { width: 176, height: 152 },
  [TILE_SIZES.RECTANGLE_6X8]: { width: 176, height: 235 },
  [TILE_SIZES.RECTANGLE_8X6]: { width: 235, height: 176 },
  [TILE_SIZES.SQUARE_8X8]: { width: 235, height: 235 },
  [TILE_SIZES.SQUARE_12X12]: { width: 250, height: 250 },
  [TILE_SIZES.SQUARE_20X20]: { width: 250, height: 250 },
  [TILE_SIZES.RECTANGLE_11X8]: { width: 270, height: 196 },
  [TILE_SIZES.RECTANGLE_8X11]: { width: 196, height: 270 },
  [TILE_SIZES.HONEYCOMB_11X10]: { width: 225, height: 196 },
  [TILE_SIZES.HONEYCOMB_10X11]: { width: 196, height: 225 },
  [TILE_SIZES.RECTANGLE_12X16]: { width: 217, height: 290 },
  [TILE_SIZES.RECTANGLE_16X12]: { width: 290, height: 217 },
  [TILE_SIZES.RECTANGLE_16X20]: { width: 232, height: 290 },
  [TILE_SIZES.RECTANGLE_20X16]: { width: 290, height: 232 },
  [TILE_SIZES.RECTANGLE_20X27]: { width: 217, height: 290 },
  [TILE_SIZES.RECTANGLE_27X20]: { width: 290, height: 217 },
  [TILE_SIZES.RECTANGLE_27X36]: { width: 217, height: 290 },
  [TILE_SIZES.RECTANGLE_36X27]: { width: 290, height: 217 },
  [TILE_SIZES.RECTANGLE_25X8]: { width: 330, height: 110 },
  [TILE_SIZES.RECTANGLE_22X44]: { width: 165, height: 330 },
  [TILE_SIZES.RECTANGLE_44X22]: { width: 330, height: 165 },
  [TILE_SIZES.SQUARE_30X30]: { width: 300, height: 300 },
  [TILE_SIZES.SQUARE_40X40]: { width: 350, height: 350 },
}
