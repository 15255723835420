import {
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteError,
  useRouteLoaderData,
} from '@remix-run/react'
import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix'
import { BodyScripts, HeadScripts, KeysScript } from './scripts'
import 'App.scss'
import { UserContext, type UserContextValue } from 'services/UserProvider'
import { useInitClientServices } from './root/rootUtils.client'
import * as importedMeta from './root/meta'
import { translateManager } from 'services/TranslateManager'
import { KeysContext } from 'services/KeysProvider'
import { LangContext } from 'services/LangProvider'
import { ExperimentContext } from 'services/ExperimentManager/ExperimentContext'
import { UserDeviceContext } from 'services/UserDeviceContext'
import DefaultProviders from 'DefaultProviders'
import { useInitExperimentClient } from './root/rootHelpers'
import { useEffect, useState } from 'react'
import { useReloadOnNewServiceWorker } from '../hooks/useReloadOnNewServiceWorker'
import UserManager from '../services/UserManager'
import { UserSettingsProvider } from 'services/UserSettingsProvider'
import PromoCodeDialogContainer from 'components/PromoCodeDialogContainer/PromoCodeDialogContainer'
import { DarkModeWarningPopUp } from 'components/DarkModeDetector/DarkModeWarningPopUp'
import { rootLoader } from './root/loader.server'
import type { MainLayoutData } from './root/types'
import { Head as RootHead } from './root/head'
import RootClientInit from './root/RootClientInit'

export const meta = importedMeta.meta
export const links = importedMeta.links
export const loader = rootLoader
export const Head = RootHead

function RootLayout({ children }: { children: React.ReactNode }) {
  const data = useRouteLoaderData<typeof loader>('root')

  if (!data) {
    // Error state layout
    return (
      <>
        <div id="root">{children}</div>
        <Scripts />
      </>
    )
  }

  return <MainLayout data={data}>{children}</MainLayout>
}

function MainLayout({
  children,
  data,
}: {
  children: React.ReactNode
  data: MainLayoutData
}) {
  const {
    keys,
    user: initialUser,
    language,
    features,
    experimentsUserId,
    initialPricingData,
    userSettings,
    utmParams,
    userDeviceData,
  } = data

  const [user, setUser] = useState(initialUser)

  const experimentClient = useInitExperimentClient({
    features,
    keys,
    userId: experimentsUserId,
    language,
    utmParams,
  })

  if (!translateManager.didInit) {
    translateManager.init(language)
  }

  if (useInitClientServices) {
    useInitClientServices(user)
  }

  useEffect(() => {
    UserManager.subscribeToUserChange((user: UserContextValue) => setUser(user))
  }, [])

  return (
    <>
      <Head />

      <UserSettingsProvider initialSettings={userSettings}>
        <UserDeviceContext.Provider value={userDeviceData}>
          <ExperimentContext.Provider value={experimentClient}>
            <LangContext.Provider value={language}>
              <KeysContext.Provider value={keys}>
                <UserContext.Provider value={user}>
                  <DefaultProviders initialPricingData={initialPricingData}>
                    <PromoCodeDialogContainer />
                    <DarkModeWarningPopUp />
                    <RootClientInit />

                    <div id="root">{children}</div>
                  </DefaultProviders>
                </UserContext.Provider>
              </KeysContext.Provider>
            </LangContext.Provider>
          </ExperimentContext.Provider>
        </UserDeviceContext.Provider>
      </UserSettingsProvider>

      <KeysScript keys={keys} />

      <ScrollRestoration />
      <Scripts />

      <HeadScripts keys={keys} />
      <BodyScripts keys={keys} />
    </>
  )
}

// @ts-ignore
export const Layout = withSentry(RootLayout)

export default function App() {
  useReloadOnNewServiceWorker()
  return <Outlet />
}

export function ErrorBoundary() {
  const error = useRouteError()
  console.error(error)

  captureRemixErrorBoundaryError(error)

  return <div>Error</div>
}
