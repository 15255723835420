import React, { type PropsWithChildren, useEffect, useRef } from 'react'
import * as S from './VisibilityDetector.styles'
import useElementIntersection from '../../hooks/useElementIntersection'

const VISIBILITY_THRESHOLD = 0.5

export default function VisibilityDetector({
  key,
  onVisible,
  triggerDelay = 0,
  children,
  className,
}: PropsWithChildren<{
  key?: string
  onVisible: () => void
  triggerDelay?: number
  className?: string
}>) {
  const { elementRef, intersectionRatio } =
    useElementIntersection<HTMLDivElement>({
      threshold: [VISIBILITY_THRESHOLD],
    })
  const liveIntersectionRef = useRef(intersectionRatio)
  const [wasVisible, setWasVisible] = React.useState(false)
  const timeoutRef = useRef<NodeJS.Timeout>()

  useEffect(() => {
    liveIntersectionRef.current = intersectionRatio
    if (!wasVisible && intersectionRatio >= VISIBILITY_THRESHOLD) {
      timeoutRef.current = setTimeout(() => {
        if (
          liveIntersectionRef.current >= VISIBILITY_THRESHOLD &&
          !wasVisible
        ) {
          setWasVisible(true)
          onVisible()
        }
      }, triggerDelay)
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [intersectionRatio, wasVisible, triggerDelay, onVisible])

  return (
    <div key={key} ref={elementRef} className={className}>
      {
        children || (
          <S.EmptyBottom />
        ) /* Create an empty child if no children given */
      }
    </div>
  )
}
