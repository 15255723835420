import { type StateCreator } from 'zustand/vanilla'
import { type Store } from '../store.types'
import { type PaymentSlice } from './paymentSlice.types'
import { mixtilesAxios } from '../../utils/ApiUtils'
import {
  initializePayPalAccountName,
  initializeStripeAccountName,
  isPaymentMethodGoogleOrApple,
  isReusablePaymentMethod,
} from './paymentUtils'
import { logger } from '../../services/logger'
import { PaymentMethodType } from '../../services/PaymentManager.types'
import { translateManager as t } from '../../services/TranslateManager'
import {
  KEY_STRIPE_ACCOUNT_NAME,
  KEY_STRIPE_CUSTOMER,
  STRIPE_ACCOUNTS_DATA,
} from './paymentConsts'
import { analytics } from '../../services/Analytics/Analytics'
import { getOrderLocalId } from '../../services/OrderUtils'
import StorageManager from '../../services/StorageManager'
import { isClient } from '../../utils/runtimeUtils'

export const createPaymentSlice: StateCreator<Store, [], [], PaymentSlice> = (
  set,
  get
) => ({
  stripeCustomer: null,
  stripeAccountName: isClient() ? initializeStripeAccountName() : null,
  stripePaymentMethodId: null,
  paymentElementMethodType: null,
  paymentMethodType: null,
  paypalAccountName: isClient() ? initializePayPalAccountName() : null,
  setPaypalAccountName: (accountName: string) =>
    set(() => ({ paypalAccountName: accountName })),
  setPaymentMethodType: (type: PaymentMethodType) => {
    analytics.track('Checkout - Payment Method Added', { paymentMethod: type })
    return set(() => ({ paymentMethodType: type }))
  },
  getSelectedPaymentMethodType: () => {
    if (
      get().paymentMethodType === PaymentMethodType.CREDIT_CARD &&
      !get().stripePaymentMethodId
    ) {
      return null
    }
    return get().paymentMethodType
  },
  paymentInfo: { stripeCustomerId: null },
  paymentCurrentCard: null,
  paypalTransactionId: null,
  setPaypalTransactionId: (transactionId: string) =>
    set(() => ({ paypalTransactionId: transactionId })),
  getSelectedCard: () => {
    if (get().stripeCustomer == null || !get().paymentCurrentCard) {
      return null
    }
    return get().paymentCurrentCard
  },
  loadCardFromStripe: async () => {
    try {
      const paymentMethods = await mixtilesAxios.post(
        'v3/stripe/listPaymentMethods',
        {
          customer: get().stripeCustomer?.id,
          stripeAccountName: get().stripeAccountName,
        }
      )
      if (paymentMethods.data.success.data.length === 0) {
        return
      }

      return set(() => ({
        // save the last card added by the user to the local storage
        paymentCurrentCard: paymentMethods.data.success.data[0].card,
        // save the payment method id to the local storage.
        // this will be required later to make payments
        stripePaymentMethodId: paymentMethods.data.success.data[0].id,
      }))
    } catch (error) {
      logger.error('Failed to load card from stripe', error)
    }
  },
  loadStripeCustomerForAccount: (account: any) => {
    const { paymentConfig } = account
    if (paymentConfig) {
      logger.info(
        `loadStripeCustomerFromAccount: ${paymentConfig.stripeCustomerId} stripeAccount: ${paymentConfig.stripeAccount}`
      )
      set(() => ({
        stripeAccountName: paymentConfig.stripeAccount,
        stripeCustomer: { id: paymentConfig.stripeCustomerId },
        paymentMethodType: PaymentMethodType.CREDIT_CARD,
      }))
    }
  },
  shouldLoadCardFromStripe: () => {
    return (
      get().stripeCustomer?.id &&
      (!get().paymentCurrentCard || !get().stripePaymentMethodId)
    )
  },
  getPaymentMethodForOrder: (payPalTransactionId: string = '') => {
    const selectedPaymentMethodType = get().getSelectedPaymentMethodType()
    switch (selectedPaymentMethodType) {
      case PaymentMethodType.CREDIT_CARD:
        return {
          type: selectedPaymentMethodType,
          ...get().getSelectedCard(),
        }
      case PaymentMethodType.PAYPAL:
        return {
          type: PaymentMethodType.PAYPAL,
          transactionId: payPalTransactionId || get().paypalTransactionId,
        }
      case undefined:
      case null:
        logger.warning('Received selected payment method without a type.', {
          paymentMethodId: get().stripePaymentMethodId,
        })
        return null
      default:
        return {
          type: selectedPaymentMethodType,
          paymentMethodId: get().stripePaymentMethodId,
        }
    }
  },
  canMakeAppleOrGooglePay: async ({ stripe }: { stripe: any }) => {
    const stripeAccountName = get().stripeAccountName
    if (!stripeAccountName) throw new Error('Stripe is not initialized')
    const stripeAccount = STRIPE_ACCOUNTS_DATA[stripeAccountName]
    const paymentRequest = stripe.paymentRequest({
      country: stripeAccount.country,
      currency: stripeAccount.bankCurrency,
      total: {
        label: t.get('order.checkout.payment_summary_rows.total'),
        amount: 0,
      },
      displayItems: [],
      requestPayerEmail: true,
      requestPayerName: true,
      // TODO: add request shipping and convert address to our format.
      // requestShipping: true,
    })
    const result = await paymentRequest.canMakePayment()
    return {
      ...get().canMakeWalletPay(paymentRequest, result),
    }
  },
  canMakeWalletPay: (paymentRequest: any, stripePaymentResult: any) => {
    const methodTypes = [
      PaymentMethodType.APPLE_PAY,
      PaymentMethodType.GOOGLE_PAY,
    ]
    const result = {}
    for (const methodType of methodTypes) {
      const hasWallet = stripePaymentResult?.[methodType]
      const literalMethodType = PaymentMethodType.APPLE_PAY ? 'Apple' : 'Google'
      const canMakeKey = `canMake${literalMethodType}Pay`
      if (hasWallet) {
        const analyticsProperty = `Has ${literalMethodType} Pay`
        analytics.setUserProperties({ [analyticsProperty]: true })

        if (!get().getSelectedPaymentMethodType()) {
          analytics.track(`${literalMethodType} Pay Set By Default`)
          set(() => ({ paymentMethodType: methodType }))
        }
        Object.assign(result, {
          canMakePayment: true,
          paymentRequest,
          paymentType: methodType,
          [canMakeKey]: true,
        })
      } else {
        Object.assign(result, {
          [canMakeKey]: false,
        })
      }
    }
    return result
  },
  getStripeKey: () => {
    const stripeAccountName = get().stripeAccountName
    switch (stripeAccountName) {
      case 'US':
        return window.KEYS.stripeUSKey
      case 'NL':
        return window.KEYS.stripeNLKey
      default:
        logger.warning('Missing stripe key for given stripe account name', {
          stripeAccountName,
        })
        return window.KEYS.stripeUSKey
    }
  },
  savePaymentDataLocally: (
    paymentMethodType: PaymentMethodType,
    paymentData: any
  ) => {
    // set the payment method
    // save the payment method id to the local storage. This will be required later to make payments.
    // Store paymentElementMethodType (or remove it for ApplePay and PayPal)
    set(() => ({
      paymentMethodType,
      stripePaymentMethodId: paymentData.id,
      paymentElementMethodType:
        isPaymentMethodGoogleOrApple(paymentMethodType) ||
        paymentMethodType === PaymentMethodType.PAYPAL
          ? null
          : paymentMethodType,
      paymentCurrentCard:
        paymentMethodType === PaymentMethodType.CREDIT_CARD
          ? // save the last card added by the user to the local storage
            paymentData.card
          : // Remove the card data from local storage because we have overridden KEY_PAYMENT_METHOD_ID
            null,
    }))
  },
  savePaymentMethodToCustomer: async (
    stripePaymentMethod: any,
    email: string
  ) => {
    let response: any
    if (get().stripeCustomer?.id === null) {
      // if there is no stripe customer, we then create a new one
      const data = {
        paymentMethodId: stripePaymentMethod.id,
        email,
        stripeAccountName: get().stripeAccountName,
      }
      logger.info('Creating new stripe customer', data)
      response = await mixtilesAxios.post('v3/stripe/createCustomer', data)
    } else if (stripePaymentMethod.id !== get().stripePaymentMethodId) {
      // if stripe customer exists, we attach this new
      // payment method to it.
      const data = {
        paymentMethodId: stripePaymentMethod.id,
        customerId: get().stripeCustomer?.id,
        stripeAccountName: get().stripeAccountName,
        email,
      }
      response = await mixtilesAxios.post('v3/stripe/attachPaymentMethod', data)
    }
    if (response.data && response.data.success) {
      set(() => ({ stripeCustomer: response.data.success }))
    } else if (response.data.error) {
      throw response.data.error
    }
  },
  addStripePaymentMethod: async (
    stripePaymentMethod: any,
    email: string,
    paymentMethodType = PaymentMethodType.CREDIT_CARD
  ) => {
    if (isReusablePaymentMethod(paymentMethodType)) {
      await get().savePaymentMethodToCustomer(stripePaymentMethod, email)
    }

    get().savePaymentDataLocally(paymentMethodType, stripePaymentMethod)

    return true
  },
  createPaymentIntent: async (orderPriceSummary: any) => {
    // this method is called when 'place order' is clicked and before the
    // photos finished to upload, it will just get a funds hold confirmation
    // and return a paymentIntentId which we can use later to make the
    // actual charge
    logger.info(`PaymentManager.createPaymentIntent paymentMethodId: ${
      get().stripePaymentMethodId
    } 
                          customerId: ${get().stripeCustomer?.id}
                          stripeAccountName: ${get().stripeAccountName}`)

    const apiPath = 'v4/stripe/createPaymentIntent'

    return mixtilesAxios.post(apiPath, {
      paymentMethodId: get().stripePaymentMethodId,
      customerId: get().stripeCustomer?.id,
      stripeAccountName: get().stripeAccountName,
      totalCost: orderPriceSummary.totalPrice,
      currency: orderPriceSummary.currency,
      localId: getOrderLocalId(),
    })
  },
  clearPaymentMethod: () =>
    set(() => ({
      paymentMethodType: null,
      stripePaymentMethodId: null,
    })),
  clearData: () => {
    get().clearPaymentMethod()
    StorageManager.remove(KEY_STRIPE_CUSTOMER)
    StorageManager.remove(KEY_STRIPE_ACCOUNT_NAME)

    set(() => ({
      stripeAccountName: initializeStripeAccountName(),
      stripeCustomer: null,
      paymentCurrentCard: null,
      paypalAccountName: null,
      paypalTransactionId: null,
      paymentElementMethodType: null,
    }))
  },
})
