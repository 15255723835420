import {
  createStore,
  type StoreApi,
  useStore as useZustandStore,
} from 'zustand'
import { createContext, useState, useContext, useEffect } from 'react'

import { useShallow } from 'zustand/shallow'
import { createAddressSlice } from './addressSlice/addressSlice'
import { createShippingSlice } from './shippingSlice/shippingSlice'
import { createPricingSlice } from './pricingSlice/pricingSlice'
import {
  type Store,
  type StoreInitializerProviderProps,
  type StoreProviderProps,
} from './store.types'
import { persist } from 'zustand/middleware'
import { createPromoCodeSlice } from './promoCodeSlice/promoCodeSlice'
import { createPaymentSlice } from './paymentSlice/paymentSlice'
import { createPromoCodeHandlersSlice } from './promoCodeSlice/promoCodeHandlersSlice'
import { createOrderSlice } from './orderStore/orderSlice'
import useApi from '../api/apiProvider'
import useDialog from '../elements/Dialog/DialogProvider'
import { useProductType } from '../services/ProductTypesManager'
import { useKeys } from '../services/KeysProvider'
import { useExperimentManager } from '../services/ExperimentManager/ExperimentManager'
import useOnMount from '../hooks/useOnMount'
import { getRelevantVariants } from '../components/Pricing/PricingUtil'
import omitBy from 'lodash/omitBy'
import { createCreditSlice } from './promoCodeSlice/creditSlice/creditSlice'
import { createGiftCardSlice } from './promoCodeSlice/giftCardSlice/giftCardSlice'

const StoreContext = createContext<StoreApi<Store> | null>(null)

let storeGetter: () => Store

export function getStoreState() {
  if (!storeGetter) {
    throw new Error('Store not initialized')
  }

  return storeGetter()
}

export function StoreProvider({
  children,
  initialPricingData,
}: StoreProviderProps) {
  const api = useApi()
  const dialog = useDialog()
  const experimentManager = useExperimentManager()
  const { productType } = useProductType()
  const { ipCountry } = useKeys()
  const [store] = useState(() => {
    const store = createStore<Store>()(
      persist(
        (set, get, store) => ({
          ...createAddressSlice(set, get, store),
          ...createShippingSlice(set, get, store),
          ...createPricingSlice(
            initialPricingData,
            experimentManager,
            api,
            dialog,
            productType,
            ipCountry
          )(set, get, store),
          ...createPaymentSlice(set, get, store),
          ...createPromoCodeSlice(set, get, store),
          ...createPromoCodeHandlersSlice(set, get, store),
          ...createOrderSlice(api, productType)(set, get, store),
          ...createCreditSlice(set, get, store),
          ...createGiftCardSlice(set, get, store),
        }),
        {
          partialize: (state) =>
            omitBy(state, (_, key) =>
              [
                'lastPromoCode',
                'isFirstInit',
                'lastCouponValidationTimestamp',
                'appliedPromoCodeFromBanner',
                'selectedShippingOptions',
              ].includes(key)
            ),
          name: 'checkout-store',
        }
      )
    )
    storeGetter = store.getState
    return store
  })

  return (
    <StoreContext.Provider value={store}>
      <StoreInitializer
        experimentManager={experimentManager}
        productType={productType}
      >
        {children}
      </StoreInitializer>
    </StoreContext.Provider>
  )
}

function StoreInitializer({
  children,
  experimentManager,
  productType,
}: StoreInitializerProviderProps) {
  const [
    revalidateGiftCardCodes,
    setPricingVariantsIds,
    updatePricing,
    updatePricingSummaryIfNeeded,
    discounts,
    pricingData,
    currentAddress,
    products,
    discountId,
  ] = useStore(
    useShallow((state) => [
      state.revalidateGiftCardCodes,
      state.setPricingVariantsIds,
      state.updatePricing,
      state.updatePricingSummaryIfNeeded,
      state.discounts,
      state.pricingData,
      state.currentAddress,
      state.products,
      state.discountId,
    ])
  )

  useOnMount(() => {
    if (experimentManager.isEnabled('checkout-v2')) {
      // Setup subscription when slice is created
      const experimentSubscription =
        experimentManager.subscribeToExperimentFetch(() => {
          setPricingVariantsIds(getRelevantVariants(experimentManager))
        })

      revalidateGiftCardCodes()

      return () => {
        experimentSubscription && experimentSubscription.unsubscribe()
      }
    }
  })

  useEffect(() => {
    if (experimentManager.isEnabled('checkout-v2')) {
      updatePricing()
    }
  }, [productType])

  useEffect(() => {
    if (experimentManager.isEnabled('checkout-v2')) {
      updatePricingSummaryIfNeeded({ force: true })
    }
  }, [discounts, pricingData, currentAddress])

  useEffect(() => {
    if (experimentManager.isEnabled('checkout-v2')) {
      updatePricingSummaryIfNeeded()
    }
  }, [products, discountId])

  return children
}

export const useStore = <T,>(selector: (state: Store) => T) => {
  const store = useContext(StoreContext)
  if (!store) {
    throw new Error('useStore must be used within a StoreProvider')
  }

  return useZustandStore(store, selector)
}
