export enum TILES_SOURCE {
  PHOTO_STYLER = 'photo-styler',
  JUMBLE = 'jumble',
}

export const DEFAULT_TILES_SOURCE = TILES_SOURCE.PHOTO_STYLER

export const STORAGE_KEYS = {
  [TILES_SOURCE.PHOTO_STYLER]: 'photo-tiles',
  [TILES_SOURCE.JUMBLE]: 'photo-tiles-jumble',
}

export const DEFAULT_STORAGE_KEY = STORAGE_KEYS[DEFAULT_TILES_SOURCE]

export const ORDER_DRAFT_TILES_STORAGE_KEY = 'order-draft-tiles'
