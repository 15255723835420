import {
  PHOTO_ORIENTATIONS,
  TILE_SHAPES,
  type TILE_SIZES,
  tileSizeToOrientation,
} from '@mixtiles/web-backend-shared'

export function getShapeFromSize(tileSize: TILE_SIZES): TILE_SHAPES | null {
  const orientation = tileSizeToOrientation(tileSize)
  if (orientation === PHOTO_ORIENTATIONS.SQUARE) {
    return TILE_SHAPES.SQUARE
  }
  if (orientation === PHOTO_ORIENTATIONS.LANDSCAPE) {
    return TILE_SHAPES.LANDSCAPE
  }
  if (orientation === PHOTO_ORIENTATIONS.PORTRAIT) {
    return TILE_SHAPES.PORTRAIT
  }
  return null
}
