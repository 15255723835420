import { addressManager } from '../../services/AddressManager'
import { saveAddressApi } from '../../api/address.api'
import { analytics } from '../../services/Analytics/Analytics'
import { experimentManager } from '../../services/ExperimentManager/ExperimentManager'
import { getStoreState } from '../../stores/store'
import { denormalizeAddress } from '../../stores/addressSlice/addressUtils'

export default async function syncAddress(api, currentAccount) {
  let localAddress = addressManager.getCurrentNormalizedAddress()
  if (currentAccount.address?.country && !localAddress) {
    analytics.track('Restored address')
    if (!experimentManager.isEnabled('checkout-v2')) {
      addressManager.setAddressFromServer(currentAccount.address)
    } else {
      getStoreState().setCurrentAddress(
        denormalizeAddress(currentAccount.address),
        false
      )
      getStoreState().setAddressValidationSkipped(
        currentAccount.address.shouldValidate
      )
      getStoreState().updateTaxForAddress()
    }
  }

  addressManager.removeAddressIfNotSupported()

  localAddress = addressManager.getCurrentNormalizedAddress()

  if (
    localAddress &&
    (!currentAccount.address ||
      JSON.stringify(
        currentAccount.address,
        Object.keys(localAddress).sort()
      ) !== JSON.stringify(localAddress, Object.keys(localAddress).sort()))
  ) {
    await api.call(saveAddressApi(localAddress))
  }
}
