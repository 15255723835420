// Changing these constants can affect the PaymentConfig in Parse
import { PaymentMethodType } from '../../services/PaymentManager.types'
import { type StripeAccountName } from './paymentSlice.types'

export const KEY_STRIPE_CUSTOMER = 'stripeCustomer'
export const KEY_PAYMENT_METHOD_TYPE = 'paymentMethodType'
export const KEY_PAYMENT_CURRENT_CARD = 'paymentCurrentCard'
export const KEY_PAYMENT_METHOD_ID = 'paymentMethodId'
export const KEY_STRIPE_ACCOUNT_NAME = 'stripeAccount'
export const KEY_PAYPAL_ACCOUNT_NAME = 'paypalAccount'

export const ADDITIONAL_PAYMENT_INFO_KEYS = [
  KEY_PAYMENT_METHOD_TYPE,
  KEY_PAYMENT_METHOD_ID,
  KEY_STRIPE_ACCOUNT_NAME,
  KEY_PAYPAL_ACCOUNT_NAME,
]

// Stores the payment method type that was selected in the payment element
// It is required because in the past the credit card popup contained only credit card option,
// but since adding the PaymentElement, this popup can contain other payment methods
export const KEY_PAYMENT_ELEMENT_METHOD_TYPE = 'paymentElementMethodType'

export const PaymentMethodTypeDisplay = {
  [PaymentMethodType.CREDIT_CARD]: 'Credit Card',
  [PaymentMethodType.PAYPAL]: 'PayPal',
  [PaymentMethodType.APPLE_PAY]: 'Apple Pay',
  [PaymentMethodType.GOOGLE_PAY]: 'Google Pay',
  [PaymentMethodType.LINK]: 'Link',
  [PaymentMethodType.AFTERPAY]: 'Afterpay',
  [PaymentMethodType.AFFIRM]: 'Affirm',
  [PaymentMethodType.KLARNA]: 'Klarna',
  [PaymentMethodType.AMAZON_PAY]: 'Amazon Pay',
  [PaymentMethodType.CASH_APP]: 'Cash App Pay',
}

// The separation to different stripe accounts is done in order to avoid exchange rates that we have to
// pay when charging the customer in a different currency then the bank's currency in the Stripe account.
// The default currency that we will use is US dollars.
// Same for PayPal ^
export const DEFAULT_STRIPE_ACCOUNT_NAME = 'US'
export const OLD_PAYPAL_ACCOUNT_NAME = 'US' // we obsolete this account so old users should migrate to new account
export const DEFAULT_PAYPAL_ACCOUNT_NAME = 'US_2'

// relevant european countries according to Finance regarding European credit cards. (including Israel)
// we're going to use the same list to also to PayPal
export const COUNTRIES_FOR_EUROPEAN_PAYMENT = [
  'AL',
  'AD',
  'AT',
  'BY',
  'BE',
  'BA',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FO',
  'FI',
  'FR',
  'DE',
  'GI',
  'GR',
  'HU',
  'IS',
  'IE',
  'IM',
  'IT',
  'RS',
  'LV',
  'LI',
  'LT',
  'LU',
  'MK',
  'MT',
  'MD',
  'MC',
  'ME',
  'NL',
  'NO',
  'PL',
  'PT',
  'RO',
  'RU',
  'SM',
  'RS',
  'SK',
  'SI',
  'ES',
  'SE',
  'CH',
  'UA',
  'GB',
  'VA',
  'RS',
  'IL',
  'KZ',
  'AM',
  'TR',
  'AZ',
  'GE',
]

// STRIPE DATA FOR PAYMENTS
export const STRIPE_ACCOUNTS_DATA: Record<
  StripeAccountName,
  {
    country: string
    bankCurrency: string
    userCountries: string[]
  }
> = {
  NL: {
    country: 'NL',
    bankCurrency: 'eur',
    userCountries: COUNTRIES_FOR_EUROPEAN_PAYMENT,
  },
  US: {
    country: 'US',
    bankCurrency: 'usd',
    userCountries: ['US'],
  },
}

// PAYPAL DATA FOR PAYMENTS
export const PAYPAL_ACCOUNTS_DATA = {
  NL: {
    bankCurrency: 'eur',
    userCountries: COUNTRIES_FOR_EUROPEAN_PAYMENT,
  },
  US_2: {
    bankCurrency: 'usd',
    userCountries: ['US'],
  },
}
