import React from 'react'
import * as S from './Divider.styles'

function Divider({
  isMobile = false,
  mobileSub = 15,
  transparent = false,
  marginTop = 0,
  marginBottom = 0,
  margin = 30,
  color = 'lightGray',
}) {
  const finalMargin = (targetMargin) =>
    isMobile
      ? `${Math.max(targetMargin - mobileSub, 0)}px`
      : `${targetMargin}px`
  const margins =
    transparent || (marginTop === 0 && marginBottom === 0)
      ? { margin: `${finalMargin(margin)} 0` }
      : {
          marginTop: finalMargin(marginTop),
          marginBottom: finalMargin(marginBottom),
        }
  return (
    <S.Divider $margins={margins} $transparent={transparent} $color={color} />
  )
}

export default Divider
