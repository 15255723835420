import ExifReader from 'exifreader'
import { loadImage } from 'mixtiles-web-common/services/imageLoader'
import { getKeyByValue } from './utils'
import { logger } from '../services/logger'
import { getPrintedImageDimensions } from '@mixtiles/web-backend-shared'
import { getFilestackUploadMetadata } from './filestackUtils'

/* global CSS, getComputedStyle */

export const TRANSPARENT_PIXEL_URL =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='
const IMAGE_ORIENTATION_CSS_PROP = 'image-orientation'
const SUPPORT_EXIF_IMAGE_ORIENTATION_VALUE = 'from-image'

export const SUPPORTED_IMAGE_FORMATS = {
  'image/png': ['.png'],
  'image/jpeg': ['.jpeg', '.jpg'],
  'image/webp': ['.webp'],
  'image/heic': ['.heic'],
  'image/svg+xml': ['.svg'],
}

export const DEFAULT_IMAGE_DPI = 300

function browserRespectsExifMetadata() {
  const anyImageElement = document.querySelector('img')
  return (
    CSS &&
    CSS.supports(
      IMAGE_ORIENTATION_CSS_PROP,
      SUPPORT_EXIF_IMAGE_ORIENTATION_VALUE
    ) &&
    anyImageElement &&
    getComputedStyle(anyImageElement)[IMAGE_ORIENTATION_CSS_PROP] ===
      SUPPORT_EXIF_IMAGE_ORIENTATION_VALUE
  )
}

export function loadImageMeasurements(fileOrUrl) {
  return new Promise((resolve, reject) => {
    loadImage(
      fileOrUrl,
      (image, meta) => {
        if (image.type === 'error') {
          return reject(new Error('Failed loading image'))
        }

        const exifOrientation =
          (meta && meta.exif && meta.exif.get('Orientation')) || 1
        let width = image.width
        let height = image.height
        if (
          !browserRespectsExifMetadata() &&
          [5, 6, 7, 8].includes(exifOrientation)
        ) {
          // For 90deg rotated exif values, swap width and height
          width = image.height
          height = image.width
        }
        resolve({ width, height, exifOrientation, image })
      },
      { meta: true, noRevoke: true }
    )
  })
}

export async function getImageDataByUrl(url) {
  const loadedImage = await loadImage(url)
  return {
    image: loadedImage,
    width: loadedImage.width,
    height: loadedImage.height,
  }
}

// Note: the Javascript-Load-Image documentation seems to be wrong and confuse clockwise with counter-clockwise rotation degrees.
// Therefore, the following mapping is based on the demo within this same github project: https://blueimp.github.io/JavaScript-Load-Image
const DEGREES_TO_ORIENTATION = {
  0: 1,
  90: 6,
  180: 3,
  270: 8,
}

/**
 * Convert photo rotation degrees into the corresponding orientation value.
 * If a photo already has an orientation, rotate the photo in relation to the old orientation.
 *
 * Reference: https://github.com/blueimp/JavaScript-Load-Image#orientation
 * @param rotation - rotation degrees received from Cropper
 * @param orientation - current exifOrientation of the photo
 * @returns {(number|undefined)} - orientation number if rotation degrees exist in mapping
 */
function convertRotationToOrientation(rotation, orientation) {
  if (Number.isInteger(rotation)) {
    // Calculate degrees before rotation if exif orientation exists
    let currentDegrees = getKeyByValue(DEGREES_TO_ORIENTATION, orientation)
    currentDegrees = currentDegrees ? parseInt(currentDegrees) : 0
    rotation = (rotation + currentDegrees) % 360
    // Convert counter-clockwise degrees to clockwise degrees
    if (rotation < 0) {
      rotation = 360 - Math.abs(rotation)
    }
    return DEGREES_TO_ORIENTATION[rotation]
  }
}

export async function generateImageThumbnail(
  fileOrUrl,
  orientation,
  width,
  height,
  crop
) {
  // Note: orientation value is mandatory - otherwise generating thumbnail will be failed with non intuitive error
  // in order to obtain orientation value you can use loadImageMeasurements
  return new Promise((resolve, reject) => {
    const config = {
      canvas: true,
      orientation,
      maxWidth: width,
      maxHeight: height,
      minWidth: width,
      minHeight: height,
      noRevoke: true,
    }

    if (crop) {
      config.sourceWidth = crop.width
      config.sourceHeight = crop.height
      config.left = crop.x
      config.top = crop.y
      if (crop.rotation) {
        // Try to override orientation based on rotation
        const mappedOrientation = convertRotationToOrientation(
          crop.rotation,
          orientation
        )
        config.orientation = mappedOrientation || config.orientation
      }
    }

    loadImage(
      fileOrUrl,
      (canvas) => {
        canvas.toBlob((blob) => {
          try {
            const url = URL.createObjectURL(blob)
            resolve(url)
          } catch (error) {
            logger.error(
              'generateImageThumbnail: Error on createObjectURL',
              error,
              { fileOrUrl }
            )
            reject(error)
          }
        })
      },
      config
    )
  })
}

export function tileSizeToImageDimensions({
  tileSize,
  materialType,
  dpi = DEFAULT_IMAGE_DPI,
}) {
  const { jigImageWidth, jigImageHeight } = getPrintedImageDimensions({
    tileSize,
    materialType,
  })
  return {
    width: jigImageWidth * dpi,
    height: jigImageHeight * dpi,
  }
}

function disposeImage(image) {
  image.src = ''
  image = null
}

export async function preloadImagePromise(imageUrl) {
  let loaded = false
  return new Promise((resolve) => {
    const img = document.createElement('img')
    img.onload = () => {
      loaded = true
      disposeImage(img)
      resolve()
    }
    img.onerror = (event) => {
      if (!loaded) {
        logger.warning(`Failed to preload image ${event.target.src}`, event)
        disposeImage(img)
        resolve()
      }
    }
    img.src = imageUrl
  })
}

// Exif reader returns the format of the date as 'YYYY:MM:DD HH:mm:ss' and it needs to be converted to ISO format like this
// 'YYYY-MM-DDTHH:mm:ss'
function parseExifDate(dateStr) {
  const [day, time] = dateStr.split(' ')
  // Replace the first two colons with hyphens and add 'T' between date and time
  const formattedDay = day.replace(/:/g, '-')
  const formattedDateStr = `${formattedDay}T${time}`

  const date = new Date(formattedDateStr).getTime()
  if (isNaN(date)) {
    return undefined
  }
  return date
}

/**
 * This function returns the date from the exif data of the image. If the exif data is not available, it returns the
 * last modified date of the image file. For Filestack it tries to get the exif data from the file's metadata.
 * @param file
 * @param isFileStackFile
 * @returns {Promise<number|undefined>}
 */
export async function getExifDate(file, isFileStackFile) {
  if (isFileStackFile) {
    const metadata = await getFilestackUploadMetadata(file)
    return metadata?.exif?.['EXIF DateTimeOriginal']
      ? parseExifDate(metadata.exif['EXIF DateTimeOriginal'])
      : undefined
  }

  let tags
  try {
    tags = await ExifReader.load(file)
    return tags?.DateTimeOriginal?.description
      ? parseExifDate(tags.DateTimeOriginal.description)
      : undefined
  } catch (error) {
    logger.error('getExifDate: Error on load exif data', error)
  } finally {
    // Encouraging garbage collection
    tags = null
  }

  return undefined
}

export function dataURItoBlob(dataURI) {
  // Parse the dataURI components as per RFC 2397
  const dataURIPattern = /^data:((.*?)(;charset=.*?)?)(;base64)?,/
  const matches = dataURI.match(dataURIPattern)
  if (!matches) {
    throw new Error('invalid data URI')
  }
  // Default to text/plain;charset=US-ASCII
  const mediaType = matches[2]
    ? matches[1]
    : `text/plain${matches[3] || ';charset=US-ASCII'}`
  const isBase64 = !!matches[4]
  const dataString = dataURI.slice(matches[0].length)
  const byteString = isBase64
    ? atob(dataString)
    : decodeURIComponent(dataString)
  const intArray = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i += 1) {
    intArray[i] = byteString.charCodeAt(i)
  }
  return new Blob([intArray], { type: mediaType })
}
