import React, {
  createContext,
  type PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { logger } from '../../services/logger'
import useApi from '../../api/apiProvider'
import {
  calculateBatchProductsPricing,
  getDiscounts,
  getPricingItems,
} from './Pricing.api'
import { countryManager } from '../../services/CountryManager'
import { promoCodeManager } from '../../services/PromoCodeManager'
import useOnMount from '../../hooks/useOnMount'
import { useProductType } from '../../services/ProductTypesManager'
import { getRelevantVariants, PRICING_ERROR_CONTEXT } from './PricingUtil'
import { currencyState } from '../../services/CurrencyState'
import debounce from 'lodash/debounce'

import { getDiscountForSize } from './Discounts/Discounts'
import {
  fallbackUntilMatch,
  formatPrice,
  FRAME_COLORS,
  hashTilePricingProduct,
  MATERIAL_TYPES,
  type PricingProduct,
  TILE_SIZES,
} from '@mixtiles/web-backend-shared'
import { runAsyncWithRetries } from '../../utils/promises'
import useDialog from '../../elements/Dialog/DialogProvider'
import { translateManager as t } from '../../services/TranslateManager'
import { sendPricingErrorAnalytics } from '../../services/Analytics/pricingAnalytics'
import {
  MIXED_MATERIALS,
  MIXED_SIZES,
} from '../../pages/PhotoStyler/TileDesignerConsts'
import { getOrderedSizes } from '../../pages/DiscoveryPage/DiscoveryConsts'
import {
  type BatchProductsPricingResponse,
  type ProductCollection,
} from './types/checkout.types'
import { type PricingItem } from './types/pricingItem.types'
import {
  type DiscountsAPI,
  type FetchDiscountsResponse,
} from './types/discount.types'
import {
  type DiscountData,
  type FetchDiscountParams,
  type FetchDiscountResponse,
  type FetchDiscountsForPromoCodeParams,
  type FetchDiscountsForPromoCodeResponse,
  type FetchPricingItemResponse,
  type FetchPricingResponse,
  type MixtilesPlusPricingData,
  type PriceDifferenceMap,
  type PricingContextValue,
  type PricingData,
} from './types/PricingProvider.types'
import { type PRODUCT_TYPES } from '../../services/ProductTypeState'
import { useEvent } from 'react-use-event-hook'
import {
  getPricingMaterialType,
  isFramedMaterial,
} from '../../utils/materialTypeUtils'
import { useExperimentManager } from '../../services/ExperimentManager/ExperimentManager'
import { getMixtilesPlusData } from '../../api/mixtilesPlus.api'
import UserManager from '../../services/UserManager'
import { useKeys } from 'services/KeysProvider'
import {
  FETCH_PRICING_ITEMS_OPERATION_NAME,
  RELOAD_DISCOUNTS_OPERATION_NAME,
  REFRESH_PRICING_NUM_RETRIES,
  DEBOUNCE_INTERVAL,
} from '../../stores/pricingSlice/pricingConsts'
import {
  formatPricingItems,
  getPricingProductType,
  shouldUseAPI,
} from '../../stores/pricingSlice/pricingUtils'

const PricingContext = createContext<PricingContextValue | null>(null)
const frameColors = Object.values(FRAME_COLORS)
const materialTypes = Object.values(MATERIAL_TYPES)

export function usePricing() {
  const context = useContext(PricingContext)
  if (!context) {
    throw new Error('usePricing must be within PricingContextProvider')
  }
  return context
}

export function withPricing(Component: any) {
  return React.forwardRef((props, ref) => (
    <PricingContext.Consumer>
      {(contexts) => <Component {...props} ref={ref} pricing={contexts} />}
    </PricingContext.Consumer>
  ))
}

export function PricingProvider({
  initialPricingData,
  children,
}: PropsWithChildren & { initialPricingData: PricingData }) {
  const isFirstInit = useRef(true)
  const { ipCountry } = useKeys()
  const api = useApi()
  const dialog = useDialog()
  const { productType } = useProductType()
  const [discounts, setDiscounts] = useState<DiscountData>({
    value: [],
    isLoading: true,
  })

  const [pricingData, setPricingData] =
    useState<PricingData>(initialPricingData)
  useEffect(() => {
    UserManager.subscribeToExperimentFetch(() => calculateMixtilesPlusInfo())
    calculateMixtilesPlusInfo()
  }, [])

  useEffect(() => {
    currencyState.setCurrency(initialPricingData.currency)
  }, [initialPricingData.currency])

  const experimentManager = useExperimentManager()

  const [error, setError] = useState<any>(null)
  const [pricingVariantsIds, setPricingVariantsIds] = useState<string[]>(() =>
    getRelevantVariants(experimentManager)
  )
  const [mixtilesPlusPricingData, setMixtilesPlusPricingData] =
    useState<MixtilesPlusPricingData | null>(null)
  const pricingCountry = ipCountry
  const lastPromocode = useRef(undefined)

  const [priceDifferenceMap, setPriceDifferenceMap] =
    useState<PriceDifferenceMap>(null)

  const calculateMixtilesPlusInfo = async () => {
    const data = await getMixtilesPlusData(
      pricingVariantsIds,
      countryManager.getPricingCountry(pricingCountry),
      UserManager.getUserEmail()
    )
    setMixtilesPlusPricingData(data)
  }

  const setDataForAll = (
    pricingItems: PricingItem[],
    discountValue: DiscountsAPI,
    currency: string,
    pricingProductType: PRODUCT_TYPES
  ) => {
    const formattedPricingItems = formatPricingItems(pricingItems)

    setPricingData({
      currency,
      pricingItems: formattedPricingItems,
      productType: pricingProductType,
      isLoading: false,
    })
    setDiscounts({
      value: discountValue,
      isLoading: false,
    })
  }

  const setDiscountsIsLoading = (isLoading: boolean) => {
    setDiscounts({ value: discounts.value, isLoading })
  }

  const reportPricingError = (message: string, error: any) => {
    sendPricingErrorAnalytics(error)
    logger.error(message, error, {
      tags: { context: PRICING_ERROR_CONTEXT },
      pricingCountry,
      pricingVariantsIds,
      productType,
    })
  }

  const refreshPage = () => {
    window.location.reload()
  }

  const handleError = (message: string, error: any) => {
    reportPricingError(message, error)
    setError(error)
    dialog.showAlert(
      t.get('general.errors.general_title'),
      t.get('general.errors.general_description'),
      t.get('general.refresh'),
      refreshPage
    )
  }

  const setIsLoading = (isLoading: boolean) => {
    setDiscountsIsLoading(isLoading)
    setPricingData({ ...pricingData, isLoading })
  }

  const fetchDiscounts = async (
    forceCouponValidation?: boolean
  ): Promise<FetchDiscountsResponse> => {
    await promoCodeManager.revalidateDiscountCoupon(forceCouponValidation)
    const promocode = promoCodeManager.getDiscountCouponCode()
    return api.call(
      getDiscounts({
        productType: getPricingProductType(productType),
        pricingCountry,
        variantsIds: pricingVariantsIds,
        promocode,
      })
    )
  }

  const fetchDiscount = useCallback(
    ({
      tileSize,
      materialType = MATERIAL_TYPES.CLASSIC,
      frameColor,
    }: FetchDiscountParams): FetchDiscountResponse => {
      const backendMaterialType = getPricingMaterialType(materialType)
      if (!discounts.isLoading && !pricingData.isLoading) {
        return {
          value: getDiscountForSize({
            discounts: discounts.value,
            tileSize: materialType === MIXED_MATERIALS ? MIXED_SIZES : tileSize,
            currency: pricingData.currency,
            materialType: backendMaterialType,
            frameColor: isFramedMaterial(backendMaterialType)
              ? frameColor
              : undefined,
          }),
          isLoading: false,
        }
      } else {
        return { value: null, isLoading: true }
      }
    },
    [pricingData, discounts, productType]
  )

  const fetchDiscountsForPromoCode = async ({
    promocode,
    tileSize,
    materialType = MATERIAL_TYPES.CLASSIC,
    frameColor,
    forceProductType = null,
  }: FetchDiscountsForPromoCodeParams): FetchDiscountsForPromoCodeResponse => {
    const backendMaterialType =
      materialType === MATERIAL_TYPES.FRAMELESS
        ? MATERIAL_TYPES.CLASSIC
        : materialType
    const { discounts } = await api.call(
      getDiscounts({
        productType: forceProductType || getPricingProductType(productType),
        pricingCountry,
        variantsIds: pricingVariantsIds,
        promocode,
      })
    )
    return {
      value: getDiscountForSize({
        discounts,
        tileSize,
        currency: pricingData.currency,
        materialType: backendMaterialType,
        frameColor,
      }),
      isLoading: false,
    }
  }

  const fetchPricing = async (): Promise<FetchPricingResponse> => {
    logger.info(
      `[PricingProvider] - getPricingItems - productType: ${productType} ${pricingCountry} ${pricingVariantsIds}`
    )
    const pricingProductType = getPricingProductType(productType)
    const pricingItemsPromise = isFirstInit.current
      ? initialPricingData
      : api.call(
          getPricingItems({
            productType: pricingProductType,
            pricingCountry,
            variantsIds: pricingVariantsIds,
          })
        )
    isFirstInit.current = false
    const discountsPromise = fetchDiscounts()
    const [pricingItemRes, discountsRes] = await Promise.all([
      pricingItemsPromise,
      discountsPromise,
    ])
    const { pricingItems, currency } = pricingItemRes

    return {
      pricingItems,
      currency,
      discounts: discountsRes.discounts,
      pricingProductType,
    }
  }

  const updatePricingFromAPI = debounce(async () => {
    setIsLoading(true)
    setError(null)
    try {
      const { pricingItems, currency, discounts, pricingProductType } =
        await runAsyncWithRetries<FetchPricingResponse>({
          op: fetchPricing,
          opName: FETCH_PRICING_ITEMS_OPERATION_NAME,
          numRetries: REFRESH_PRICING_NUM_RETRIES,
        })
      setDataForAll(pricingItems, discounts, currency, pricingProductType)
      currencyState.setCurrency(currency)
    } catch (e: any) {
      handleError('Failed to reload price for user', e)
    }
  }, DEBOUNCE_INTERVAL)

  const updatePricing = () => {
    if (shouldUseAPI(productType)) {
      updatePricingFromAPI()
    } else {
      isFirstInit.current = false
    }
  }

  const handlePromocodeChange = useEvent(
    async (params?: { freeTiles?: boolean; giftCard?: true }) => {
      const { freeTiles, giftCard } = params || {}
      const curPromocode = promoCodeManager.getDiscountCouponDisplayName()
      // Free tiles is the only exception because it doesn't get loaded into the local storage like other promocodes
      if (
        !shouldUseAPI(productType) ||
        (!freeTiles && !giftCard && lastPromocode.current === curPromocode)
      ) {
        return
      }
      setDiscountsIsLoading(true)
      try {
        const { discounts: value } =
          await runAsyncWithRetries<FetchDiscountsResponse>({
            op: fetchDiscounts,
            opName: RELOAD_DISCOUNTS_OPERATION_NAME,
            numRetries: REFRESH_PRICING_NUM_RETRIES,
          })
        setDiscounts({
          value,
          isLoading: false,
        })
        lastPromocode.current = curPromocode
      } catch (e: any) {
        handleError('failed to fetch discount after promocode update', e)
      }
    }
  )

  const setupSubscriptions = () => {
    const promocodeSubscription = promoCodeManager.promoCodeSubject.subscribe(
      handlePromocodeChange
    )

    const experimentSubscription = experimentManager.subscribeToExperimentFetch(
      () => {
        setPricingVariantsIds(getRelevantVariants(experimentManager))
      }
    )

    return () => {
      promocodeSubscription && promocodeSubscription.unsubscribe()
      experimentSubscription && experimentSubscription.unsubscribe()
    }
  }

  useOnMount(() => {
    setupSubscriptions()
    promoCodeManager.revalidateGiftCardCodes()
  })

  useEffect(updatePricing, [pricingVariantsIds, productType])

  const fetchPricingItem = (
    pricingProduct: PricingProduct
  ): FetchPricingItemResponse => {
    if (
      !pricingProduct ||
      !pricingData ||
      pricingData.isLoading ||
      pricingData.productType !== getPricingProductType(productType) ||
      pricingProduct.tileSize.includes(MIXED_SIZES) ||
      pricingProduct.materialType?.includes(MIXED_MATERIALS)
    ) {
      return { value: null, isLoading: true, currency: null }
    } else {
      const pricingItem = fallbackUntilMatch(
        pricingProduct,
        (pricingProduct: PricingProduct) =>
          pricingData.pricingItems[hashTilePricingProduct(pricingProduct)]
      )

      if (!pricingItem) {
        if (
          getOrderedSizes(
            pricingProduct.materialType as MATERIAL_TYPES
          ).includes(pricingProduct.tileSize as TILE_SIZES) &&
          !pricingProduct.frameColor
        ) {
          // We report error if the given SKU should be in the pricing but it's not
          const message = `failed to find pricing item for tileSKU ${JSON.stringify(
            pricingProduct
          )}, ${JSON.stringify(pricingData.pricingItems)}`
          reportPricingError(message, new Error(message))
        }
        return { value: null, isLoading: true, currency: null }
      }
      return {
        value: pricingItem.price,
        currency: pricingData.currency,
        isLoading: false,
      }
    }
  }

  const calculateBatchPricing = async (
    productsCollections: ProductCollection[]
  ): Promise<BatchProductsPricingResponse> => {
    return api.call(
      calculateBatchProductsPricing({
        productsCollections,
        productType,
        pricingCountry,
        variantsIds: pricingVariantsIds,
        promocode: lastPromocode.current,
      })
    )
  }

  const fetchPrices = async () => {
    const currPriceDiff: Partial<
      Record<MATERIAL_TYPES, Partial<Record<FRAME_COLORS, number>>>
    > = {}

    const { value: blackValue, isLoading: blackIsLoading } =
      await fetchPricingItem({
        tileSize: TILE_SIZES.SQUARE_8X8,
        materialType: MATERIAL_TYPES.CLASSIC,
        frameColor: FRAME_COLORS.BLACK,
      })
    if (blackIsLoading || blackValue === 0) {
      return
    }
    for (const materialType of materialTypes) {
      currPriceDiff[materialType] = {}

      for (const frameColor of frameColors) {
        const { value: currentValue, isLoading: currentIsLoading } =
          await fetchPricingItem({
            tileSize: TILE_SIZES.SQUARE_8X8,
            materialType,
            frameColor,
          })

        if (currentIsLoading) {
          // If the material is missing or still loading, set the difference to 0
          currPriceDiff[materialType]![frameColor] = 0
        } else {
          currPriceDiff[materialType]![frameColor] = currentValue - blackValue
        }
      }
    }

    setPriceDifferenceMap(currPriceDiff as PriceDifferenceMap)
  }

  useEffect(() => {
    fetchPrices()
  }, [pricingData])

  const mixColorPrice = useMemo(() => {
    if (!priceDifferenceMap) {
      return null
    }

    return formatPrice({
      price: priceDifferenceMap[MATERIAL_TYPES.CLASSIC][FRAME_COLORS.BLACK],
      currency: pricingData.currency,
      shouldRound: false,
      roundingPrecision: false,
      country: countryManager.getPricingCountry(),
    })
  }, [priceDifferenceMap])

  const contextValue: PricingContextValue = {
    pricingData,
    discounts,
    error,
    pricingVariantsIds,
    fetchDiscount,
    fetchDiscounts,
    fetchDiscountsForPromoCode,
    fetchPricingItem,
    calculateBatchPricing,
    mixtilesPlusPricingData,
    priceDifferenceMap,
    mixColorPrice,
  }

  return (
    <PricingContext.Provider value={contextValue}>
      {children}
    </PricingContext.Provider>
  )
}
