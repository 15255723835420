import { experimentManager } from '../ExperimentManager/ExperimentManager'
import { MATERIAL_TYPES } from '@mixtiles/web-backend-shared'

class MaterialsAsColorManager {
  isEnabled: boolean = false

  availableMaterialsList: MATERIAL_TYPES[] = []

  private generateAvailableMaterialsList(isCanvasEnabled: boolean) {
    const list: MATERIAL_TYPES[] = [
      MATERIAL_TYPES.FRAMELESS,
      MATERIAL_TYPES.CLASSIC,
      MATERIAL_TYPES.WIDE_FRAME,
    ]
    if (isCanvasEnabled) {
      list.push(MATERIAL_TYPES.CANVAS)
    }
    return list
  }

  init(isEnabled: boolean) {
    this.isEnabled = isEnabled
    const isCanvasEnabled = experimentManager.isEnabled('canvas')
    this.availableMaterialsList =
      this.generateAvailableMaterialsList(isCanvasEnabled)
  }
}

export const materialsAsColorManager = new MaterialsAsColorManager()
