export const SHIPPING_PREFERENCE_KEY = 'shippingPreference'
export const SHIPPING_OPTIONS_KEY = 'shippingOptions'

export const CACHE_TIMEOUT_MS = 30 * 60 * 1000

export const SHIPPING_TOP_QUEUE_KEY = 'shippingOptions_topQueue'
export const SHIPPING_OPTION_FREE = 'FREE'
export const SHIPPING_OPTION_FAST = 'FAST'
export const SHIPPING_OPTION_EXPRESS = 'EXPEDITED'
export const SHIPPING_OPTION_PICKUP = 'PICKUP'

export const PROMISE_DATE_RANGE_SIZE_DAYS = 4
