export const ADDRESSES_VALIDATION_SKIP_KEY = 'skipAddressValidation'
// Address validation timeout is set because we don't want the user to wait a lot of time
// if the backend responds slowly, and we prefer to enable the user to finish it's order without
// validating the address.
export const ADDRESS_VALIDATION_TIMEOUT_MS = 6 * 1000

export const TAXES_FOR_ADDRESS_KEY = 'taxesForAddress'

export const ADDRESSES_KEY = 'currentAddress'

export const ERROR_CODES = {
  countryUnsupported: 'E.COUNTRY.UNSUPPORTED',
  engineUnavailable: 'E.ENGINE.UNAVAILABLE',
  queryUnanswerable: 'E.QUERY.UNANSWERABLE',
  addressNotFound: 'E.ADDRESS.NOT_FOUND',
  secondaryInformationInvalid: 'E.SECONDARY_INFORMATION.INVALID',
  secondaryInformationMissing: 'E.SECONDARY_INFORMATION.MISSING',
  houseNumberMissing: 'E.HOUSE_NUMBER.MISSING',
  houseNumberInvalid: 'E.HOUSE_NUMBER.INVALID',
  streetMissing: 'E.STREET.MISSING',
  streetInvalid: 'E.STREET.INVALID',
  boxNumberMissing: 'E.BOX_NUMBER.MISSING',
  boxNumberInvalid: 'E.BOX_NUMBER.INVALID',
  addressInvalid: 'E.ADDRESS.INVALID',
  zipNotFound: 'E.ZIP.NOT_FOUND',
  zipInvalid: 'E.ZIP.INVALID',
  stateZipMismatch: 'E.ZIP.STATE_MISMATCH',
  zipPlus4NotFound: 'E.ZIP.PLUS4.NOT_FOUND',
  addressMultiple: 'E.ADDRESS.MULTIPLE',
  addressInsufficient: 'E.ADDRESS.INSUFFICIENT',
  addressDual: 'E.ADDRESS.DUAL',
  streetMagnet: 'E.STREET.MAGNET',
  cityStateInvalid: 'E.CITY_STATE.INVALID',
  stateInvalid: 'E.STATE.INVALID',
  addressDeliveryInvalid: 'E.ADDRESS.DELIVERY.INVALID',
  timedOut: 'E.TIMED_OUT',
  timeZoneUnavailable: 'E.TIME_ZONE.UNAVAILABLE',
  poBoxInternational: 'E.PO_BOX.INTERNATIONAL',
}
