import { type HappyData, parseHappyData } from '../faceDetection'
import { loadImage } from 'mixtiles-web-common/services/imageLoader'
import { timeoutPromise } from '../../utils/promises'
import { padRect, unionRects } from '../../utils/rectUtils'
import { CROP_TYPE } from '../../utils/cropUtils.consts'
import { type Crop, type Rect } from '../../utils/cropUtils.types'
import { type PhotoFaceData } from '../../components/PhotoBank/PhotoBank.types'
import { analytics, EVENT_NAMES } from '../Analytics/Analytics'
import sortBy from 'lodash/sortBy'

export type SmartCropResult = {
  cropRect: Crop
  faceCount: number
  happyData?: HappyData
}

export async function calculateSmartCropFromUrl({
  imageUrl,
  targetAspectRatio,
  timeout,
  faces = [],
}: {
  imageUrl: string
  targetAspectRatio: number
  timeout: number
  faces: PhotoFaceData[] | undefined
}) {
  const image = await loadImage(imageUrl, true)
  return timeoutPromise(
    calculateSmartCrop({
      imageDimensions: {
        width: image.naturalWidth,
        height: image.naturalHeight,
      },
      targetAspectRatio,
      faces,
    }),
    timeout
  )
}

export class ExternalError extends Error {}

function fromRelativeCrop(crop: PhotoFaceData, width: number, height: number) {
  return {
    ...crop,
    x: crop.x * width,
    y: crop.y * height,
    width: crop.width * width,
    height: crop.height * height,
  }
}

// Calculate crop that maintains the aspect ratio of the image with added padding
function getFitCropRect(
  {
    imageWidth,
    imageHeight,
  }: {
    imageWidth: number
    imageHeight: number
  },
  uid: string
): Crop {
  analytics.track(EVENT_NAMES.CROP_ORIENTATION_FIT, {
    uid,
    method: CROP_TYPE.SMART,
  })
  const aspectRatio = imageWidth / imageHeight
  let mattedWidth
  let mattedHeight
  if (aspectRatio > 1) {
    mattedWidth = imageWidth * 1.25
    mattedHeight = imageHeight * 1.25 * aspectRatio
  } else {
    mattedHeight = imageHeight * 1.25
    mattedWidth = (imageWidth * 1.25) / aspectRatio
  }

  return {
    x: (imageWidth - mattedWidth) / 2,
    y: (imageHeight - mattedHeight) / 2,
    width: mattedWidth,
    height: mattedHeight,
    cropType: CROP_TYPE.SMART,
  }
}

// To avoid memory issues it is better to pass the low-res/thumbnail image rather than the full quality
export async function calculateSmartCrop({
  imageDimensions,
  targetAspectRatio,
  withHappyScore = false,
  faces,
  shouldFitIfFacesOverflow = false,
  uid = '',
}: {
  imageDimensions: { width: number; height: number }
  targetAspectRatio: number
  withHappyScore?: boolean
  faces: PhotoFaceData[] | undefined
  shouldFitIfFacesOverflow?: boolean
  uid?: string
}): Promise<SmartCropResult> {
  const relativeFaces =
    faces?.map((face) =>
      fromRelativeCrop(face, imageDimensions.width, imageDimensions.height)
    ) ?? []

  const facesToCrop = cullFarFaces(relativeFaces)

  const happyData = withHappyScore ? parseHappyData(facesToCrop) : undefined

  const facesRects = facesToCrop.map((detectionRect) => {
    // We add 1/2-face to the top of each face because the face detection API
    // returns only the actual face without the top of the head.
    // This seems to do the job pretty well.
    return padRect(
      detectionRect,
      {
        top: detectionRect.height * 0.3,
      },
      imageDimensions
    )
  })

  if (facesRects.length === 0) {
    throw new ExternalError('No faces found. Cannot calculate smart crop.')
  }
  const cropRect = createCropWithFaces({
    naturalWidth: imageDimensions.width,
    naturalHeight: imageDimensions.height,
    faces: facesRects,
    targetAspectRatio,
  })
  const contained = isContainingRect(unionRects(...facesRects), cropRect)

  return {
    cropRect:
      // we don't want to zoom-out if:
      // 1. the image is already contained in the crop rect
      // 2. shouldFitIfFacesOverflow - will be true for the photo album
      //  and we want to fit the faces in the crop rect
      // 3. if we detect only one face, we don't want to zoom-out
      !contained && shouldFitIfFacesOverflow && relativeFaces.length > 1
        ? getFitCropRect(
            {
              imageWidth: imageDimensions.width,
              imageHeight: imageDimensions.height,
            },
            uid
          )
        : cropRect,
    faceCount: facesToCrop.length,
    happyData,
  }
}

function cullFarFaces(faces: PhotoFaceData[], scale = 9) {
  const maxFaceSize = Math.max(
    ...faces.map(({ width, height }) => width * height)
  )

  return faces.filter((f) => f.width * f.height >= maxFaceSize / scale)
}

function isContainingRect(rect: Rect, otherRect: Rect) {
  return (
    rect.x >= otherRect.x &&
    rect.y >= otherRect.y &&
    rect.x + rect.width <= otherRect.x + otherRect.width &&
    rect.y + rect.height <= otherRect.y + otherRect.height
  )
}

export function createCropWithFaces({
  naturalWidth,
  naturalHeight,
  targetAspectRatio,
  faces,
}: {
  naturalWidth: number
  naturalHeight: number
  targetAspectRatio: number
  faces: Rect[]
}): Crop {
  const allFacesRect = unionRects(...faces)
  const imageAspectRatio = naturalWidth / naturalHeight
  let width
  let height
  let x
  let y

  // photo is wider than crop. when target aspect ratio is 1 - landscape mode
  if (imageAspectRatio >= targetAspectRatio) {
    width = naturalWidth * (targetAspectRatio / imageAspectRatio)
    height = naturalHeight
    y = 0

    // Center crop on the faces rect
    x = allFacesRect.x + allFacesRect.width / 2 - width / 2

    // Make sure the crop is within the image
    x = clampValue({
      value: x,
      min: 0,
      max: naturalWidth - width,
    })
  }
  // photo is narrower than target
  else {
    width = naturalWidth
    height = naturalHeight * (imageAspectRatio / targetAspectRatio)

    x = 0

    const highestFace = sortBy(faces, (face) => face.y)[0]

    y =
      highestFace.y +
      // the eye is at the 50% of the face
      highestFace.height / 2 -
      // position the eye at the upper third of the frames
      height / 3

    y = clampValue({
      value: y,
      // The min size should still contain allFacesRect
      min: 0,
      max: Math.max(
        naturalHeight - height,
        allFacesRect.y + allFacesRect.height - height,
        0
      ),
    })
  }

  return {
    width: Math.round(width),
    height: Math.round(height),
    x: Math.round(x),
    y: Math.round(y),
    cropType: CROP_TYPE.SMART,
  }
}

function clampValue({
  value,
  min,
  max,
}: {
  value: number
  min: number
  max: number
}) {
  if (value < min) {
    return min
  }

  if (value > max) {
    return max
  }

  return value
}
