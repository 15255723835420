import { type CheckoutSummaryProduct } from '../../components/Pricing/types/checkout.types'
import {
  hashTilePricingProduct,
  ProductIds,
} from '@mixtiles/web-backend-shared'

export function checkoutSummaryProductId(product: CheckoutSummaryProduct) {
  return `${
    product.id === ProductIds.SKU_TILES && product.pricingProduct
      ? hashTilePricingProduct(product.pricingProduct)
      : product.id
  }-${product.quantity}-${'value' in product ? product.value : ''}`
}
