import { useState } from 'react'
import useLocalStorageState from './useLocalStorageState'
import { logger } from '../services/logger'
import { analytics, EVENT_NAMES } from '../services/Analytics/Analytics'
import { translateManager } from '../services/TranslateManager'
import useOnMount from './useOnMount'

export default function useUsercentrics() {
  const [didAutoConsent, setDidAutoConsent] = useLocalStorageState(
    'didAutoConsent',
    false
  )
  const [isLoadingCentricsScript, setIsLoadingCentricsScript] = useState(true)

  const waitForUCInitialized = () => {
    return new Promise((resolve) => {
      if (window.UC_UI && window.UC_UI.isInitialized()) {
        resolve()
      } else {
        window.addEventListener('UC_UI_INITIALIZED', () => {
          resolve()
        })
      }
    })
  }

  useOnMount(() => {
    waitForUCInitialized().then(() => {
      window.UC_UI.updateLanguage(translateManager.getLanguage())
      if (window.UC_UI.isConsentRequired() || didAutoConsent) {
        window.UC_UI.showFirstLayer()
        analytics.track(EVENT_NAMES.SHOW_CONSENT_FIRST_LAYER)
        setDidAutoConsent(false)
      } else {
        analytics.track(EVENT_NAMES.NO_CONSENT_REQUIRED)
        logger.info('no consent required')
      }
      setIsLoadingCentricsScript(false)
    })
  })

  return {
    isLoadingCentricsScript,
  }
}
