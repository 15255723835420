import { type StateCreator } from 'zustand/vanilla'
import { type Store } from '../store.types'
import { type PricingSlice } from './pricingSlice.types'
import { getRelevantVariants } from '../../components/Pricing/PricingUtil'
import {
  fallbackUntilMatch,
  formatPrice,
  FRAME_COLORS,
  hashTilePricingProduct,
  MATERIAL_TYPES,
  type PricingProduct,
  type TILE_SIZES,
} from '@mixtiles/web-backend-shared'
import {
  getPricingMaterialType,
  isFramedMaterial,
} from '../../utils/materialTypeUtils'
import { getDiscountForSize } from '../../components/Pricing/Discounts/Discounts'
import {
  MIXED_MATERIALS,
  MIXED_SIZES,
} from '../../pages/PhotoStyler/TileDesignerConsts'
import { type PRODUCT_TYPES } from '../../services/ProductTypeState'
import {
  calculateBatchProductsPricing,
  getDiscounts,
  getPricingItems,
} from '../../components/Pricing/Pricing.api'
import {
  formatPricingItems,
  getPricingProductType,
  reportPricingError,
  shouldUseAPI,
} from './pricingUtils'
import { getOrderedSizes } from '../../pages/DiscoveryPage/DiscoveryConsts'
import { type ProductCollection } from '../../components/Pricing/types/checkout.types'
import { countryManager } from '../../services/CountryManager'
import { runAsyncWithRetries } from '../../utils/promises'
import { type FetchDiscountsResponse } from '../../components/Pricing/types/discount.types'
import {
  FETCH_PRICING_ITEMS_OPERATION_NAME,
  REFRESH_PRICING_NUM_RETRIES,
  RELOAD_DISCOUNTS_OPERATION_NAME,
} from './pricingConsts'
import { translateManager as t } from '../../services/TranslateManager'
import { type FetchPricingResponse } from '../../components/Pricing/types/PricingProvider.types'
import { currencyState } from '../../services/CurrencyState'
import { logger } from '../../services/logger'

export const createPricingSlice =
  (
    initialPricingData: any,
    experimentManager: any,
    api: any,
    dialog: any,
    productType: PRODUCT_TYPES,
    pricingCountry: string
  ): StateCreator<Store, [['zustand/persist', unknown]], [], PricingSlice> =>
  (set, get) => ({
    pricingData: initialPricingData,
    setPricingData: (pricingData: any) => {
      set(() => ({ pricingData }))
      get().fetchPricing()
    },
    lastPromoCode: null,
    isFirstInit: true,
    discounts: {
      value: [],
      isLoading: true,
    },
    discountId: '',
    error: null,
    pricingVariantsIds: getRelevantVariants(experimentManager),
    setPricingVariantsIds: (pricingVariantsIds: string[]) => {
      set(() => ({ pricingVariantsIds }))
      get().updatePricing()
    },
    priceDifferenceMap: null,
    currency: null,
    fetchDiscounts: async (forceCouponValidation?: boolean) => {
      await get().revalidateDiscountCoupon(forceCouponValidation || false)
      const promocode = get().discountCoupon?.code
      return api.call(
        getDiscounts({
          productType: getPricingProductType(productType),
          pricingCountry,
          variantsIds: get().pricingVariantsIds,
          promocode,
        })
      )
    },
    fetchDiscount: (
      tileSize: TILE_SIZES,
      material: MATERIAL_TYPES | typeof MIXED_MATERIALS,
      frameColor: FRAME_COLORS
    ) => {
      const backendMaterialType = getPricingMaterialType(material)
      if (!get().discounts.isLoading && !get().pricingData.isLoading) {
        return {
          value: getDiscountForSize({
            discounts: get().discounts.value,
            tileSize: material === MIXED_MATERIALS ? MIXED_SIZES : tileSize,
            currency: get().pricingData.currency,
            materialType: backendMaterialType,
            frameColor: isFramedMaterial(backendMaterialType)
              ? frameColor
              : undefined,
          }),
          isLoading: false,
        }
      } else {
        return { value: null, isLoading: true }
      }
    },
    fetchDiscountsForPromoCode: async (
      promocode: string,
      tileSize: TILE_SIZES,
      materialType: MATERIAL_TYPES = MATERIAL_TYPES.CLASSIC,
      frameColor: FRAME_COLORS,
      forceProductType: PRODUCT_TYPES | null = null
    ) => {
      const backendMaterialType =
        materialType === MATERIAL_TYPES.FRAMELESS
          ? MATERIAL_TYPES.CLASSIC
          : materialType
      const { discounts } = await api.call(
        getDiscounts({
          productType: forceProductType || getPricingProductType(productType),
          pricingCountry,
          variantsIds: get().pricingVariantsIds,
          promocode,
        })
      )
      return {
        value: getDiscountForSize({
          discounts,
          tileSize,
          currency: get().pricingData.currency,
          materialType: backendMaterialType,
          frameColor,
        }),
        isLoading: false,
      }
    },
    fetchPricingItem: (pricingProduct: PricingProduct) => {
      if (
        !pricingProduct ||
        !get().pricingData ||
        get().pricingData.isLoading ||
        get().pricingData.productType !== getPricingProductType(productType) ||
        pricingProduct.tileSize.includes(MIXED_SIZES) ||
        pricingProduct.materialType?.includes(MIXED_MATERIALS)
      ) {
        return { value: null, isLoading: true, currency: null }
      } else {
        const pricingItem = fallbackUntilMatch(
          pricingProduct,
          (pricingProduct: PricingProduct) =>
            get().pricingData.pricingItems[
              hashTilePricingProduct(pricingProduct)
            ]
        )

        if (!pricingItem) {
          if (
            getOrderedSizes(
              pricingProduct.materialType as MATERIAL_TYPES
            ).includes(pricingProduct.tileSize as TILE_SIZES) &&
            !pricingProduct.frameColor
          ) {
            // We report error if the given SKU should be in the pricing but it's not
            const message = `failed to find pricing item for tileSKU ${JSON.stringify(
              pricingProduct
            )}, ${JSON.stringify(get().pricingData.pricingItems)}`
            reportPricingError(
              message,
              new Error(message),
              pricingCountry,
              get().pricingVariantsIds,
              productType
            )
          }
          return { value: null, isLoading: true, currency: null }
        }
        return {
          value: pricingItem.price,
          currency: get().pricingData.currency,
          isLoading: false,
        }
      }
    },
    calculateBatchPricing: async (productsCollections: ProductCollection[]) => {
      return api.call(
        calculateBatchProductsPricing({
          productsCollections,
          productType,
          pricingCountry,
          variantsIds: get().pricingVariantsIds,
          promocode: get().lastPromoCode,
        })
      )
    },
    getMixColorPrice: () => {
      if (!get().priceDifferenceMap) {
        return null
      }

      return formatPrice({
        price:
          get().priceDifferenceMap[MATERIAL_TYPES.CLASSIC][FRAME_COLORS.BLACK],
        currency: get().pricingData.currency,
        shouldRound: false,
        roundingPrecision: false,
        country: countryManager.getPricingCountry(),
      })
    },
    handlePromoCodeChange: async (params?: {
      freeTiles?: boolean
      giftCard?: true
    }) => {
      const { freeTiles, giftCard } = params || {}
      const curPromocode = get().getDiscountCouponDisplayName()
      // Free tiles is the only exception because it doesn't get loaded into the local storage like other promocodes
      if (
        !shouldUseAPI(productType) ||
        (!freeTiles && !giftCard && get().lastPromoCode === curPromocode)
      ) {
        return
      }
      set((state) => ({ discounts: { ...state.discounts, isLoading: true } }))
      try {
        const { discounts: value } =
          await runAsyncWithRetries<FetchDiscountsResponse>({
            op: get().fetchDiscounts,
            opName: RELOAD_DISCOUNTS_OPERATION_NAME,
            numRetries: REFRESH_PRICING_NUM_RETRIES,
          })
        set(() => ({
          discounts: { value, isLoading: false },
          lastPromoCode: curPromocode,
        }))
      } catch (e: any) {
        get().handleError('failed to fetch discount after promocode update', e)
      }
    },
    handleError: (message: string, error: any) => {
      reportPricingError(
        message,
        error,
        pricingCountry,
        get().pricingVariantsIds,
        productType
      )
      set({ error })
      dialog.showAlert(
        t.get('general.errors.general_title'),
        t.get('general.errors.general_description'),
        t.get('general.refresh'),
        () => window.location.reload()
      )
    },
    updatePricing: async () => {
      if (shouldUseAPI(productType)) {
        get().setPricingData({ ...get().pricingData, isLoading: true })
        set((state) => ({
          error: null,
          pricingData: { ...state.pricingData, isLoading: true },
          discounts: { value: { ...state.discounts.value }, isLoading: true },
        }))
        try {
          const { pricingItems, currency, discounts, pricingProductType } =
            await runAsyncWithRetries<FetchPricingResponse>({
              op: get().fetchPricing,
              opName: FETCH_PRICING_ITEMS_OPERATION_NAME,
              numRetries: REFRESH_PRICING_NUM_RETRIES,
            })
          const formattedPricingItems = formatPricingItems(pricingItems)
          get().setPricingData({
            currency,
            pricingItems: formattedPricingItems,
            productType: pricingProductType,
            isLoading: false,
          })
          set(() => ({
            discounts: { value: discounts, isLoading: false },
          }))
          currencyState.setCurrency(currency)
        } catch (e: any) {
          get().handleError('Failed to reload price for user', e)
        }
      } else {
        set(() => ({ isFirstInit: false }))
      }
    },
    fetchPricing: async () => {
      logger.info(
        `[PricingProvider] - getPricingItems - productType: ${productType} ${pricingCountry} ${
          get().pricingVariantsIds
        }`
      )
      const pricingProductType = getPricingProductType(productType)
      const pricingItemsPromise = get().isFirstInit
        ? initialPricingData
        : api.call(
            getPricingItems({
              productType: pricingProductType,
              pricingCountry,
              variantsIds: get().pricingVariantsIds,
            })
          )
      set(() => ({ isFirstInit: false }))
      const [pricingItemRes, discountsRes] = await Promise.all([
        pricingItemsPromise,
        get().fetchDiscounts(),
      ])
      const { pricingItems, currency } = pricingItemRes

      return {
        pricingItems,
        currency,
        discounts: discountsRes.discounts,
        pricingProductType,
      }
    },
  })
