import { Links, Meta } from '@remix-run/react'
import { createHead } from 'remix-island'

// User centrics scripts should appear before all other scripts in the head - so they can block 3rd party requests before they are sent
// Reference: https://usercentrics.atlassian.net/wiki/spaces/SKB/pages/223379597/How+do+I+install+the+Smart+Data+Protector+on+my+website
function getUsercentricsScripts() {
  return (
    <>
      {/* <!-- This script blocks non consented requests https://docs.usercentrics.com/#/smart-data-protector --> */}
      <script
        id="usercentrics-cmp"
        src="https://app.usercentrics.eu/browser-ui/latest/loader.js"
        data-ruleset-id="MU39HE-m4BxMR3"
        data-avoid-prefetch-services=""
      />
      <script src="https://privacy-proxy.usercentrics.eu/latest/uc-block.bundle.js" />
    </>
  )
}

export const Head = createHead(
  () => (
    <>
      <Meta />
      <Links />
      {getUsercentricsScripts()}
    </>
  ),
  { cleanup: true, cleanupTimeout: 1000 }
)
