import {
  BLEED_TYPES,
  getBleed,
  getPrintedImageDimensions,
  MATERIAL_TYPES,
  MATTING,
  type TILE_SIZES,
} from '@mixtiles/web-backend-shared'
import {
  BOTTOM_ROTATION_DEGREES,
  RIGHT_ROTATION_DEGREES,
} from '../components/Tile/CanvasTile/CanvasTile.consts'
import { sin } from './utils'
import type { Crop, Rect } from './cropUtils.types'
import type { Tile } from '../components/TilesProvider/TilesProvider.types'
import { toAbsoluteRect, toRelativeRect } from './rectUtils'

function calculateBleedRatio({
  bleedWidth,
  bleedHeight,
  width,
  height,
}: {
  bleedWidth: number
  bleedHeight: number
  width: number
  height: number
}) {
  const horizontalSideSize = bleedWidth / (2 * bleedWidth + width)
  const verticalSideSize = bleedHeight / (2 * bleedHeight + height)
  return { horizontalSideSize, verticalSideSize }
}

export function applyBleedOnCrop(
  cropParams: Crop,
  {
    pageWidth,
    pageHeight,
    bleedSize,
  }: {
    pageWidth: number // Width of the page including bleed (in inches)
    pageHeight: number // Height of the page including bleed (in inches)
    bleedSize: number // Size of the bleed to be added from each edge (in inches)
  }
): Crop {
  const appliedCropParams = { ...cropParams }

  const { horizontalSideSize, verticalSideSize } = calculateBleedRatio({
    bleedWidth: bleedSize,
    bleedHeight: bleedSize,
    width: pageWidth,
    height: pageHeight,
  })
  const relativeHorizontalSide = horizontalSideSize * appliedCropParams.width
  const relativeVerticalSide = verticalSideSize * appliedCropParams.height

  appliedCropParams.x += relativeHorizontalSide
  appliedCropParams.y += relativeVerticalSide
  appliedCropParams.width -= 2 * relativeHorizontalSide
  appliedCropParams.height -= 2 * relativeVerticalSide
  return appliedCropParams
}

// Calculate the size of the sides (bleed) of the tile relative to the tile size
export function getSideToTileSizeRatio({
  tileSize,
  materialType,
  bleedWidth,
  bleedHeight,
}: {
  tileSize: TILE_SIZES
  materialType: MATERIAL_TYPES
  bleedWidth: number
  bleedHeight: number
}) {
  const { jigImageWidth, jigImageHeight } = getPrintedImageDimensions({
    materialType,
    tileSize,
    isPlastic: false,
  })

  return calculateBleedRatio({
    bleedWidth,
    bleedHeight,
    width: jigImageWidth,
    height: jigImageHeight,
  })
}

type RelativeCropRectParams = {
  relativeCropRect: Rect
  absoluteCropRect?: never
}

type AbsoluteCropRectParams = {
  relativeCropRect?: never
  absoluteCropRect: Rect
}

export function getPreviewCropRect({
  relativeCropRect,
  absoluteCropRect,
  tileSize,
  materialType,
  isPreviewWithoutBleed,
  matting,
}: {
  tileSize: TILE_SIZES
  materialType: MATERIAL_TYPES
  isPreviewWithoutBleed?: boolean
  matting: MATTING
} & (RelativeCropRectParams | AbsoluteCropRectParams)): {
  x: number
  y: number
  width: number
  height: number
} {
  const { bleedType, bleedWidth, bleedHeight } = getBleed({
    tileSize,
    materialType,
    shouldAddBuffer: isPreviewWithoutBleed,
  })

  // Convert absolute to relative if needed
  const workingCropRect =
    relativeCropRect ??
    toRelativeRect(absoluteCropRect, {
      width: absoluteCropRect.width,
      height: absoluteCropRect.height,
    })

  if (
    [BLEED_TYPES.BUFFER, BLEED_TYPES.FULL_WRAP].includes(bleedType) &&
    matting === MATTING.NONE
  ) {
    const { horizontalSideSize, verticalSideSize } = getSideToTileSizeRatio({
      tileSize,
      materialType,
      bleedWidth,
      bleedHeight,
    })
    const relativeHorizontalSide = horizontalSideSize * workingCropRect.width
    const relativeVerticalSide = verticalSideSize * workingCropRect.height

    const result = {
      x: workingCropRect.x + relativeHorizontalSide,
      y: workingCropRect.y + relativeVerticalSide,
      width: workingCropRect.width - 2 * relativeHorizontalSide,
      height: workingCropRect.height - 2 * relativeVerticalSide,
    }

    // Convert back to absolute if input was absolute
    return absoluteCropRect
      ? toAbsoluteRect(result, {
          width: absoluteCropRect.width,
          height: absoluteCropRect.height,
        })
      : result
  } else {
    return absoluteCropRect ?? workingCropRect
  }
}

// When a tile has a buffer bleed we need the cropper to only show the parts that are printed and remain after removing the bleed.
// In order to do it, we scale the cropper, so the part which appears inside the frame will only be the parts that actually get printed.
// To avoid showing the "bleed parts" after scaling the cropper, we added a mask container that hides the overflows (S.BleedMaskContainer).
export function getCropperTransform({
  materialType,
  size,
  matting,
}: {
  materialType: MATERIAL_TYPES
  size: TILE_SIZES
  matting: MATTING
}): string | undefined {
  const { bleedHeight, bleedWidth, bleedType } = getBleed({
    materialType,
    tileSize: size,
    shouldAddBuffer: true,
  })
  if (bleedType === BLEED_TYPES.BUFFER && matting === MATTING.NONE) {
    const relativeBleedSize = getSideToTileSizeRatio({
      tileSize: size,
      materialType,
      bleedWidth,
      bleedHeight,
    })
    const scale = {
      x: 1 / (1 - 2 * relativeBleedSize.horizontalSideSize),
      y: 1 / (1 - 2 * relativeBleedSize.verticalSideSize),
    }
    return `scale(${scale.x}, ${scale.y})`
  } else if (materialType === MATERIAL_TYPES.CANVAS) {
    const relativeBleedSize = getSideToTileSizeRatio({
      tileSize: size,
      materialType,
      bleedWidth,
      bleedHeight,
    })

    // Scale the photo, so the parts of the bleed will be outside the main view of the frame
    const bleedScale = {
      x: 1 / (1 - 2 * relativeBleedSize.horizontalSideSize),
      y: 1 / (1 - 2 * relativeBleedSize.verticalSideSize),
    }

    // Calculate the size of the image with the sides of the canvas (as we render)
    const relativeImageWithSides = {
      x: 1 + relativeBleedSize.horizontalSideSize * sin(RIGHT_ROTATION_DEGREES),
      y: 1 + relativeBleedSize.verticalSideSize * sin(BOTTOM_ROTATION_DEGREES),
    }

    const scale = {
      x: bleedScale.x / relativeImageWithSides.x,
      y: bleedScale.y / relativeImageWithSides.y,
    }

    return `scale(${scale.x}, ${scale.y}) translate(${-scale.x}%, ${-scale.y}%)`
  } else {
    return undefined
  }
}

export type BleedOffsets = {
  horizontalSideSize: number
  verticalSideSize: number
}
export function getBleedByTile({
  tileSize,
  materialType,
}: Pick<Tile, 'tileSize' | 'materialType'>): BleedOffsets {
  const { bleedWidth, bleedHeight } = getBleed({
    tileSize,
    materialType,
    shouldAddBuffer: false,
  })

  return getSideToTileSizeRatio({
    materialType,
    tileSize,
    bleedWidth,
    bleedHeight,
  })
}
