import { ADDRESS_VALIDATION_TIMEOUT_MS } from './addressConsts'
import { type Address, type AddressSlice } from './addressSlice.types'
import UserManager from '../../services/UserManager'
import { normalizeAddress } from './addressUtils'
import { mixtilesAxios } from '../../utils/ApiUtils'
import { logger } from '../../services/logger'
import { COUNTRIES } from '../../config/countries-data'
import { type StateCreator } from 'zustand/vanilla'
import { type Store } from '../store.types'

export const createAddressSlice: StateCreator<
  Store,
  [['zustand/persist', unknown]],
  [],
  AddressSlice
> = (set, get) => ({
  addressValidationSkipped: null,
  setAddressValidationSkipped: (skip: boolean) => {
    return set(() => ({
      addressValidationSkipped: skip,
    }))
  },

  currentAddress: null,
  setCurrentAddress: (address: Address, persist: boolean) => {
    if (address?.fullName) {
      UserManager.setUserFullName(address.fullName, persist)
    }
    return set(() => ({
      currentAddress: address,
    }))
  },

  currentNormalizedAddress: (get: () => AddressSlice) =>
    normalizeAddress(get().currentAddress, !!get().addressValidationSkipped),

  taxForAddress: null,
  updateTaxForAddress: async () => {
    const address = get().currentNormalizedAddress
    if (!address) {
      set({ taxForAddress: null })
      return
    }

    try {
      const response = await mixtilesAxios.post(
        'v3/avatax/taxByAddress',
        { address },
        { timeout: ADDRESS_VALIDATION_TIMEOUT_MS }
      )
      const { taxes } = response.data
      set({ taxForAddress: taxes })
    } catch (e: any) {
      // Status code 400 means address is invalid, we don't want to error on it
      if (!e.response || e.response.status !== 400) {
        logger.error('Failed Receiving Tax from BE', e)
      }
      // If the request fails, we want to use the FE's default taxes
      set({ taxForAddress: null })
    }
  },

  removeAddressIfNotSupported: () =>
    set((state: AddressSlice) => {
      if ((state.currentAddress?.countryId || '') in COUNTRIES) {
        return {
          currentAddress: null,
          taxForAddress: null,
        }
      } else {
        return state
      }
    }),

  clearData: () => {
    return set(() => ({
      addressValidationSkipped: false,
      currentAddress: null,
      taxForAddress: null,
    }))
  },
})
