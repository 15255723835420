import { type StateCreator } from 'zustand/vanilla'
import { type Store } from '../../store.types'
import { type GiftCardSlice } from './giftCardSlice.types'
import { callValidateCoupon } from '../promoCodeUtils'
import { type GiftCardBalance } from '../../../pages/GiftCard/GiftCard.types'
import { isValidGiftCard } from '../../../pages/GiftCard/utils'

export const createGiftCardSlice: StateCreator<
  Store,
  [['zustand/persist', unknown]],
  [],
  GiftCardSlice
> = (set, get) => ({
  giftCardCreditsCodes: [],
  addGiftCardCreditsCode: (code: string) => {
    if (!get().giftCardCreditsCodes.includes(code)) {
      set((state) => ({
        giftCardCreditsCodes: [...state.giftCardCreditsCodes, code],
      }))
    }
  },
  removeGiftCardCreditsCode: (code: string) => {
    const filteredCodes = get().giftCardCreditsCodes.filter((c) => c !== code)
    set(() => ({ giftCardCreditsCodes: filteredCodes }))
  },
  revalidateGiftCardCodes: async () => {
    const giftCardCodes = get().giftCardCreditsCodes
    if (giftCardCodes.length > 0) {
      await Promise.all(
        giftCardCodes.map(async (code) => {
          const couponData = await callValidateCoupon(code)
          if (couponData && !couponData.valid) {
            get().removeGiftCardCreditsCode(code)
          }
        })
      )
    }
  },
  removeInvalidGiftCards: (giftsBalances: GiftCardBalance[]) => {
    giftsBalances.forEach((giftCard) => {
      if (!isValidGiftCard(giftCard)) {
        get().removeGiftCardCreditsCode(giftCard.code)
      }
    })
  },
})
