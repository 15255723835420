import {
  BLEED_TYPES,
  getBleed,
  getPrintedImageDimensions,
  MATERIAL_TYPES,
  MATTING,
  TILE_SIZES,
} from '@mixtiles/web-backend-shared'
import omitBy from 'lodash/omitBy'
import { getMattingRect } from './mattingUtils'
import { CROP_TYPE } from './cropUtils.consts'

const ASPECT_RATIO_ERR_BOUNDARY = 0.01

const AVAILABLE_MATERIAL_TYPES = omitBy(
  MATERIAL_TYPES,
  (value) => value === MATERIAL_TYPES.STICKER
)

const AVAILABLE_MATTING = omitBy(MATTING, (value) =>
  [MATTING.ULTRA_DEEP, MATTING.TOP, MATTING.ROUND, MATTING.HEART].includes(
    value
  )
)
const AVAILABLE_TILE_SIZES = omitBy(TILE_SIZES, (value) =>
  [
    TILE_SIZES.SPLIT_24X24,
    TILE_SIZES.SPLIT_24X32,
    TILE_SIZES.SPLIT_27X40,
    TILE_SIZES.SPLIT_32X24,
    TILE_SIZES.SPLIT_40X27,
    TILE_SIZES.CIRCLE_10,
    TILE_SIZES.RECTANGLE_25X8,
    TILE_SIZES.RECTANGLE_4X6,
    TILE_SIZES.RECTANGLE_6X4,
    TILE_SIZES.RECTANGLE_6X8,
    TILE_SIZES.RECTANGLE_8X6,
    TILE_SIZES.SQUARE_30X30,
    TILE_SIZES.SQUARE_40X40,
    TILE_SIZES.RECTANGLE_16X20,
    TILE_SIZES.RECTANGLE_20X16,
  ].includes(value)
)

// Leaves 2 decimal points for the float. i.e: decimal2Points(1.56336813) = 1.56
const decimal2Points = (num) => parseFloat(num.toFixed(2))

export function isCropInCorrectRatio({ cropRect, targetAspectRatio }) {
  const cropAspectRatio = cropRect.width / cropRect.height

  // If there is a tiny difference between the target and actual, its OK
  return (
    Math.abs(1 - cropAspectRatio / targetAspectRatio) <=
    ASPECT_RATIO_ERR_BOUNDARY
  )
}

/**
 * Calculates the wanted aspect-ratio for the cropped zone for a specific tileSize and matting.
 *
 * When a matting is added to an image, the zone which the image fits in will probably have a different aspect ratio.
 * This function calculates the target aspect ratio for the "inner zone" of the photo (inside the matting)
 */
export function getCropTargetAspectRatio({
  mattingType,
  materialType,
  tileSize,
}) {
  const { bleedType } = getBleed({
    tileSize,
    materialType,
    shouldAddBuffer: true,
  })
  const { jigImageWidth, jigImageHeight } = getPrintedImageDimensions({
    materialType,
    tileSize,
    isPlastic: false,
  })
  if (bleedType !== BLEED_TYPES.NONE && mattingType === MATTING.NONE) {
    return decimal2Points(jigImageWidth / jigImageHeight || 1)
  } else {
    const mattingRect = getMattingRect({
      width: jigImageWidth,
      height: jigImageHeight,
      mattingType,
      tileSize,
    })

    if (!mattingRect) {
      return 1
    }

    return decimal2Points(mattingRect.width / mattingRect.height)
  }
}

export function calculateAllPossibleRatios() {
  const allAspectRatios = new Set()
  for (const materialType of Object.values(AVAILABLE_MATERIAL_TYPES)) {
    for (const mattingType of Object.values(AVAILABLE_MATTING)) {
      for (const tileSize of Object.values(AVAILABLE_TILE_SIZES)) {
        // Need to remove this condition if we want to support canvas with matting
        if (
          [
            MATERIAL_TYPES.FRAMELESS,
            MATERIAL_TYPES.CLASSIC,
            MATERIAL_TYPES.WIDE_FRAME,
          ].includes(materialType) ||
          mattingType === MATTING.NONE
        ) {
          const aspectRatio = getCropTargetAspectRatio({
            mattingType,
            materialType,
            tileSize,
          })
          allAspectRatios.add(aspectRatio)
        }
      }
    }
  }

  return Array.from(allAspectRatios)
}

// This function calculates the crop rect for every possible aspect ratio, based on a given crop rect that was made for a specific ratio
export function fitCropToAllRatios({
  ratiosToCalculate,
  cropRect,
  currentAspectRatio,
  imageWidth,
  imageHeight,
  zoom = cropRect.zoom,
}) {
  const cropCenterX = cropRect.x + cropRect.width / 2
  const cropCenterY = cropRect.y + cropRect.height / 2
  // We want to keep the crop area the same for every ratio, as much as possible
  const cropArea = cropRect.width * cropRect.height

  zoom = zoom || cropRect.zoom
  const cropParams = {
    [currentAspectRatio]: { ...cropRect, zoom, cropType: CROP_TYPE.MANUAL },
  }
  ratiosToCalculate.forEach((ratio) => {
    if (Number(ratio) === currentAspectRatio) {
      return
    }

    // We want to find a new crop width and height, that will fit the new ratio, and will have the same amount of pixels (crop area) as the current crop
    // So for every ratio, we have this two equations we need to solve: (1) cropArea = cropWidth * cropHeight (2) ratio = cropWidth / cropHeight
    // So cropWidth = cropArea / cropHeight and cropWidth = cropHeight * ratio
    // Then, cropWidth^2 = cropArea * ratio
    let cropWidth = Math.sqrt(cropArea * ratio)
    let cropHeight = cropArea / cropWidth
    let cropX = cropCenterX - cropWidth / 2
    let cropY = cropCenterY - cropHeight / 2

    if (cropWidth > imageWidth) {
      cropWidth = imageWidth
      cropHeight = cropWidth / ratio
      cropX = 0
      zoom = 1
    }
    if (cropHeight > imageHeight) {
      cropHeight = imageHeight
      cropWidth = cropHeight * ratio
      cropY = 0
      zoom = 1
    }

    cropX = Math.max(0, cropX)
    cropY = Math.max(0, cropY)
    cropX = Math.min(imageWidth - cropWidth, cropX)
    cropY = Math.min(imageHeight - cropHeight, cropY)

    cropParams[ratio] = {
      x: Math.round(cropX),
      y: Math.round(cropY),
      width: Math.round(cropWidth),
      height: Math.round(cropHeight),
      zoom,
      cropType: CROP_TYPE.FIT,
    }
  })

  return cropParams
}
