import { styled } from 'styled-components'
import { m } from 'framer-motion'
import { onMediumScreen } from '../../mixtiles-web-common'

export const StickyContainer = styled(m.div)`
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 9px 9px;
  z-index: 5;
  backdrop-filter: blur(20px);
  background-color: rgba(253, 251, 252, 0.5);

  ${onMediumScreen()} {
    border-top: 1px solid rgba(0, 0, 0, 0.07);
    background: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(37px);
  }
`

export const LetsGoButton = styled.button`
  width: 100%;
  height: 52px;
  max-height: 52px;
  background-color: rgba(236, 0, 91, 0.88);
  cursor: pointer;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  padding: 0;
  display: flex;
  border: 0;
  color: #fff;
  text-align: center;
  letter-spacing: 0.1px;
  font-size: 20px;
  font-weight: 700;
  transition: transform 0.1s;

  &:active {
    transform: scale(0.97);
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryAccent};
  }

  ${onMediumScreen()} {
    max-width: 265px;
    margin: auto;
  }
`
