import pick from 'lodash/pick'
import StorageManager from '../StorageManager'
import {
  type BookBasicData,
  type Cover,
  type Page,
} from '../../components/PhotoBook/PhotoBookProvider/PhotoBook.types'
import {
  SAVE_PHOTO_KEYS,
  type StoredPage,
  type StoredPhotoBook,
} from './photoBookSaver.types'
import { type BookSize } from '@mixtiles/web-backend-shared'
import {
  ACCOUNT_PHOTO_BOOK_STORAGE_KEY,
  PHOTO_BOOK_STORAGE_KEY,
} from '../../components/PhotoBook/PhotoBook.consts'
import isEqual from 'lodash/isEqual'
import { photosInPages } from './photoBookUtils'

export function getStorablePhotoBook(
  cover: Cover,
  pages: Page[],
  size: BookSize,
  quantity: number
): StoredPhotoBook {
  const pagesToSave: StoredPage[] = pages.map((page) => ({
    front: {
      photos: page.front.photos,
    },
    back: {
      photos: page.back.photos,
    },
  }))

  return {
    pages: pagesToSave,
    cover: {
      caption: cover.caption,
      photo: pick(cover.photo, SAVE_PHOTO_KEYS),
    },
    size,
    quantity,
  }
}

export const savePhotoBookLocally = (
  cover: Cover,
  pages: Page[],
  size: BookSize,
  quantity: number
) => {
  const storedPhotoBook: StoredPhotoBook = getStorablePhotoBook(
    cover,
    pages,
    size,
    quantity
  )
  StorageManager.set(PHOTO_BOOK_STORAGE_KEY, storedPhotoBook)
}

export const clearPhotoBook = () => {
  StorageManager.set(PHOTO_BOOK_STORAGE_KEY, null)
  StorageManager.set(ACCOUNT_PHOTO_BOOK_STORAGE_KEY, null)
}

export const loadPhotoBook = (
  storageKey = PHOTO_BOOK_STORAGE_KEY
): StoredPhotoBook | null => {
  const storedPhotoBook = StorageManager.get(storageKey)

  if (
    !storedPhotoBook ||
    !storedPhotoBook?.cover?.photo?.uid ||
    !storedPhotoBook.pages
  ) {
    return null
  }

  return storedPhotoBook
}

export const isPhotoBookEmpty = (storageKey?: string) => {
  return loadPhotoBook(storageKey) === null
}

const getBookBasicData = (storageKey: string): BookBasicData | undefined => {
  const data = loadPhotoBook(storageKey)
  if (data) {
    const captionText = data.cover.caption.text
    const coverPhotoUid = data.cover?.photo?.uid
    const pages = photosInPages(data.pages as Page[]).map((page) =>
      pick(page, ['uid', 'cropParams', 'lowQualityApproved'])
    )
    if (captionText && coverPhotoUid) {
      return { cover: { captionText, coverPhotoUid }, pages }
    }
  }
}

export const getBookBasicDataByStorageKey = (): {
  localStoragePhotoBook: BookBasicData | undefined
  accountPhotoBook: BookBasicData | undefined
  isEqualCover: boolean
  isEqualBook: boolean
} => {
  const localStoragePhotoBook = getBookBasicData(PHOTO_BOOK_STORAGE_KEY)
  const accountPhotoBook = getBookBasicData(ACCOUNT_PHOTO_BOOK_STORAGE_KEY)

  return {
    localStoragePhotoBook,
    accountPhotoBook,
    isEqualCover:
      !!localStoragePhotoBook &&
      !!accountPhotoBook &&
      isEqual(localStoragePhotoBook.cover, accountPhotoBook.cover),
    isEqualBook:
      !!localStoragePhotoBook &&
      !!accountPhotoBook &&
      isEqual(localStoragePhotoBook, accountPhotoBook),
  }
}

export const clearAccountPhotoBook = () =>
  StorageManager.set(ACCOUNT_PHOTO_BOOK_STORAGE_KEY, null)
