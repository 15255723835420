export const ASSET_DIMENSIONS = { width: 1126, height: 1133 }
export const ASSET_CORNER_DIMENSIONS = {
  topRight: { width: 26, height: 25 },
  bottomLeft: { width: 54, height: 34.5 },
}
export const ASSET_RELATIVE_BORDER = {
  top: ASSET_CORNER_DIMENSIONS.topRight.height / ASSET_DIMENSIONS.height,
  right: ASSET_CORNER_DIMENSIONS.topRight.width / ASSET_DIMENSIONS.width,
  bottom: ASSET_CORNER_DIMENSIONS.bottomLeft.height / ASSET_DIMENSIONS.height,
  left: ASSET_CORNER_DIMENSIONS.bottomLeft.width / ASSET_DIMENSIONS.width,
}

// The rotation angles, relative to the "surface", of the right and bottom parts in the asset, that create the 3D illusion.
// Having higher values means will simulate lifting more the canvas (from the bottom or the right side) and expose more of the sides of it.
// Should be decided by product.
export const RIGHT_ROTATION_DEGREES = 20
export const BOTTOM_ROTATION_DEGREES = 20
