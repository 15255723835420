import {
  MATTING,
  PHOTO_ORIENTATIONS,
  tileSizeToOrientation,
  DEEP_MATTING_SMALLER_EDGE_PROPORTION,
  TILE_SIZES,
} from '@mixtiles/web-backend-shared'

const HEART_HEIGHT = 526
const HEART_WIDTH = 605

const OVAL_NARROW_SIDE = 565
const OVAL_WIDE_SIDE = 856

function getEqualPaddingRect({ width, height, padding }) {
  const mattingPixels = Math.min(width, height) * padding
  return {
    x: mattingPixels,
    y: mattingPixels,
    width: width - 2 * mattingPixels,
    height: height - 2 * mattingPixels,
  }
}

function getDeepMattingRect({ width, height, orientation }) {
  if (orientation === PHOTO_ORIENTATIONS.SQUARE) {
    return getEqualPaddingRect({ width, height, padding: 0.28 })
  } else {
    const deepBorderPercentage = 0.25
    const smallEdgeBorderPercentage =
      deepBorderPercentage * DEEP_MATTING_SMALLER_EDGE_PROPORTION

    let verticalBorder
    let horizontalBorder

    if (orientation === PHOTO_ORIENTATIONS.LANDSCAPE) {
      verticalBorder = width * deepBorderPercentage
      horizontalBorder = width * smallEdgeBorderPercentage
    } else {
      horizontalBorder = height * deepBorderPercentage
      verticalBorder = height * smallEdgeBorderPercentage
    }

    return {
      x: verticalBorder,
      y: horizontalBorder,
      width: width - verticalBorder * 2,
      height: height - horizontalBorder * 2,
    }
  }
}

function getRoundMattingRect({ width, height, orientation }) {
  if (orientation === PHOTO_ORIENTATIONS.SQUARE) {
    return getEqualPaddingRect({ width, height, padding: 0.17 })
  } else {
    let verticalBorder
    let horizontalBorder
    let imageHeight
    let imageWidth

    if (orientation === PHOTO_ORIENTATIONS.LANDSCAPE) {
      verticalBorder = height * 0.17
      imageHeight = height - verticalBorder * 2
      imageWidth = (imageHeight * OVAL_WIDE_SIDE) / OVAL_NARROW_SIDE
      horizontalBorder = (width - imageWidth) / 2
    } else {
      horizontalBorder = width * 0.17
      imageWidth = width - horizontalBorder * 2
      imageHeight = (imageWidth * OVAL_WIDE_SIDE) / OVAL_NARROW_SIDE
      verticalBorder = (height - imageHeight) / 2
    }

    return {
      x: verticalBorder,
      y: horizontalBorder,
      width: imageWidth,
      height: imageHeight,
    }
  }
}

function getHeartMattingRect({ width, height, orientation }) {
  let verticalBorder
  let horizontalBorder
  let imageWidth
  let imageHeight

  if (orientation === PHOTO_ORIENTATIONS.SQUARE) {
    horizontalBorder = width * 0.17
    imageWidth = width - horizontalBorder * 2
    imageHeight = (imageWidth * HEART_HEIGHT) / HEART_WIDTH
    verticalBorder = (height - imageHeight) / 2
  } else {
    if (orientation === PHOTO_ORIENTATIONS.LANDSCAPE) {
      verticalBorder = height * 0.15
      imageHeight = height - verticalBorder * 2
      imageWidth = (imageHeight * HEART_WIDTH) / HEART_HEIGHT
      horizontalBorder = (width - imageWidth) / 2
    } else {
      horizontalBorder = width * 0.15
      imageWidth = width - horizontalBorder * 2
      imageHeight = (imageWidth * HEART_HEIGHT) / HEART_WIDTH
      verticalBorder = (height - imageHeight) / 2
    }
  }
  return {
    x: horizontalBorder,
    y: verticalBorder,
    width: imageWidth,
    height: imageHeight,
  }
}

function getTopMattingRect({ width, orientation }) {
  if (orientation !== PHOTO_ORIENTATIONS.PORTRAIT) {
    return null
  }

  const mattingPixels = width * 0.15
  const newWidth = width - 2 * mattingPixels
  return {
    x: mattingPixels,
    y: mattingPixels,
    width: newWidth,
    height: 0.75 * newWidth,
  }
}

/**
 * Calculates the matting rectangle, the part of the frame contents in which the actual photo appears in.
 * @param width frame width
 * @param height frame height
 * @param {MATTING} mattingType
 * @param {TILE_SIZES} tileSize
 * @return {{x: number, width: number, y: number, height}|{x: number, width, y: number, height}|{x: *, width, y: *, height}}
 */
export function getMattingRect({ width, height, mattingType, tileSize }) {
  const orientation = tileSizeToOrientation(tileSize)
  const is22X44 = [
    TILE_SIZES.RECTANGLE_22X44,
    TILE_SIZES.RECTANGLE_44X22,
  ].includes(tileSize)

  switch (mattingType) {
    case MATTING.NONE:
      return getEqualPaddingRect({ width, height, padding: 0 })
    case MATTING.SHALLOW:
      return getEqualPaddingRect({ width, height, padding: 0.1 })
    case MATTING.MEDIUM:
      return getEqualPaddingRect({ width, height, padding: 0.2 })
    case MATTING.DEEP:
      return is22X44
        ? getEqualPaddingRect({ width, height, padding: 0.28 })
        : getDeepMattingRect({ width, height, orientation })
    case MATTING.ROUND:
      return getRoundMattingRect({ width, height, orientation })
    case MATTING.HEART:
      return getHeartMattingRect({ width, height, orientation })
    case MATTING.TOP:
      return getTopMattingRect({ width, orientation })
    default:
      return getEqualPaddingRect({ width, height, padding: 0 })
  }
}
