import { COUNTRIES } from '../../config/countries-data'
import {
  type Address,
  type AddressFormatThatShouldntExist,
  type NormalizedAddress,
} from './addressSlice.types'
import { translateManager as t } from '../../services/TranslateManager'
import { ADDRESS_VALIDATION_TIMEOUT_MS, ERROR_CODES } from './addressConsts'
import { mixtilesAxios } from '../../utils/ApiUtils'
import { analytics } from '../../services/Analytics/Analytics'

export function normalizeAddress(
  address: Address | null,
  addressValidationSkipped: boolean
): NormalizedAddress | null {
  if (!(address && address.countryId.toUpperCase() in COUNTRIES)) {
    return null
  }

  return {
    fullName: address.fullName,
    streetAddress: address.street,
    streetAddress2: address.address_2,
    city: address.city,
    state: address.state || '',
    zip: address.zipCode,
    country: COUNTRIES[address.countryId].code,
    phoneNumber: address.phoneNumber,
    shouldValidate: !addressValidationSkipped,
  }
}

// Converts to the local format.
// This function is the opposite of normalize address
export function denormalizeAddress(address: NormalizedAddress) {
  if (!address || !Object.keys(address).length) {
    return null
  }

  return {
    fullName: address.fullName,
    street: address.streetAddress,
    address_2: address.streetAddress2,
    city: address.city,
    state: address.state || '',
    zipCode: address.zip,
    countryId: (
      Object.values(COUNTRIES).find(({ code }) => code === address.country) ||
      {}
    ).id,
    phoneNumber: address.phoneNumber,
  }
}

export function validateAddress(address: Address) {
  const {
    fullName,
    countryId,
    street,
    address_2: address2,
    city,
    state,
    zipCode,
  } = address

  if (isPOBoxAddressOutsideUS(countryId, street)) {
    return Promise.resolve({
      isValid: false,
      allowEditAddress: false,
      validationErrors: [
        getMessageForEpostValidationError(ERROR_CODES.poBoxInternational),
      ],
    })
  }

  const addressValues = {
    name: fullName,
    country: countryId,
    street1: street,
    street2: address2,
    city,
    state: state || '',
    zip: zipCode,
  }
  return callValidateAddress(addressValues)
}

function isPOBoxAddressOutsideUS(countryId: string, street: string) {
  if (!countryId || countryId.toUpperCase() === 'UNITED_STATES' || !street) {
    return false
  }

  return (
    !!street.match(/\b(p\.?o\.?\s*)?box\b/i) ||
    !!street.match(/\bp\.?o\.?b\.?\b/i)
  )
}

function callValidateAddress(address: AddressFormatThatShouldntExist) {
  const data = {
    ...address,
    requiresOldFreeTiles: true,
  }

  return mixtilesAxios
    .post('v1/address/validate', data, {
      timeout: ADDRESS_VALIDATION_TIMEOUT_MS,
    })
    .then((response) => {
      const { isValid, validationErrors } = response.data
      if (isValid) {
        return { isValid: true, allowEditAddress: true }
      }

      analytics.track('Address Validation Failed', {
        Error: JSON.stringify(validationErrors),
      })
      const validationErrorMessages = validationErrors
        .map((error: any) => getMessageForEpostValidationError(error.code))
        .filter((message: string) => message !== '')
      if (validationErrorMessages.length === 0) {
        validationErrorMessages.push(
          t.get('general.address_form.validation_errors.address.invalid')
        )
      }
      return {
        isValid,
        allowEditAddress: true,
        validationErrors: validationErrorMessages,
      }
    })
}

function getMessageForEpostValidationError(
  errorCode: (typeof ERROR_CODES)[keyof typeof ERROR_CODES]
) {
  switch (errorCode) {
    case ERROR_CODES.countryUnsupported:
      return t.get('general.address_form.validation_errors.country.unsupported')
    case ERROR_CODES.addressInvalid:
      return t.get('general.address_form.validation_errors.address.invalid')
    case ERROR_CODES.addressNotFound:
      return t.get('general.address_form.validation_errors.address.not_found')
    case ERROR_CODES.secondaryInformationInvalid:
      return t.get(
        'general.address_form.validation_errors.secondary_information.invalid'
      )
    case ERROR_CODES.secondaryInformationMissing:
      return t.get(
        'general.address_form.validation_errors.secondary_information.missing'
      )
    case ERROR_CODES.houseNumberMissing:
      return t.get(
        'general.address_form.validation_errors.house_number.missing'
      )
    case ERROR_CODES.houseNumberInvalid:
      return t.get(
        'general.address_form.validation_errors.house_number.invalid'
      )
    case ERROR_CODES.streetMissing:
      return t.get('general.address_form.validation_errors.street.missing')
    case ERROR_CODES.streetInvalid:
      return t.get('general.address_form.validation_errors.street.invalid')
    case ERROR_CODES.zipNotFound:
      return t.get('general.address_form.validation_errors.zip.not_found')
    case ERROR_CODES.stateZipMismatch:
      return t.get('general.address_form.validation_errors.zip.state_mismatch')
    case ERROR_CODES.zipInvalid:
      return t.get('general.address_form.validation_errors.zip.invalid')
    case ERROR_CODES.cityStateInvalid:
      return t.get('general.address_form.validation_errors.city_state.invalid')
    case ERROR_CODES.stateInvalid:
      return t.get('general.address_form.validation_errors.state.invalid')
    case ERROR_CODES.poBoxInternational:
      return t.get('general.address_form.validation_errors.street.po_box')
    default:
      return ''
  }
}
