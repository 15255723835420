import { type StateCreator } from 'zustand/vanilla'
import { type Store } from '../store.types'
import { type Coupon, type PromoCodeSlice } from './promoCodeSlice.types'
import {
  callValidateCoupon,
  getGivenDiscountCouponType,
  handleAlreadyRegisteredError,
  handleExistingCredit,
  handlePromoCodeLoadResult,
  isValidCoupon,
  isValidForProductType,
} from './promoCodeUtils'
import {
  COUPON_REVALIDATION_BACKOFF_MINUTES,
  DISCOUNT_TYPES,
  PROMO_CODE_SOURCE,
} from './promoCodeConsts'
import { getBundlePriceData } from '../../services/PromoCodeData'
import { skipPricingPopup } from '../../pages/DiscoveryPage/PricingPopup/pricingPopupService'
import { analytics } from '../../services/Analytics/Analytics'
import { logger } from '../../services/logger'
import { triggerApi } from '../../api/apiProvider'
import { saveOrderDraftApi } from '../../api/orderDraft.api'
import { productTypeState } from '../../services/ProductTypeState'
import delay from 'delay'

export const createPromoCodeSlice: StateCreator<
  Store,
  [['zustand/persist', unknown]],
  [],
  PromoCodeSlice
> = (set, get) => {
  let appContainerLoadedTs: number = 0
  let monitoring: any = null
  return {
    discountCoupon: null,
    setDiscountCoupon: (couponData: Coupon | null, persist: boolean = true) => {
      set(() => ({
        discountCoupon: couponData,
        appliedPromoCodeFromBanner: null,
      }))
      if (persist) {
        triggerApi(
          saveOrderDraftApi({
            discountCoupon: couponData?.code,
          })
        )
      }
    },
    editDiscountCoupon: (couponData: Coupon) => {
      const discountCoupons = get().allDiscountCoupons
      const couponIndex = discountCoupons.findIndex(
        (coupon) => coupon.code === couponData.code
      )
      discountCoupons[couponIndex] = couponData
      set(() => ({ allDiscountCoupons: discountCoupons }))
    },
    addDiscountCoupon: (couponData: Coupon, persist = true) => {
      set(() => ({
        allDiscountCoupons: [...get().allDiscountCoupons, couponData],
      }))
      if (
        isValidForProductType(couponData, productTypeState.getProductType())
      ) {
        get().setDiscountCoupon(couponData, persist)
      }
      skipPricingPopup()
    },
    hasDiscountCoupon: () =>
      !!get().discountCoupon &&
      isValidCoupon(getGivenDiscountCouponType(get().discountCoupon) || ''),
    hasDiscountCouponWithCode: (code: string) => {
      return get().hasDiscountCoupon() && get().discountCoupon?.code === code
    },
    clearDiscountCoupon: () => set(() => ({ discountCoupon: null })),
    isDiscountCouponRegistered: (code: string) =>
      get().allDiscountCoupons.findIndex((coupon) => coupon.code === code) !==
      -1,
    allDiscountCoupons: [],
    removeDiscountCouponFromList: (couponCode: string) => {
      const filteredCouponList = get().allDiscountCoupons.filter(
        (coupon) => coupon.code !== couponCode
      )
      set(() => ({ allDiscountCoupons: filteredCouponList }))
    },
    removeDiscountCoupon: (track = true) => {
      const discountCouponCode = get().discountCoupon?.code
      if (track) {
        // Only track user-invoked removal
        analytics.track('Promo Code Removed', { Code: discountCouponCode })
      }

      get().removeDiscountCouponFromList(discountCouponCode || '')
      get().setDiscountCoupon(null)
    },
    getDiscountCouponDisplayName: () => {
      const coupon = get().discountCoupon || {
        pseudonym: undefined,
        code: undefined,
      }
      return coupon.pseudonym || coupon.code
    },
    removeAllExpiredDiscountCoupons: () => {
      const filteredCouponList = get().allDiscountCoupons.filter(
        (coupon) =>
          !coupon.expirationDate ||
          new Date() <= new Date(coupon.expirationDate)
      )
      const activeCoupon = get().discountCoupon?.code

      if (
        activeCoupon &&
        !filteredCouponList.find((coupon) => coupon.code === activeCoupon)
      ) {
        get().setDiscountCoupon(null)
      }
      set(() => ({ allDiscountCoupons: filteredCouponList }))
    },
    removeDiscountCouponIfExpired: () => {
      // Returns true if coupon was removed, false if not
      const discountCoupon = get().discountCoupon
      if (!discountCoupon) {
        return false
      }

      const { expirationDate } = discountCoupon
      if (expirationDate && new Date() > new Date(expirationDate)) {
        logger.info('Removing expired coupon', {
          code: get().getDiscountCouponDisplayName(),
          expiryDate: expirationDate,
        })
        get().removeDiscountCoupon()
        return true
      }
      return false
    },
    revalidateDiscountCoupon(forceCouponValidation = false) {
      if (!get().discountCoupon) {
        return null
      }

      if (get().removeDiscountCouponIfExpired()) {
        return null
      }

      // Check for backoff time limit
      if (
        new Date().getTime() -
          (get().lastCouponValidationTimestamp?.getTime() || 0) >
          COUPON_REVALIDATION_BACKOFF_MINUTES * 60 * 1000 ||
        forceCouponValidation
      ) {
        // Validate coupon
        return callValidateCoupon(get().discountCoupon?.code || '').then(
          (couponData) => {
            set(() => ({ lastCouponValidationTimestamp: new Date() }))
            if (couponData && !couponData.valid) {
              logger.info('Removing invalid coupon', {
                code: get().discountCoupon?.code,
                reason: couponData.errorCode,
              })
              get().removeDiscountCoupon()
            }
          }
        )
      }
    },
    lastCouponValidationTimestamp: null,
    startMonitoringTime: () => {
      if (!monitoring) {
        appContainerLoadedTs = new Date().getTime()
        monitoring = {}
      }
    },
    monitorEventTime: (eventName: string, extraParams: any) => {
      if (appContainerLoadedTs && monitoring && !monitoring[eventName]) {
        monitoring = {
          ...monitoring,
          ...extraParams,
          [eventName]: new Date().getTime() - appContainerLoadedTs,
        }
      }
    },
    // For use in a useEffect in promoCodeDialogContainer that will execute loadCoupon
    couponData: null,
    setCouponData: (couponData: Coupon | null) => {
      set(() => ({ couponData }))
    },

    appliedPromoCodeFromBanner: null,
    setAppliedPromoCodeFromBanner: (promoCode: string | null) => {
      set(() => ({ appliedPromoCodeFromBanner: promoCode }))
    },

    // Specific getters
    getDiscountCouponType: (coupon?: Coupon | null) =>
      getGivenDiscountCouponType(coupon || get().discountCoupon),
    getMetadata: (requestedCouponType: string) => {
      const couponType = getGivenDiscountCouponType(get().discountCoupon)
      return couponType === requestedCouponType
        ? get().discountCoupon?.metadata
        : null
    },
    getBundlePriceData: (tileCount: number) => {
      const couponType = getGivenDiscountCouponType(get().discountCoupon)
      const metadata =
        couponType === DISCOUNT_TYPES.BUNDLE
          ? get().discountCoupon?.metadata
          : null
      return getBundlePriceData(tileCount, metadata)
    },

    getPromoCodeAnalyticsData: () => {
      const data: Record<string, any> = {
        'Promo Code': get().getDiscountCouponDisplayName(),
        'Promo Code Category': get().discountCoupon?.category,
        'Promo Code Type': getGivenDiscountCouponType(get().discountCoupon),
      }
      if (get().discountCoupon?.discount?.type === DISCOUNT_TYPES.PERCENT) {
        data['Percent Off'] = get().discountCoupon?.discount?.percent_off || 0
      }
      return data
    },

    // Generic addition
    addPromoCode: async (
      code,
      source = PROMO_CODE_SOURCE.CHECKOUT,
      persist = true,
      pseudonym = undefined
    ) => {
      try {
        if (get().isTileCreditsCouponRegistered(code)) {
          return handleAlreadyRegisteredError(code)
        }
        const couponData = await callValidateCoupon(code.toUpperCase())
        get().monitorEventTime('Server validation returned')

        if (!couponData.valid) {
          return get().handleInvalidCoupon(couponData, source, code)
        }
        return get().handleValidCoupon(
          couponData,
          code,
          source,
          persist,
          pseudonym
        )
      } catch (error) {
        logger.error('Coupon code server error', error)
        return { error: 'SERVER_ERROR' }
      }
    },
    autoLoadOfGivenCoupon: async (
      code: string,
      title: string,
      source: string,
      pseudonym?: string
    ) => {
      if (!code) {
        return
      }
      get()?.removeAllExpiredDiscountCoupons()
      const commonAnalyticsEventProperties = { code, ...(source && { source }) }
      const credit = get().getCreditsFromCoupon(code)
      const existingCreditResult = handleExistingCredit(
        credit,
        title,
        commonAnalyticsEventProperties
      )
      if (existingCreditResult) return existingCreditResult
      get().monitorEventTime(
        'About to call server validation',
        commonAnalyticsEventProperties
      )
      let result = await get().addPromoCode(code, source, true, pseudonym)
      if (result && 'error' in result) {
        // try again
        await delay(5000)
        result = await get().addPromoCode(code, source, true, pseudonym)
      }
      return handlePromoCodeLoadResult(
        result,
        commonAnalyticsEventProperties,
        code,
        title,
        source
      )
    },
  }
}
