import { FRAME_COLORS, MATERIAL_TYPES } from '@mixtiles/web-backend-shared'
import { getTileStyleData } from './tilesStyling'
import { isFramedMaterial } from '../utils/materialTypeUtils'
import uniq from 'lodash/uniq'
import flatten from 'lodash/flatten'
import { type Tile } from '../components/TilesProvider/TilesProvider.types'
import { type TFunction } from 'i18next'
import { materialsAsColorManager } from './MaterialsAsColorManager/MaterialsAsColorManager'

export function getTileMaterialType(tile: Tile): MATERIAL_TYPES {
  if (!tile) {
    return MATERIAL_TYPES.CLASSIC
  } else if (isFramelessTile(tile)) {
    return MATERIAL_TYPES.FRAMELESS
  }
  return tile.materialType || MATERIAL_TYPES.CLASSIC
}

export function getTilesMaterialType(tile: Tile): MATERIAL_TYPES[] {
  if (!tile) {
    return [MATERIAL_TYPES.CLASSIC]
  } else if (isFramelessTile(tile) && !materialsAsColorManager.isEnabled) {
    return [MATERIAL_TYPES.FRAMELESS]
  }
  if (materialsAsColorManager.isEnabled) {
    return materialsAsColorManager.availableMaterialsList
  }
  if (
    [MATERIAL_TYPES.CLASSIC, MATERIAL_TYPES.WIDE_FRAME].includes(
      tile.materialType
    )
  ) {
    return [MATERIAL_TYPES.CLASSIC, MATERIAL_TYPES.WIDE_FRAME]
  }
  return tile.materialType ? [tile.materialType] : [MATERIAL_TYPES.CLASSIC]
}

export function isFramedTile(tile: Tile): boolean {
  return isFramedMaterial(getTileMaterialType(tile))
}

function isFramelessTile(tile: Tile): boolean {
  const { frameColor } = getTileStyleData(tile)
  return (
    [MATERIAL_TYPES.CLASSIC, MATERIAL_TYPES.FRAMELESS].includes(
      tile.materialType
    ) && frameColor === FRAME_COLORS.NONE
  )
}

export const getMaterialToName = (t: TFunction) => ({
  [MATERIAL_TYPES.CLASSIC]: t('order.materials.classic'),
  [MATERIAL_TYPES.CANVAS]: t('order.materials.canvas'),
  [MATERIAL_TYPES.FRAMELESS]: t('order.materials.frameless'),
  [MATERIAL_TYPES.STICKER]: t('order.materials.sticker'),
  [MATERIAL_TYPES.WIDE_FRAME]: t('order.materials.wide_frame'),
})

export function getTilesMaterialsWithExtra(tiles: Tile[]): MATERIAL_TYPES[] {
  const materialTypes = tiles.map((tile) => getTilesMaterialType(tile))
  return uniq(flatten(materialTypes))
}

export function getTilesMaterials(tiles: Tile[]): MATERIAL_TYPES[] {
  return uniq(tiles.map((tile) => getTileMaterialType(tile)))
}
