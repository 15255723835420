import { createGlobalStyle } from 'styled-components'

// All of our pages have "height:100%" on the root.
// No reason technically for that in any content page, only designer pages.
// To mitigate risk with this kind of change, only disabling it for home/content pages
// This is a temporary fix until we have a better solution for the root height issue
const RootHeightAuto = createGlobalStyle`
  html,
  body,
  #root {
    min-height: 100%;
    height: auto !important;
  }
`

export default RootHeightAuto
