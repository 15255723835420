import { translateManager as t } from '../../services/TranslateManager'
import { giftNoteManager } from '../../services/GiftNoteManager'
import { promoCodeManager } from '../../services/PromoCodeManager'
import { PROMO_CODE_SOURCE } from '../../stores/promoCodeSlice/promoCodeConsts'
import { saveOrderDraftApi } from '../../api/orderDraft.api'
import { getTiles, saveTiles } from '../../pages/PhotoStyler/tilesSaver'
import { SNACKBAR_VARIANTS } from '../../elements/Snackbar/Snackbar.consts'
import { ORDER_DRAFT_TILES_STORAGE_KEY } from '../TilesProvider/TilesProvider.consts'
import { isExpiredPhoto } from '../PhotoBank/photoUtils'
import { logger } from '../../services/logger'
import { analytics } from '../../services/Analytics/Analytics'

export default async function syncOrder(api, currentAccount, enqueueSnackbar) {
  const tiles = getTiles()
  const giftNote = giftNoteManager.getGiftNote()
  const discountCoupon = promoCodeManager.getDiscountCouponDisplayName()
  const { orderDraft } = currentAccount

  const hasRemoteTiles = orderDraft?.tiles?.length
  const hasRemoteGiftNote = orderDraft?.giftNote
  const hasRemoteDiscountCoupon = !!orderDraft?.discountCoupon
  let shouldSave =
    (tiles.length && !hasRemoteTiles) ||
    (giftNote && !hasRemoteGiftNote) ||
    (discountCoupon && !hasRemoteDiscountCoupon)

  if (orderDraft) {
    const restoredTiles = getRestoredTiles(
      tiles,
      orderDraft?.tiles,
      enqueueSnackbar,
      'Account Sync'
    )
    if (restoredTiles.length > 0) {
      saveTiles(restoredTiles, ORDER_DRAFT_TILES_STORAGE_KEY)
      shouldSave = true
    }

    if (orderDraft.giftNote && !giftNote) {
      giftNoteManager.setGiftNote(orderDraft.giftNote)
    } else if (
      orderDraft.giftNote &&
      giftNote &&
      orderDraft.giftNote !== giftNote
    ) {
      shouldSave = true
    }

    if (hasRemoteDiscountCoupon && !discountCoupon) {
      await promoCodeManager.addPromoCode({
        code: orderDraft.discountCoupon,
        source: PROMO_CODE_SOURCE.ACCOUNT,
        persist: false,
      })
    } else if (
      hasRemoteDiscountCoupon &&
      discountCoupon &&
      discountCoupon !== orderDraft.discountCoupon
    ) {
      shouldSave = true
    }
  }

  if (shouldSave) {
    await api
      .call(
        saveOrderDraftApi({
          tiles: getTiles(),
          giftNote: giftNoteManager.getGiftNote(),
          discountCoupon: promoCodeManager.getDiscountCouponCode(),
        })
      )
      .catch((e) => logger.error('Save order draft failed during sync', e))
  }
}

export const getRestoredTiles = (
  tiles,
  orderDraftTiles,
  enqueueSnackbar,
  source
) => {
  const existingTileIds = tiles.map((t) => t.id)
  const restoredTiles =
    orderDraftTiles?.filter((t) => !existingTileIds.includes(t.id)) || []

  if (restoredTiles.length) {
    // Filter out old tiles that are no longer accessible
    const validRestoredTiles = restoredTiles.filter((t) => !isExpiredPhoto(t))
    if (validRestoredTiles.length) {
      if (tiles.length) {
        const restoredText =
          validRestoredTiles.length === 1
            ? t.get('login.restored_photo')
            : t.get('login.restored_photos', { v1: validRestoredTiles.length })
        setTimeout(
          () =>
            enqueueSnackbar('', {
              variant: SNACKBAR_VARIANTS.COMMON,
              title: restoredText,
              name: 'Sync Order',
            }),
          2000
        )

        analytics.track('Restored photos', {
          photosNum: validRestoredTiles.length,
          Source: source,
        })
      }
    }

    return validRestoredTiles.map((t) => ({
      ...t,
      cropParams: t.cropParams || {},
      remote: true,
    }))
  }

  return []
}
