// Get the productType for the pricing API calls (for the pricing-items and discounts).
// For example for gift card productType, we want the classic pricing-items and
// discounts - because the gift card applied on the classic product
import { PRODUCT_TYPES } from '../../services/ProductTypeState'
import { sendPricingErrorAnalytics } from '../../services/Analytics/pricingAnalytics'
import { logger } from '../../services/logger'
import {
  NEW_PRICING_SUPPORTED_PRODUCT_TYPES,
  PRICING_ERROR_CONTEXT,
} from '../../components/Pricing/PricingUtil'
import { type PricingItem } from '../../components/Pricing/types/pricingItem.types'
import { hashTilePricingProduct } from '@mixtiles/web-backend-shared'

export const getPricingProductType = (productType: PRODUCT_TYPES) => {
  if (productType === PRODUCT_TYPES.GIFT_CARD) {
    return PRODUCT_TYPES.CLASSIC
  } else {
    return productType
  }
}
export const reportPricingError = (
  message: string,
  error: any,
  pricingCountry: string,
  pricingVariantsIds: any,
  productType: PRODUCT_TYPES
) => {
  sendPricingErrorAnalytics(error)
  logger.error(message, error, {
    tags: { context: PRICING_ERROR_CONTEXT },
    pricingCountry,
    pricingVariantsIds,
    productType,
  })
}

export const shouldUseAPI = (productType: PRODUCT_TYPES) =>
  NEW_PRICING_SUPPORTED_PRODUCT_TYPES.includes(productType)

export function formatPricingItems(
  pricingItems: PricingItem[]
): Record<string, PricingItem> {
  return Object.fromEntries(
    pricingItems.map((item) => [
      hashTilePricingProduct(item.pricingProduct),
      {
        ...item,
      },
    ])
  )
}
