import { styled } from 'styled-components'

export const StyledBox = styled.div`
  border-bottom: ${({ theme }) => theme.colors.grayMedium} 1px solid;
  padding-bottom: 28px;
`

export const Content = styled.div`
  margin-top: 1px;
  margin-left: ${({ $isArt }) => ($isArt ? '12px' : '8px')};
`

export const Text = styled.div`
  font-size: 14px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.primary};
  margin-left: 21px;
`
