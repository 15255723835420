import StorageManager from './StorageManager'
import { Subject, BehaviorSubject } from 'rxjs'
import { analytics, EVENT_NAMES } from './Analytics/Analytics'
import delay from 'delay'
import { CURRENCY_CONVERSIONS } from '../config/currency-conversion'
import { getCurrency, getPricing } from './PricingUtils'
import { getUrlParam } from '../utils/UrlUtils'
import { getBuyXgetYCredit, getBundlePriceData } from './PromoCodeData'
import { triggerApi } from '../api/apiProvider'
import { saveOrderDraftApi } from '../api/orderDraft.api'
import { logger } from './logger'
import { CouponErrorCodes, TILE_SIZES } from '@mixtiles/web-backend-shared'
import { getStoredPromoCode, removeStoredPromoCode } from './promoCodeStore'
import { currencyState } from './CurrencyState'
import { skipPricingPopup } from '../pages/DiscoveryPage/PricingPopup/pricingPopupService'
import { ServerSideAnalyticsPlatform } from './Analytics/ServerSideAnalytics'
import { mixtilesAxios } from '../utils/ApiUtils'
import { PRODUCT_TYPES, productTypeState } from './ProductTypeState'
import { isClient } from 'utils/runtimeUtils'
import {
  DISCOUNT_COUPON_KEY,
  GIFT_CARD_CODES_KEY,
  ALL_DISCOUNT_COUPON_KEY,
  TILES_CREDITS_KEY,
  SIZES_BUNDLE_CODE,
  DISCOUNT_TYPES,
  CLIENT_PROMO_CODE_ERRORS_TYPES,
  COUPON_REVALIDATION_BACKOFF_MINUTES,
  PROMO_CODE_SOURCE,
} from '../stores/promoCodeSlice/promoCodeConsts'
import {
  getGivenDiscountCouponType,
  isValidForProductType,
  isValidCoupon,
} from '../stores/promoCodeSlice/promoCodeUtils'

class PromoCodeManager {
  constructor() {
    this.promoCodeSubject = new Subject()
    this.couponAutoLoadSubject = new BehaviorSubject()
    this.lastCouponValidationTimestamp = null
    this.tilesCredits = isClient()
      ? StorageManager.get(TILES_CREDITS_KEY)
      : null
  }

  async addPromoCode({
    code,
    source = PROMO_CODE_SOURCE.CHECKOUT,
    persist = true,
    pseudonym = undefined,
  }) {
    try {
      if (this.isTileCreditsCouponRegistered(code)) {
        analytics.track(EVENT_NAMES.PROMO_CODE_ALREADY_REGISTERED, {
          Code: code,
        })
        return { error: CLIENT_PROMO_CODE_ERRORS_TYPES.ALREADY_REGISTERED }
      }
      const couponData = await this._callValidateCoupon(code.toUpperCase())
      couponData.pseudonym = pseudonym
      this.monitorEventTime('Server validation returned')
      if (couponData.valid) {
        // If a user manually enters a promo code, we want to remove any auto-loaded promo codes
        removeStoredPromoCode()
        const discountType = couponData.metadata.discountType

        let type = couponData.discount.type
        if (type === 'AMOUNT' && discountType) {
          type = discountType
        }

        if (this.isDiscountCouponRegistered(code)) {
          if (!pseudonym) {
            analytics.track(EVENT_NAMES.PROMO_CODE_ALREADY_REGISTERED, {
              Code: code,
            })
            return { error: CLIENT_PROMO_CODE_ERRORS_TYPES.ALREADY_REGISTERED }
          } else {
            this.removeDiscountCouponFromList(code)
          }
        }
        analytics.track(
          EVENT_NAMES.PROMO_CODE_ADDED,
          {
            Code: code,
            Type: type,
            Source: source,
            Metadata: couponData.metadata,
          },
          {
            serverSideAnalyticsPlatforms: [ServerSideAnalyticsPlatform.Klaviyo],
          }
        )
        analytics.setUserProperties({ 'Used Promo Code': true })
        if (couponData.discount.type === DISCOUNT_TYPES.UNIT) {
          await this.fetchTilesCredits()
          return {
            discountType: couponData.discount.type,
            amount: couponData.discount.unit_off,
            referringUser: couponData.referringUser,
            metadata: couponData.metadata,
            code: couponData.code,
          }
        } else if (couponData.discount.type === DISCOUNT_TYPES.GIFT_CARD) {
          this.addGiftCardCreditsCode(couponData.code)
          skipPricingPopup()
          analytics.setUserProperties({ 'Used Gift Card': true })
          this.promoCodeSubject.next({ giftCard: true })
          return {
            discountType: couponData.discount.type,
            metadata: couponData.metadata,
            code: couponData.code,
          }
        } else if (couponData.discount.type === DISCOUNT_TYPES.PERCENT) {
          const percentOff = couponData.discount.percent_off
          if (percentOff >= this.getDiscountCouponPercent()) {
            // Only set new coupon if it has a higher or equal discount
            this.addDiscountCoupon(couponData, persist)
          } else {
            return {
              discountType: couponData.discount.type,
              amount: this.getDiscountCouponPercent(),
              code: couponData.code,
            }
          }

          return {
            discountType: couponData.discount.type,
            amount: percentOff,
            code: couponData.code,
          }
        } else {
          const discountType = couponData.metadata.discountType
          switch (discountType) {
            case DISCOUNT_TYPES.MIX:
            case DISCOUNT_TYPES.BUNDLE:
            case DISCOUNT_TYPES.BUYXGETY:
            case DISCOUNT_TYPES.BUYOVER:
            case DISCOUNT_TYPES.EARN_CASH_OVER_AMOUNT:
              if (discountType === DISCOUNT_TYPES.BUNDLE) {
                couponData.discount.amount_off = 0

                const sizesBundle = !couponData.metadata.tileSizesList.includes(
                  TILE_SIZES.SQUARE_8X8
                )

                if (
                  sizesBundle &&
                  productTypeState.getProductType() !== PRODUCT_TYPES.CLASSIC
                ) {
                  analytics.track(
                    'Sizes Promo Code Attempted Without Sizes Experiment',
                    { Code: code, Error: 'UNKNOWN_ERROR' }
                  )
                  return { error: 'NON_EXISTING' } // Sometimes server returns no errorCode
                }
              }
              this.addDiscountCoupon(couponData, persist)
              return {
                discountType,
                metadata: couponData.metadata,
                code: couponData.code,
              }
            case DISCOUNT_TYPES.GET_AMOUNT_OVER_AMOUNT: {
              const clientCurrency = getCurrency()
              const formattedAmountOff = Math.ceil(
                (couponData.discount.amount_off / 100) *
                  CURRENCY_CONVERSIONS[clientCurrency]
              )
              const formattedMinOrderAmount = Math.ceil(
                couponData.metadata.minOrderAmount *
                  CURRENCY_CONVERSIONS[clientCurrency]
              )
              couponData.discount.amount_off = formattedAmountOff
              couponData.metadata.minOrderAmount = formattedMinOrderAmount
              // TODO: in order to keep the voucher structure unified, im not overwrite the coupon discount (couponData.discount.type still 'AMOUNT' , and also not remove the new discount type from metadata.) we'll should consider to do that someday.
              this.addDiscountCoupon(couponData, persist)
              return {
                discountType,
                amount: formattedAmountOff,
                metadata: couponData.metadata,
                code: couponData.code,
              }
            }
            default:
              logger.error('Unsupported discount type', null, { discountType })
              return {}
          }
        }
      } else if (
        couponData.errorCode === CouponErrorCodes.ALREADY_LOADED_COUPON
      ) {
        // should happen only in units coupon.
        return {
          discountType: couponData.discount.type,
          amount: couponData.discount.unit_off,
          code: couponData.code,
        }
      } else {
        analytics.track('Invalid Promo Code Attempted', {
          Code: code,
          Source: source,
          Error: couponData.errorCode || 'UNKNOWN_ERROR',
        })
        return { error: couponData.errorCode || 'UNKNOWN_ERROR' } // Sometimes server returns no errorCode
      }
    } catch (error) {
      logger.error('Coupon code server error', error)
      return { error: 'SERVER_ERROR' }
    }
  }

  isDiscountCouponRegistered(code) {
    const allCoupons = this.getAllDiscountCoupons()
    return allCoupons.findIndex((coupon) => coupon.code === code) !== -1
  }

  isTileCreditsCouponRegistered(code) {
    const allCoupons = this.getAllTileCredits()
    return allCoupons.findIndex((coupon) => coupon.sourceCoupon === code) !== -1
  }

  hasDiscountCoupon() {
    return (
      !!this.getDiscountCoupon() &&
      isValidCoupon(getGivenDiscountCouponType(this.getDiscountCoupon()))
    )
  }

  _callValidateCoupon(code) {
    const data = {
      coupon: code,
      requiresOldFreeTiles: true,
      targetCurrency: currencyState.getCurrency(),
    }
    const config = { headers: { supportsBuyxGetyCoupon: true } }
    return mixtilesAxios
      .post('v4/validateCoupon', data, config)
      .then((response) => response.data)
  }

  async fetchTilesCredits() {
    const userTileCredits = await mixtilesAxios
      .get('v1/tileCredits')
      .then((response) => response.data)
    this.setTilesCredits(userTileCredits)
  }

  setTilesCredits(tilesCredits) {
    this.tilesCredits = tilesCredits
    this.promoCodeSubject.next({ freeTiles: true })
    StorageManager.set(TILES_CREDITS_KEY, tilesCredits)
  }

  getTilesCreditsAmount() {
    return this.tilesCredits ? this.tilesCredits.total_amount : 0
  }

  getTilesCreditsToRedeem(freeTilesCount) {
    if (!this.tilesCredits || !freeTilesCount) {
      return []
    }

    const creditsToRedeem = []
    let freeTilesLeftToRedeem = freeTilesCount

    // eslint-disable-next-line no-unused-vars
    for (const tilesCredit of this.tilesCredits.credits) {
      freeTilesLeftToRedeem -= tilesCredit.amount
      if (freeTilesLeftToRedeem > 0) {
        creditsToRedeem.push({
          creditId: tilesCredit.credit_id,
          amount: tilesCredit.amount,
        })
      } else {
        creditsToRedeem.push({
          creditId: tilesCredit.credit_id,
          amount: tilesCredit.amount - Math.abs(freeTilesLeftToRedeem),
        })
        break
      }
    }

    return creditsToRedeem
  }

  getCreditsFromCoupon(coupon) {
    if (!this.tilesCredits) {
      return
    }

    // eslint-disable-next-line no-unused-vars
    for (const tilesCredit of this.tilesCredits.credits) {
      if (tilesCredit.sourceCoupon === coupon) {
        return tilesCredit
      }
    }
  }

  clearDiscountCoupon() {
    StorageManager.remove(DISCOUNT_COUPON_KEY)
  }

  getDiscountCoupon() {
    return StorageManager.get(DISCOUNT_COUPON_KEY)
  }

  getAllDiscountCoupons() {
    const coupons = StorageManager.get(ALL_DISCOUNT_COUPON_KEY)
    return coupons || []
  }

  getAllTileCredits() {
    const coupons = StorageManager.get(TILES_CREDITS_KEY)?.credits
    return coupons || []
  }

  getDiscountCouponType(coupon = null) {
    // On Voucherify there is only 3 types of discount coupons - UNIT, PERCENTS or AMOUNT
    // in order to to expend the discount types we add "discountType" field on voucher.metadata
    // this method purpose is the prevent other developer to count on voucher.type which is limited
    return getGivenDiscountCouponType(coupon || this.getDiscountCoupon())
  }

  getDiscountCouponDisplayName() {
    const coupon = this.getDiscountCoupon() || {}
    return coupon?.pseudonym || coupon?.code
  }

  getDiscountCouponCode() {
    return (this.getDiscountCoupon() || {}).code
  }

  getDiscountCouponCodeCategory() {
    return (this.getDiscountCoupon() || {}).category
  }

  getGiftCardCreditsCodes() {
    return StorageManager.get(GIFT_CARD_CODES_KEY) || []
  }

  addGiftCardCreditsCode(code) {
    const giftCardCodes = this.getGiftCardCreditsCodes()
    if (!giftCardCodes.includes(code)) {
      giftCardCodes.push(code)
      StorageManager.set(GIFT_CARD_CODES_KEY, giftCardCodes)
    }
  }

  removeGiftCardCreditsCode(code) {
    const giftCardCodes = this.getGiftCardCreditsCodes()
    const filteredCodes = giftCardCodes.filter((c) => c !== code)
    StorageManager.set(GIFT_CARD_CODES_KEY, filteredCodes)
  }

  getDiscountCouponPercent() {
    const discountCoupon = this.getDiscountCoupon()
    return discountCoupon &&
      discountCoupon?.discount?.type === DISCOUNT_TYPES.PERCENT
      ? discountCoupon.discount.percent_off
      : 0
  }

  getDiscountCouponLimit() {
    const discountCoupon = this.getDiscountCoupon()
    return discountCoupon &&
      discountCoupon.discount.type === DISCOUNT_TYPES.PERCENT &&
      discountCoupon.discount.amount_limit
      ? discountCoupon.discount.amount_limit / 100.0
      : null
  }

  getMetadata(requestedCouponType) {
    const couponType = this.getDiscountCouponType()
    return couponType === requestedCouponType
      ? this.getDiscountCoupon().metadata
      : null
  }

  addDiscountCoupon(couponData, persist = true) {
    const discountCoupons = this.getAllDiscountCoupons()
    discountCoupons.push(couponData)
    StorageManager.set(ALL_DISCOUNT_COUPON_KEY, discountCoupons)

    if (isValidForProductType(couponData, productTypeState.getProductType())) {
      this.setDiscountCoupon(couponData, persist)
    }
    skipPricingPopup()
  }

  editDiscountCoupon(couponData) {
    const discountCoupons = this.getAllDiscountCoupons()
    const couponIndex = discountCoupons.findIndex(
      (coupon) => coupon.code === couponData.code
    )
    discountCoupons[couponIndex] = couponData
    StorageManager.set(ALL_DISCOUNT_COUPON_KEY, discountCoupons)
  }

  setDiscountCoupon(couponData, persist = true) {
    StorageManager.set(DISCOUNT_COUPON_KEY, couponData)
    this.promoCodeSubject.next({ resetBannerPromoCodeUsageInSession: true })

    if (persist) {
      triggerApi(
        saveOrderDraftApi({
          discountCoupon: couponData ? couponData.code : null,
        })
      )
    }
  }

  getBuyXgetYCredit(tilesCount, ignoreBuyXGetYCoupon) {
    const metadata =
      this.getDiscountCouponType() === DISCOUNT_TYPES.BUYXGETY &&
      !ignoreBuyXGetYCoupon
        ? this.getMetadata(DISCOUNT_TYPES.BUYXGETY)
        : null
    return getBuyXgetYCredit(tilesCount, metadata)
  }

  getBundlePromoCodeMetaData() {
    const couponType = this.getDiscountCouponType()
    return couponType === DISCOUNT_TYPES.BUNDLE
      ? this.getDiscountCoupon().metadata
      : null
  }

  getBundlePriceData(tilesCount) {
    return getBundlePriceData(tilesCount, this.getBundlePromoCodeMetaData())
  }

  getGetAmountOverAmountData(orderAmount) {
    let usedAmount = 0
    let unusedAmount = 0
    let howMuchTillMin = null
    let amountOff = 0
    if (
      this.getDiscountCouponType() === DISCOUNT_TYPES.GET_AMOUNT_OVER_AMOUNT
    ) {
      const discountCoupon = this.getDiscountCoupon()
      amountOff = discountCoupon.discount.amount_off
      unusedAmount = discountCoupon.discount.amount_off
      const minOrderAmount = discountCoupon.metadata.minOrderAmount
      if (minOrderAmount <= orderAmount && unusedAmount < orderAmount) {
        usedAmount = unusedAmount
        unusedAmount = 0
        howMuchTillMin = { amount: 0, tiles: 0 }
      } else {
        howMuchTillMin = { amount: minOrderAmount - orderAmount }
        const tilePrice = getPricing().additionalTilePrice
        howMuchTillMin.tiles = Math.ceil(howMuchTillMin.amount / tilePrice)
      }
    }
    return {
      amountOff,
      usedAmount,
      unusedAmount,
      howMuchTillMin,
    }
  }

  removeDiscountCoupon(track = true) {
    const discountCouponCode = this.getDiscountCouponCode()
    if (track) {
      // Only track user-invoked removal
      analytics.track('Promo Code Removed', { Code: discountCouponCode })
    }

    this.removeDiscountCouponFromList(discountCouponCode)
    this.setDiscountCoupon(null)
  }

  removeDiscountCouponFromList(couponCode) {
    const allCoupons = this.getAllDiscountCoupons()
    const filteredCouponList = allCoupons.filter(
      (coupon) => coupon.code !== couponCode
    )
    StorageManager.set(ALL_DISCOUNT_COUPON_KEY, filteredCouponList)
  }

  removeDiscountCouponIfExpired() {
    // Returns true if coupon was removed, false if not
    if (!this.getDiscountCoupon()) {
      return false
    }

    const { expirationDate } = this.getDiscountCoupon()
    if (expirationDate && new Date() > new Date(expirationDate)) {
      logger.info('Removing expired coupon', {
        code: this.getDiscountCouponDisplayName(),
        expiryDate: expirationDate,
      })
      this.removeDiscountCoupon()
      return true
    }
  }

  removeAllExpiredDiscountCoupons() {
    const allCoupons = this.getAllDiscountCoupons()
    const filteredCouponList = allCoupons.filter(
      (coupon) =>
        !coupon.expirationDate || new Date() <= new Date(coupon.expirationDate)
    )
    const activeCoupon = this.getDiscountCouponCode()

    if (
      activeCoupon &&
      !filteredCouponList.find((coupon) => coupon.code === activeCoupon)
    ) {
      this.setDiscountCoupon(null)
    }

    StorageManager.set(ALL_DISCOUNT_COUPON_KEY, filteredCouponList)
  }

  revalidateGiftCardCodes() {
    const giftCardCodes = this.getGiftCardCreditsCodes()
    if (giftCardCodes.length > 0) {
      giftCardCodes.forEach((code) => {
        this._callValidateCoupon(code).then((couponData) => {
          if (couponData && !couponData.valid) {
            this.removeGiftCardCreditsCode(code)
          }
        })
      })
    }
  }

  revalidateDiscountCoupon(forceCouponValidation = false) {
    if (!this.getDiscountCoupon()) {
      return null
    }

    if (this.removeDiscountCouponIfExpired()) {
      return null
    }

    // Check for backoff time limit
    if (
      new Date() - this.lastCouponValidationTimestamp >
        COUPON_REVALIDATION_BACKOFF_MINUTES * 60 * 1000 ||
      forceCouponValidation
    ) {
      // Validate coupon
      return this._callValidateCoupon(this.getDiscountCouponCode()).then(
        (couponData) => {
          this.lastCouponValidationTimestamp = new Date()
          if (couponData && !couponData.valid) {
            logger.info('Removing invalid coupon', {
              code: this.getDiscountCouponCode(),
              reason: couponData.errorCode,
            })
            this.removeDiscountCoupon()
          }
        }
      )
    }
  }

  afterOrderCompleted() {
    this.fetchTilesCredits().catch((error) =>
      logger.error(
        'Failed to fetch tiles credits after order completed: ',
        error
      )
    )
    if (!this.hasDiscountCouponWithCode(SIZES_BUNDLE_CODE)) {
      // Product requirement: don't automatically remove this promo
      this.removeDiscountCoupon(false) // False = don't track in analytics
    }
  }

  async autoLoadOfGivenCoupon(code, title, source, pseudonym) {
    if (!code) {
      return Promise.resolve()
    }

    this.removeAllExpiredDiscountCoupons()

    const commonAnalyticsEventProperties = { code }
    if (source) commonAnalyticsEventProperties.source = source

    const credit = this.getCreditsFromCoupon(code)
    if (credit) {
      analytics.track('Coupon already loaded', commonAnalyticsEventProperties)
      return Promise.resolve({
        coupon: { discountType: DISCOUNT_TYPES.UNIT, amount: credit.amount },
        couponTitle: title,
      })
    }

    this.monitorEventTime(
      'About to call server validation',
      commonAnalyticsEventProperties
    )
    let result = await this.addPromoCode({ code, pseudonym })
    if (result.error) {
      // try again
      await delay(5000)
      result = await this.addPromoCode({ code, pseudonym })
    }

    if (result.error) {
      analytics.track('Promo Code Automatically Loading Failed', {
        ...commonAnalyticsEventProperties,
        error: result.error,
      })
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(
        `Failed to automatically add promo code: ${code}, error=${result.error}`
      )
    } else if (result.discountType) {
      analytics.track(
        'Promo Code Loaded Automatically',
        commonAnalyticsEventProperties
      )
      return { coupon: result, couponTitle: title, source }
    } else {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(
        'Received invalid promo code (discount type missing)'
      )
    }
  }

  async autoLoadOfCoupon() {
    const code = getUrlParam('promo') || getStoredPromoCode()
    const title = getUrlParam('ptitle')
    return this.autoLoadOfGivenCoupon(code, title, 'URL')
  }

  getPromoCodeAnalyticsData = () => {
    return {
      'Promo Code': this.getDiscountCouponDisplayName(),
      'Promo Code Category': this.getDiscountCouponCodeCategory(),
      'Promo Code Type': this.getDiscountCouponType(),
      'Percent Off': this.getDiscountCouponPercent(),
    }
  }

  startMonitoringTime = () => {
    if (!this._monitoring) {
      this._appContainerLoadedTs = +new Date()
      this._monitoring = {}
    }
  }

  monitorEventTime = (eventName, extraParams) => {
    if (this._monitoring && !this._monitoring[eventName]) {
      this._monitoring[eventName] = +new Date() - this._appContainerLoadedTs
      this._monitoring = { ...this._monitoring, ...extraParams }
    }
  }

  getTimeMonitorEvents = () => {
    return { ...this._monitoring }
  }

  hasDiscountCouponWithCode = (code) => {
    return this.hasDiscountCoupon() && this.getDiscountCoupon().code === code
  }
}

export const promoCodeManager = new PromoCodeManager()
