import React from 'react'
import { Spinner } from '../../index'
import {
  ButtonContainer,
  LinkContainer,
  Text,
  _SIZES as SIZES,
  _VARIANTS as VARIANTS,
} from './Button.styles'
import { type StringWithAutocomplete } from 'utils/typeHelpers'

export interface ButtonProps extends React.ComponentProps<'button'> {
  children: React.ReactNode
  variant?: StringWithAutocomplete<keyof typeof VARIANTS>
  size?: StringWithAutocomplete<keyof typeof SIZES>
  loading?: boolean
  disabled?: boolean
  minWidth?: number
  fullWidth?: boolean
  textColor?: string
  backgroundColor?: string
  testId?: string
  noShrink?: boolean
  link?: string
  icon?: React.ReactNode
}

function Button({
  children,
  variant = VARIANTS.primary,
  size = SIZES.lg,
  loading,
  disabled,
  minWidth,
  fullWidth,
  textColor,
  backgroundColor,
  testId,
  noShrink,
  link,
  icon,
  ...otherProps
}: ButtonProps) {
  const ContainerComponent: any = link ? LinkContainer : ButtonContainer
  const elementProps = link ? { href: link } : { disabled }

  return (
    <ContainerComponent
      $noShrink={noShrink}
      $variant={variant}
      $size={size}
      $minWidth={minWidth}
      $fullWidth={fullWidth}
      $color={textColor}
      $background={backgroundColor}
      data-testid={testId}
      {...elementProps}
      {...otherProps}
    >
      {icon}
      <Text>{loading ? <Spinner white /> : children}</Text>
    </ContainerComponent>
  )
}

export default Button
export { VARIANTS, SIZES }
