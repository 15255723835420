import { analytics, type SCREENS } from '../../services/Analytics/Analytics'
import { generateProductCountEventProperties } from '../../services/Analytics/productsAnalytics'

import { ServerSideAnalyticsPlatform } from '../../services/Analytics/ServerSideAnalytics'
import { useTilesSource } from '../../components/TilesProvider/TilesSourceContext'
import useOnMount from '../../hooks/useOnMount'
import {
  didMeasure,
  MEASUREMENT_LABELS,
  measureTime,
} from '../../services/uptimeMeasure'
import {
  LOADING_PROGRESS,
  markLoadingProgress,
} from '../../services/loadingProgress'
import { _EVENT_NAMES as EVENT_NAMES } from '../../services/Analytics/AnalyticsEvents'
import { useProductType } from '../../services/ProductTypesManager'
import { experimentManager } from '../../services/ExperimentManager/ExperimentManager'
import { ONBOARDING_URL } from '../../config/config'
import { emailCaptureManager } from '../../services/EmailCaptureManager'
import { navigationManager } from '../../services/NavigationManager'
import { useAuth } from '../../services/AuthProvider/AuthProvider'
import { useEmbeddedLogin } from '../../components/EmbeddedLogin/EmbeddedLoginProvider'
import { useLocation, useNavigate } from '@remix-run/react'
import { isClient } from 'utils/runtimeUtils'

type Props = {
  screenName: (typeof SCREENS)[keyof typeof SCREENS]
  eventProperties?: Record<string, unknown>
  ctaClickUrl: string | undefined
}

export default function useHomePage({
  screenName,
  eventProperties = {},
  ctaClickUrl,
}: Props): {
  onMainCtaClick: (withQuiz?: boolean) => void
} {
  const { tilesSource } = useTilesSource()
  const { productType } = useProductType()
  const { isAuthenticated, isLoading } = useAuth()
  const { openOnce } = useEmbeddedLogin()
  const navigate = useNavigate()
  const location = useLocation()

  const productCountProperties = isClient()
    ? generateProductCountEventProperties()
    : {}
  const analyticsProps = {
    'set type': productType,
    Flow: tilesSource,
    Type: screenName,
    Source: location?.state?.source || 'Direct',
    ...productCountProperties,
    ...eventProperties,
  }

  useOnMount(() => {
    analytics.trackScreenView(screenName)

    const shouldMeasure = !didMeasure()
    if (shouldMeasure) {
      markLoadingProgress(LOADING_PROGRESS.LOAD_FINISHED)
    }
    analytics.track(EVENT_NAMES.HOMEPAGE_RENDERED, {
      ...analyticsProps,
      Time: shouldMeasure
        ? measureTime(MEASUREMENT_LABELS.HOMEPAGE)
        : undefined,
    })
  })

  useOnMount(() => {
    document.addEventListener('scroll', trackScrolling)
    return () => window.removeEventListener('scroll', trackScrolling)
  })

  const trackScrolling = () => {
    const bottomOffset = 100
    const isReachedBottom =
      window.scrollY + window.innerHeight >
      document.body.scrollHeight - bottomOffset
    if (isReachedBottom) {
      analytics.trackOncePerSession(
        `${screenName} - Scrolled to Bottom`,
        analyticsProps
      )
    }
  }

  const onMainCtaClick = (withQuiz = true) => {
    if (
      !experimentManager.isEnabled('straight-to-photos') ||
      isAuthenticated ||
      (!isLoading && !openOnce({ source: 'cta', redirectTo: ctaClickUrl }))
    ) {
      analytics.trackFirstInteraction()
      analytics.track(EVENT_NAMES.HOMEPAGE_MAIN_CTA_TAPPED, analyticsProps, {
        serverSideAnalyticsPlatforms: [ServerSideAnalyticsPlatform.Tiktok],
      })

      if (!withQuiz || emailCaptureManager.passedAnyOnboarding()) {
        navigate(
          {
            pathname: ctaClickUrl,
          },
          {
            state: {
              source: location?.state?.source || screenName,
            },
          }
        )
      } else {
        navigationManager.setNextOnFinishOnboarding({
          pathname: ctaClickUrl,
        })
        navigate({
          pathname: ONBOARDING_URL,
          search: location.search,
        })
      }
    }
  }

  return {
    onMainCtaClick,
  }
}
