import { type LinksFunction } from '@remix-run/node'
import { Link, type MetaFunction } from '@remix-run/react'
import {
  PHOTO_STYLER_HOMEPAGE_URL,
  ONBOARDING_URL,
  PHOTO_STYLER_URL,
} from 'config/config'
import TilesHomePageEntryPoint from 'pages/HomePage/TilesHomePageEntryPoint'
import RootHeightAuto from 'styles/root-height-auto'
import { mergeMeta } from 'utils/remixUtils'
import { getOrigin } from 'utils/UrlUtils'
import { styled } from 'styled-components'

export const meta: MetaFunction = mergeMeta(() => {
  return [
    { title: 'Mixtiles - Turn your photos into affordable, stunning wall art' },
  ]
})
export const links: LinksFunction = () => {
  return [
    { rel: 'canonical', href: `${getOrigin()}${PHOTO_STYLER_HOMEPAGE_URL}` },
  ]
}

// Link that is hidden just to have remix prefetch the page
const HiddenPrefetchLink = styled(Link)`
  display: none;
`

export default function IndexRoute() {
  return (
    <>
      <HiddenPrefetchLink to={ONBOARDING_URL} prefetch="render" />
      <HiddenPrefetchLink to={PHOTO_STYLER_URL} prefetch="render" />
      <RootHeightAuto />
      <TilesHomePageEntryPoint />
    </>
  )
}
