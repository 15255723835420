import {
  getDPI,
  getDPIThreshold,
  getTileSizeSurface,
  isArtPhoto,
  isCenterpiece,
  MATERIAL_TYPES,
  MATTING,
  TILE_SHAPES,
  TILE_SIZES,
  TILE_SIZES_ROTATION,
} from '@mixtiles/web-backend-shared'
import { shouldWarnLowQualityDefault } from '../../../components/PhotoBank/photoUtils'
import { getShapeFromSize } from '../../../services/ShapeManager'
import { type Photo } from '../../../components/PhotoBank/PhotoBank.types'
import { createCropWithFaces } from '../../../services/SmartCrop/smartCrop'
import { type Tile } from '../../../components/TilesProvider/TilesProvider.types'
import { getCropTargetAspectRatio } from '../../../utils/cropRatioUtils'
import { type TileWarning } from './tileWarning.consts'
import { experimentManager } from '../../../services/ExperimentManager/ExperimentManager'
import { getDefaultArtTileSize } from '../../../hooks/tileStyling/utils'
import { tileAspectRatio } from '../../../utils/aspectRatio'
import { getTileStyleData } from 'services/tilesStyling'
import { getPreviewCropRect } from '../../../utils/bleedUtils'
import {
  toRectXYWH,
  toRectTBLR,
  toAbsoluteRect,
  unionRects,
} from '../../../utils/rectUtils'

export function getDPIThresholdWrapper(tileSize: TILE_SIZES) {
  if (!experimentManager.isEnabled('dpi-threshold-parametric-2')) {
    return getDPIThreshold(tileSize)
  }
  const payload = experimentManager.getVariantPayload(
    'dpi-threshold-parametric-2'
  )
  const isValidTileSize =
    payload?.tileSize && Object.values(TILE_SIZES).includes(payload.tileSize)
  const isValidDpi = typeof payload?.dpi === 'number'
  if (isValidTileSize && isValidDpi) {
    const tileSizeSurface = getTileSizeSurface(tileSize)
    if (tileSizeSurface >= getTileSizeSurface(payload.tileSize)) {
      return payload.dpi
    }
  }

  return 0
}

export function shouldWarnTileLowQuality(tile: Tile) {
  const shouldBypassWarning =
    tile?.useLowQualityAnyway ||
    !tile?.width ||
    !tile?.height ||
    tile?.removing ||
    isArtPhoto(tile) ||
    isCenterpiece(tile)

  if (shouldBypassWarning) {
    return false
  }

  const DPIThreshold = getDPIThresholdWrapper(tile?.tileSize)
  if (DPIThreshold === 0 || !DPIThreshold) {
    return shouldWarnLowQualityDefault(tile)
  }

  const { widthDPI, heightDPI } = getDPI(tile, undefined) || {}
  return widthDPI! < DPIThreshold || heightDPI! < DPIThreshold
}

type SquareTile =
  | TILE_SIZES.SQUARE_4X4
  | TILE_SIZES.SQUARE_8X8
  | TILE_SIZES.SQUARE_12X12
  | TILE_SIZES.SQUARE_20X20
  | TILE_SIZES.SQUARE_30X30
  | TILE_SIZES.SQUARE_40X40
const squareTileSizeToPortrait: Record<SquareTile, TILE_SIZES> = {
  [TILE_SIZES.SQUARE_4X4]: TILE_SIZES.RECTANGLE_4X6,
  [TILE_SIZES.SQUARE_8X8]: TILE_SIZES.RECTANGLE_8X11,
  [TILE_SIZES.SQUARE_12X12]: TILE_SIZES.RECTANGLE_12X16,
  [TILE_SIZES.SQUARE_20X20]: TILE_SIZES.RECTANGLE_20X27,
  [TILE_SIZES.SQUARE_30X30]: TILE_SIZES.RECTANGLE_27X36,
  [TILE_SIZES.SQUARE_40X40]: TILE_SIZES.RECTANGLE_22X44,
}
const squareTileSizeToLandscape: Record<SquareTile, TILE_SIZES> = {
  [TILE_SIZES.SQUARE_4X4]: TILE_SIZES.RECTANGLE_6X4,
  [TILE_SIZES.SQUARE_8X8]: TILE_SIZES.RECTANGLE_11X8,
  [TILE_SIZES.SQUARE_12X12]: TILE_SIZES.RECTANGLE_16X12,
  [TILE_SIZES.SQUARE_20X20]: TILE_SIZES.RECTANGLE_27X20,
  [TILE_SIZES.SQUARE_30X30]: TILE_SIZES.RECTANGLE_36X27,
  [TILE_SIZES.SQUARE_40X40]: TILE_SIZES.RECTANGLE_44X22,
}

type Rect = {
  top: number
  bottom: number
  left: number
  right: number
}
type BooleanRect = {
  top: boolean
  bottom: boolean
  left: boolean
  right: boolean
}
type FaceCropResult = {
  isCroppedOut: BooleanRect
  faceRect: Rect
}

const BAD_CROP_THRESHOLD = -5

function getBooleanCropRect(faceRect: Rect, cropRect: Rect): BooleanRect {
  return {
    top: faceRect.top - cropRect.top < BAD_CROP_THRESHOLD,
    bottom: cropRect.bottom - faceRect.bottom < BAD_CROP_THRESHOLD,
    left: faceRect.left - cropRect.left < BAD_CROP_THRESHOLD,
    right: cropRect.right - faceRect.right < BAD_CROP_THRESHOLD,
  }
}
function isBooleanRectCroppedOut(rect: BooleanRect) {
  return rect.top || rect.bottom || rect.left || rect.right
}

function getTileFacesCropWarning(
  tile?: Tile,
  photo?: Photo
): TileWarning | null {
  if (!photo || !tile) {
    return null
  }

  const faces = photo.faces?.map((f) => toAbsoluteRect(f, photo))

  if (!faces || faces.length === 0) {
    return null
  }

  const cropParams = tile.cropParams[tile.currentAspectRatio]

  if (!cropParams) {
    return null
  }

  const cropParamsWithBleed = getPreviewCropRect({
    tileSize: tile.tileSize,
    materialType: tile.materialType,
    matting:
      tile.materialType === MATERIAL_TYPES.CANVAS
        ? MATTING.NONE
        : tile.customStyle.matting,
    absoluteCropRect: cropParams,
  })

  const cropRect = toRectTBLR(cropParamsWithBleed)

  const facesCropResult: FaceCropResult[] = faces.map((face) => {
    const faceRect = toRectTBLR(face)
    const isCroppedOut = getBooleanCropRect(faceRect, cropRect)
    return { isCroppedOut, faceRect }
  })
  const isCroppedOut = facesCropResult.reduce(
    (acc, curr) => {
      if (curr.isCroppedOut.top) acc.top = true
      if (curr.isCroppedOut.bottom) acc.bottom = true
      if (curr.isCroppedOut.right) acc.right = true
      if (curr.isCroppedOut.left) acc.left = true

      return acc
    },
    {
      top: false,
      bottom: false,
      right: false,
      left: false,
    }
  )

  const hasBadCrop = isBooleanRectCroppedOut(isCroppedOut)

  if (!hasBadCrop) {
    return null
  }
  const allFacesRect = toRectTBLR(
    unionRects(...facesCropResult.map(({ faceRect }) => toRectXYWH(faceRect)))
  )

  const cropFixingRect = createCropWithFaces({
    naturalHeight: photo.height,
    naturalWidth: photo.width,
    faces,
    targetAspectRatio: tile.currentAspectRatio,
  })

  const cropFixBooleanRect = getBooleanCropRect(
    allFacesRect,
    toRectTBLR(cropFixingRect)
  )
  if (!isBooleanRectCroppedOut(cropFixBooleanRect)) {
    return {
      type: 'crop',
      payload: cropFixingRect,
    }
  }

  const targetTileSize = getCropFixingTileSize(tile.tileSize, isCroppedOut)
  if (targetTileSize) {
    const { matting } = getTileStyleData(tile)
    const targetAspectRatio = getCropTargetAspectRatio({
      mattingType: matting,
      materialType: tile.materialType,
      tileSize: targetTileSize,
    })
    const cropFixingRect = createCropWithFaces({
      naturalHeight: photo.height,
      naturalWidth: photo.width,
      faces,
      targetAspectRatio,
    })
    const cropFixBooleanRect = getBooleanCropRect(
      allFacesRect,
      toRectTBLR(cropFixingRect)
    )

    if (isBooleanRectCroppedOut(cropFixBooleanRect)) {
      return null
    }

    return {
      type: 'change-size',
      payload: {
        size: targetTileSize,
        crop: { rect: cropFixingRect, targetAspectRatio },
      },
    }
  }

  return null
}

function getArtTileOrientationWarning(tile: Tile): TileWarning | null {
  const aspectRatio = tileAspectRatio(tile.tileSize, tile.materialType)
  return isArtPhoto(tile) && aspectRatio >= 1
    ? {
        type: 'art-tile-orientation',
        payload: {
          size: getDefaultArtTileSize(),
        },
      }
    : null
}

function getCropFixingTileSize(
  size: TILE_SIZES,
  isCroppedOut: BooleanRect
): TILE_SIZES | null {
  const shape = getShapeFromSize(size)
  if (
    shape === TILE_SHAPES.PORTRAIT &&
    (!isCroppedOut.top || !isCroppedOut.bottom)
  ) {
    return TILE_SIZES_ROTATION[size]
  } else if (
    shape === TILE_SHAPES.LANDSCAPE &&
    (!isCroppedOut.left || !isCroppedOut.right)
  ) {
    return TILE_SIZES_ROTATION[size]
  } else if (shape === TILE_SHAPES.SQUARE) {
    if (isCroppedOut.left || isCroppedOut.right) {
      return squareTileSizeToLandscape[size as SquareTile]
    } else if (isCroppedOut.top || isCroppedOut.bottom) {
      return squareTileSizeToPortrait[size as SquareTile]
    }
  }

  return null
}

export function getTileWarnings(tile: Tile, photo?: Photo): TileWarning[] {
  const warnings: TileWarning[] = []
  if (shouldWarnTileLowQuality(tile)) warnings.push({ type: 'lowres' })

  const facesWarning = getTileFacesCropWarning(tile, photo)
  if (facesWarning) {
    warnings.push(facesWarning)
  }

  const artTileOrientationWarning = getArtTileOrientationWarning(tile)
  if (artTileOrientationWarning) {
    warnings.push(artTileOrientationWarning)
  }

  return warnings
}
