import {
  Experiment,
  ExperimentClient,
  type ExperimentUser,
  type Variant,
} from '@amplitude/experiment-js-client'
import { isClient, isServer } from '../../utils/runtimeUtils'
import { experimentManager } from 'services/ExperimentManager/ExperimentManager'
import { useMemo } from 'react'
import {
  LANGUAGE_LOCALES,
  type LANGUAGES,
} from '../../locale/Translation.consts'
import { cc } from '../../locale/custom/countries'
import { type PricingItem } from '../../components/Pricing/types/pricingItem.types'
import { type MATERIAL_TYPES, TILE_SIZES } from '@mixtiles/web-backend-shared'
import { formatPricingItems } from '../../components/Pricing/PricingUtil'

export function getExperimentsUser(
  userId: string,
  language: string,
  country: string,
  utmParams: Record<string, string>
): ExperimentUser {
  return {
    user_id: userId,
    language: LANGUAGE_LOCALES[language as LANGUAGES],
    country: cc.en[country as keyof typeof cc.en],
    user_properties: {
      ...(utmParams ?? {}),
    },
  }
}

export function useInitExperimentClient({
  features,
  keys,
  userId,
  language,
  utmParams,
}: {
  features: Record<string, Variant>
  keys: Record<string, any>
  userId: string
  language: string
  utmParams: Record<string, string>
}) {
  const key = keys.amplitudeDeploymentKey
  let client: ExperimentClient = useMemo(() => {
    const userProvider = {
      getUser: () =>
        getExperimentsUser(userId, language, keys.ipCountry, utmParams),
    }
    if (isServer()) {
      return new ExperimentClient(key, {
        fetchTimeoutMillis: 1000,
        pollOnStart: false,
        initialVariants: features,
        userProvider,
      })
    }

    const client = Experiment.initializeWithAmplitudeAnalytics(key, {
      fetchTimeoutMillis: 1000,
      pollOnStart: false,
      initialVariants: features,
      userProvider,
    })
    // Calling start so the experiments will be saved in the local storage
    client.start(userProvider.getUser())
    return client
  }, [])

  if (isClient()) {
    experimentManager.updateClient(client)
  }

  return client
}

export function checkPriceConsistencyBetweenClassicAndMaterials(
  items: Record<string, PricingItem>,
  materialsToCheck: MATERIAL_TYPES[]
): boolean {
  items = formatPricingItems(items)
  // The idea here is to check if the price difference between the base price and the material prices
  // (from materialsToCheck) is consistent across all sizes. If that's the case,
  // we want to show the material as a color in the UI rather than as a material.
  const SIZES_TO_CHECK_PRICE_DIFF = [
    TILE_SIZES.SQUARE_8X8,
    TILE_SIZES.RECTANGLE_8X11,
    TILE_SIZES.RECTANGLE_11X8,
    TILE_SIZES.SQUARE_12X12,
    TILE_SIZES.RECTANGLE_12X16,
    TILE_SIZES.RECTANGLE_16X12,
    TILE_SIZES.SQUARE_20X20,
    TILE_SIZES.RECTANGLE_20X27,
    TILE_SIZES.RECTANGLE_27X20,
    TILE_SIZES.RECTANGLE_27X36,
    TILE_SIZES.RECTANGLE_36X27,
  ]
  let priceDifference: number | null = null

  for (const size of SIZES_TO_CHECK_PRICE_DIFF) {
    for (const material of materialsToCheck) {
      const materialTile = `${size}-${material}`

      if (items[size] && items[materialTile]) {
        const basePrice = items[size].price
        const materialPrice = items[materialTile].price
        const currentDifference = materialPrice - basePrice

        if (priceDifference === null) {
          // Set the first difference
          priceDifference = currentDifference
        } else if (priceDifference !== currentDifference) {
          // If any difference doesn't match, return false
          return false
        }
      }
    }
  }

  // If all differences matched, return true
  return true
}
