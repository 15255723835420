import type { Rect } from './cropUtils.types'

export function getBoundingRect(rects: Rect[], shouldRound = true) {
  const left = Math.min(...rects.map(({ x }) => x))
  const right = Math.max(...rects.map(({ x, width }) => x + (width || 0)))
  const top = Math.min(...rects.map(({ y }) => y))
  const bottom = Math.max(...rects.map(({ y, height }) => y + (height || 0)))
  return {
    x: shouldRound ? Math.round(left) : left,
    width: shouldRound ? Math.round(right - left) : right - left,
    y: shouldRound ? Math.round(top) : top,
    height: shouldRound ? Math.round(bottom - top) : bottom - top,
  }
}

export function coordsToRect(coords: number[][]) {
  return {
    x: coords[0][0],
    y: coords[0][1],
    width: coords[1][0] - coords[0][0],
    height: coords[2][1] - coords[1][1],
  }
}

export function unionRects(...rects: Rect[]) {
  const copiedRects = [...rects]
  let mergedRect = copiedRects.shift()!
  while (copiedRects.length) {
    const otherRect = copiedRects.shift()!

    const xMin = Math.min(mergedRect.x, otherRect.x)
    const yMin = Math.min(mergedRect.y, otherRect.y)
    const xMax = Math.max(
      mergedRect.x + mergedRect.width,
      otherRect.x + otherRect.width
    )
    const yMax = Math.max(
      mergedRect.y + mergedRect.height,
      otherRect.y + otherRect.height
    )

    mergedRect = {
      x: xMin,
      y: yMin,
      width: xMax - xMin,
      height: yMax - yMin,
    }
  }

  return mergedRect
}

export function padRect(
  rect: Rect,
  { top = 0, right = 0, bottom = 0, left = 0 },
  { width, height }: { width: number; height: number }
) {
  return {
    x: Math.max(rect.x - left, 0),
    y: Math.max(rect.y - top, 0),
    width: Math.min(rect.width + left + right, width),
    height: Math.min(rect.height + top + bottom, height),
  }
}

// The function returns whether testRect is fully inside the boundingRect or not
export function isRectInsideRect(boundingRect: Rect, testRect: Rect) {
  return (
    testRect.x >= boundingRect.x &&
    testRect.y >= boundingRect.y &&
    testRect.x + testRect.width <= boundingRect.x + boundingRect.width &&
    testRect.y + testRect.height <= boundingRect.y + boundingRect.height
  )
}

// converts a rect from XYWH (x, y, width, height) to TBLR (top, bottom, left, right)
export function toRectTBLR(rect: Rect) {
  return {
    top: rect.y,
    bottom: rect.y + rect.height,
    left: rect.x,
    right: rect.x + rect.width,
  }
}

type TBLRRect = {
  top: number
  bottom: number
  left: number
  right: number
}
// converts a rect from TBLR (top, bottom, left, right) to XYWH (x, y, width, height)
export function toRectXYWH(rect: TBLRRect): Rect {
  return {
    x: rect.left,
    y: rect.top,
    width: rect.right - rect.left,
    height: rect.bottom - rect.top,
  }
}

export function toRelativeRect<R extends Rect | TBLRRect>(
  rect: R,
  { width, height }: { width: number; height: number }
): R {
  if ('x' in rect && 'y' in rect) {
    return {
      x: rect.x / width,
      y: rect.y / height,
      width: rect.width / width,
      height: rect.height / height,
    } as R
  } else {
    return {
      left: rect.left / width,
      top: rect.top / height,
      right: rect.right / width,
      bottom: rect.bottom / height,
    } as R
  }
}

export function toAbsoluteRect<R extends Rect | TBLRRect>(
  rect: R,
  { width, height }: { width: number; height: number }
): R {
  if ('x' in rect && 'y' in rect) {
    return {
      ...rect,
      x: Math.round(rect.x * width),
      y: Math.round(rect.y * height),
      width: Math.round(rect.width * width),
      height: Math.round(rect.height * height),
    } as R
  } else {
    return {
      ...rect,
      left: Math.round(rect.left * width),
      top: Math.round(rect.top * height),
      right: Math.round(rect.right * width),
      bottom: Math.round(rect.bottom * height),
    } as R
  }
}
