import axios from 'axios'
import { Buffer } from 'buffer'

const imagesCache: Record<string, WeakRef<HTMLImageElement>> = {}

const addImageToCache = (url: string, image: HTMLImageElement) => {
  imagesCache[url] = new WeakRef(image)
}

export const removeImageFromCache = (url: string) => {
  delete imagesCache[url]
}

const getImageFromCache = (url: string) => {
  return imagesCache[url]?.deref()
}

export const loadImage = (
  url: string,
  crossOrigin: boolean = false,
  cache: boolean = false
): Promise<HTMLImageElement> => {
  const imageFromCache = getImageFromCache(url)
  if (cache && imageFromCache) {
    return new Promise((resolve) => resolve(imageFromCache))
  }

  const image: HTMLImageElement = new window.Image()
  const extension: string = url.split('.')?.pop() || 'png'
  image.src = url
  image.style.maxWidth = '100%'
  if (crossOrigin) {
    image.crossOrigin = 'anonymous'
  }

  return new Promise((resolve, reject) => {
    image.onload = () => {
      if (cache) {
        addImageToCache(url, image)
      }
      resolve(image)
    }

    image.onerror = () => {
      setTimeout(() => {
        axios
          .get(url, {
            responseType: 'arraybuffer',
          })
          .then((response) => {
            const uri: string = Buffer.from(response.data, 'binary').toString(
              'base64'
            )
            image.src = `data:image/${extension};base64, ${uri}`
            image.onload = () => {
              if (cache) {
                addImageToCache(url, image)
              }
              resolve(image)
            }
            image.onerror = () => {
              reject(new Error(`Failed to load image with dataUri`))
            }
          })
          .catch((err: Error) => {
            const errorMessage = err?.message || err
            reject(new Error(`Failed to fetch image err=${errorMessage}`))
          })
      }, 2000)
    }
  })
}

export function imageToBlob(image: HTMLImageElement): Promise<string> {
  return new Promise((resolve) => {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    canvas.width = image.width
    canvas.height = image.height
    ctx?.drawImage(image, 0, 0)
    canvas.toBlob((blob) => {
      if (blob) {
        const blobUrl = URL.createObjectURL(blob)
        resolve(blobUrl)
      }
    })
  })
}
