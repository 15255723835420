import {
  getPhysicalDimensions,
  type MATERIAL_TYPES,
  type TILE_SIZES,
} from '@mixtiles/web-backend-shared'

/**
 * Given a tileSize, returns the aspect ratio of its frame
 */
export function tileAspectRatio(
  tileSize: TILE_SIZES,
  materialType: MATERIAL_TYPES
): number {
  const { physicalWidth, physicalHeight } = getPhysicalDimensions({
    materialType,
    tileSize,
    isPlastic: false, // Set always to false because the aspect ratio is the same for plastic and MDF
  })
  return physicalWidth / physicalHeight
}
