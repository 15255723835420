/* eslint-disable jsx-a11y/media-has-caption */
import {
  type CSSProperties,
  type ComponentProps,
  type PropsWithChildren,
  forwardRef,
  useEffect,
  useId,
  useImperativeHandle,
  useRef,
  useState,
  type RefObject,
} from 'react'
import * as S from './HomePageVideo.styles'
import { usePlaybackData } from '../../../../components/PlaybackProvider/PlaybackContext'
import { analytics, EVENT_NAMES } from 'services/Analytics/Analytics'
import useLowPowerPlayback from './useLowPowerPlayback'
import { logger } from 'services/logger'
import { extractErrorMessage } from 'utils/ApiUtils'
import { getVideoPercentagePlayed } from '../../../../utils/videoUtils'
import { useOnUnmount } from '../../../../hooks/useOnMount'
import { useEvent } from 'react-use-event-hook'

// Defines an array 100 intersection stops from 0 to 1
const thresholds: number[] = []
for (let i = 0; i <= 1.0; i += 0.01) {
  thresholds.push(i)
}
if (thresholds[thresholds.length - 1] !== 1) {
  thresholds.push(1)
}

type Props = {
  cldId: string
  name: string
  type: 'Video' | 'Story'
  analyticProps?: Record<string, string>
  fit?: 'contain' | 'cover'
  position?: CSSProperties['objectPosition']
  fetchPriority?: 'high' | 'low' | 'auto'
  quality?: 'best' | 'good' | 'eco' | 'low'
  preload?: 'auto' | 'metadata' | 'none'
  cldWidth?: number
  onVideoEnd?: (videoElement: HTMLVideoElement | null) => void
  loop?: boolean
  shouldAutoPlay?: boolean
  folder?: string
  autoPlay?: boolean
} & ComponentProps<typeof S.Container>

export type VideoRefType = {
  containerRef: RefObject<HTMLDivElement>
  videoRef: RefObject<HTMLVideoElement>
}

const HomePageVideo = forwardRef<VideoRefType, PropsWithChildren<Props>>(
  (
    {
      cldId,
      children,
      name,
      type,
      fit,
      preload = 'metadata',
      fetchPriority = 'auto',
      quality = 'best',
      position,
      analyticProps = {},
      cldWidth = 650,
      onVideoEnd = () => {},
      loop = true,
      shouldAutoPlay = true,
      folder = 'homepage',
      autoPlay = false,
      ...props
    },
    ref
  ) => {
    /**
     * Please note - home pages videos are:
     * 1) Transformed only once using Cloudinary (Each video is transformed twice: to mp4 and jpg)
     * 2) Saved in AWS's S3 bucket:
     *    https://us-east-1.console.aws.amazon.com/s3/buckets/mixtiles-public-assets?region=us-east-1&bucketType=general&prefix=videos/
     *    in one of the folders (currently homepage / photo-books)
     * 3) Served from a AWS's CDN over that S3 bucket (Already configured, no need to do anything):
     *    https://us-east-1.console.aws.amazon.com/cloudfront/v4/home?region=us-east-1#/distributions/E1Y4ISAHJDU6DE
     *
     * Therefore, whenever a new transformation is needed (new video and/or different width and/or differnet quality),
     * please make sure to run the script transform-and-download-cloudinary-home-page-video.sh
     */

    const containerRef = useRef<HTMLDivElement>(null)
    const videoRef = useRef<HTMLVideoElement>(null)

    // Copies into forwarded ref and our local ref
    useImperativeHandle(
      ref,
      () => {
        return {
          containerRef,
          videoRef,
        }
      },
      []
    )

    const playbackId = useId()
    const playPromiseRef = useRef<Promise<void> | null>(null)
    const pausePromiseRef = useRef<Promise<void> | null>(null)
    const firstPlayTimeRef = useRef<number | null>(null)
    const firstPlayTrackedRef = useRef<boolean>(false)
    const [loadedFrame, setLoadedFrame] = useState(false)
    const [, setBuffering] = useState(false)
    const {
      currentPlayingId,
      muted,
      onIntersectionChange,
      onPlayingIdUnmount,
    } = usePlaybackData()
    const isPlaying = playbackId === currentPlayingId
    const [finishedFirstPlay, setFinishedFirstPlay] = useState(false)
    const cldIdNoSlashes = cldId.replaceAll('/', '--')

    const posterSrc = `https://d2pkj4iuzqk8im.cloudfront.net/videos/${folder}/c_scale,w_900--so_0--q_75--f_jpg--v1--${cldIdNoSlashes}.jpg`

    const attemptPlay = () => {
      const video = videoRef.current
      if (!video || playPromiseRef.current !== null || !shouldAutoPlay) return
      const playPromise = video.play()

      // Safari playback bug
      // Based on https://github.com/aws/amazon-chime-sdk-js/pull/2391/files#diff-5dbdb71553bcb184ab921202e679701b0a57cf378ea628d6bd23c44023e911f4R66
      if (playPromise) {
        if (firstPlayTimeRef.current === null) {
          analytics.track(EVENT_NAMES.HOMEPAGE_VIDEO_FIRST_ATTEMPT_PLAYED, {
            Name: name,
            Type: type,
            Folder: folder,
            ...analyticProps,
          })
          firstPlayTimeRef.current = performance.now()
        }

        playPromiseRef.current = playPromise
          .catch((error) => {
            logger.warning('Error playing homepage video', {
              error: extractErrorMessage(error),
              source: 'focused-video',
            })
          })
          // Needs a then block to prevent black screen in safari
          .then(() => {
            // Setting loaded frame to true in case it didn't fire onLoadedData
            // which can happen if data saver is on
            setLoadedFrame(true)
          })
          .finally(() => {
            playPromiseRef.current = null
          })
      }
    }

    // useEvent is required because without it the onPlayingIdUnmount doesn't have the updated value of currentPlayingId
    const resetVideoPlaybackState = useEvent(() => {
      onPlayingIdUnmount(playbackId)
    })

    useOnUnmount(() => {
      resetVideoPlaybackState()
    })

    useLowPowerPlayback({
      videoRef,
      shouldPlay: isPlaying,
      attemptPlay,
    })

    useEffect(() => {
      const container = containerRef.current
      if (!container) return

      const onIntersect = (entries: IntersectionObserverEntry[]) => {
        const [entry] = entries
        onIntersectionChange(
          playbackId,
          entry.isIntersecting,
          entry.intersectionRatio
        )
      }

      const observer = new IntersectionObserver(onIntersect, {
        threshold: thresholds,
      })
      observer.observe(container)

      return () => {
        observer.disconnect()
      }
    }, [playbackId])

    useEffect(() => {
      const video = videoRef.current
      if (!video) return

      if (isPlaying) {
        attemptPlay()
      } else {
        const pause = () => {
          if (pausePromiseRef.current !== null) {
            pausePromiseRef.current = null
          }

          video.pause()

          const stoppingTimeSeconds = video.currentTime
          const videoLength = video.duration
          const percentageWatched = getVideoPercentagePlayed(video)

          analytics.track(EVENT_NAMES.HOMEPAGE_VIDEO_PAUSED, {
            Name: name,
            Type: type,
            Folder: folder,
            'Stopping Time': stoppingTimeSeconds.toFixed(1),
            'Video Length': videoLength.toFixed(1),
            'Percentage Watched': percentageWatched.toFixed(0),
            ...analyticProps,
          })
        }

        if (playPromiseRef.current !== null) {
          if (pausePromiseRef.current === null) {
            pausePromiseRef.current = playPromiseRef.current.then(pause)
          }
        } else if (!video.paused) {
          pause()
        }
      }
      // didHydrate is in dependencies so we re-run whether we should play/pause on the hydrated video
    }, [isPlaying, shouldAutoPlay])

    function onEnded() {
      if (!finishedFirstPlay) {
        analytics.track(EVENT_NAMES.HOMEPAGE_VIDEO_COMPLETED, {
          Name: name,
          Type: type,
          Folder: folder,
          ...analyticProps,
        })
        setFinishedFirstPlay(true)
      }

      const video = videoRef.current
      onVideoEnd(video)
      if (loop) {
        const playPromise = video?.play()
        if (playPromise) {
          playPromiseRef.current = playPromise
            .catch((error) => {
              logger.warning('Error playing homepage video', {
                error: extractErrorMessage(error),
                source: 'on-ended',
              })
            })
            .then(() => {})
            .finally(() => {
              playPromiseRef.current = null
            })
        }
      }
    }

    useEffect(() => {
      // Reload video when cldId changes
      if (videoRef.current && isPlaying) {
        setLoadedFrame(false)
        videoRef.current.load()
        attemptPlay()
      }
    }, [cldId])

    return (
      <S.Container ref={containerRef} {...props}>
        <S.VideoHydrateRoot>
          <S.Video
            className="homepage-video"
            poster={posterSrc}
            ref={videoRef}
            muted={muted}
            autoPlay={autoPlay}
            playsInline
            onEnded={onEnded}
            preload={preload}
            fetchpriority={fetchPriority}
            style={{ objectFit: fit, objectPosition: position }}
            onPlaying={() => {
              setBuffering(false)

              // Make sure poster is not shown if video starts playing
              if (!loadedFrame) {
                setLoadedFrame(true)
              }

              if (!firstPlayTrackedRef.current) {
                firstPlayTrackedRef.current = true
                const startTime = firstPlayTimeRef.current

                const timeToFirstPlayMs =
                  startTime !== null
                    ? (performance.now() - startTime).toFixed(0)
                    : -1
                analytics.track(EVENT_NAMES.HOMEPAGE_VIDEO_FIRST_PLAYED, {
                  Name: name,
                  Type: type,
                  Folder: folder,
                  TimeMs: timeToFirstPlayMs,
                  ...analyticProps,
                })
              }
            }}
            onPause={() => {
              setBuffering(false)
            }}
            onWaiting={() => {
              setBuffering(true)
            }}
            onLoadedData={(e) => {
              if (e.currentTarget.readyState >= 3) {
                setLoadedFrame(true)
              }
            }}
          >
            <source
              src={`https://d2pkj4iuzqk8im.cloudfront.net/videos/${folder}/c_scale,w_${cldWidth}--q_auto:${quality}--vc_h264:high:auto--v1--${cldIdNoSlashes}.mp4`}
              type="video/mp4; codecs="
            />
          </S.Video>
        </S.VideoHydrateRoot>
        {!loadedFrame && (
          <S.Poster
            src={posterSrc}
            $fit={fit}
            $position={position}
            $autoPlay={autoPlay}
          />
        )}

        <S.ChildrenContainer>{children}</S.ChildrenContainer>
      </S.Container>
    )
  }
)

export default HomePageVideo
