import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  type ReactNode,
} from 'react'
import type { SettingsCookieData } from '../server/session/types'
import { getCookie, setCookie } from 'typescript-cookie'
import { logger } from './logger'
import { useEvent } from 'react-use-event-hook'
import useOnMount from 'hooks/useOnMount'
import { getRootLoaderData } from 'utils/remixClientUtils'
import { isClient } from 'utils/runtimeUtils'

const COOKIE_NAME = 'user_settings'

export type UserSettingsContextValue = {
  settings: SettingsCookieData
  updateSettings: (newSettings: Partial<SettingsCookieData>) => void
}

const tryParseCookieData = (
  cookieData: string | undefined
): SettingsCookieData => {
  if (!cookieData) return {}

  try {
    return JSON.parse(atob(cookieData))
  } catch (error) {
    logger.error('Error parsing settings cookie data, creating new', error)
    return {}
  }
}

const getClientCookieData = (): SettingsCookieData => {
  const cookieData = getCookie(COOKIE_NAME)
  return tryParseCookieData(cookieData)
}

const eventTarget = new EventTarget()
let cachedSettings: SettingsCookieData | undefined = isClient()
  ? getRootLoaderData()?.userSettings
  : undefined

export function getUserSettings(): SettingsCookieData {
  if (cachedSettings) return cachedSettings

  cachedSettings = getClientCookieData()
  return cachedSettings
}

function updateSettingsCookie(newSettings: SettingsCookieData) {
  cachedSettings = newSettings
  setCookie(COOKIE_NAME, btoa(JSON.stringify(newSettings)))
}

const SETTINGS_UPDATED_EVENT = 'settings-updated'

export function updateUserSettings(newSettings: Partial<SettingsCookieData>) {
  updateSettingsCookie({ ...cachedSettings, ...newSettings })
  eventTarget.dispatchEvent(new Event(SETTINGS_UPDATED_EVENT))
}

export const UserSettingsContext =
  createContext<UserSettingsContextValue | null>(null)

export function UserSettingsProvider({
  children,
  initialSettings,
}: {
  children: ReactNode
  initialSettings: SettingsCookieData
}) {
  const [settings, setSettings] = useState<SettingsCookieData>(initialSettings)

  useOnMount(() => {
    cachedSettings = initialSettings
  })

  const updateSettings = useEvent(
    (newSettings: Partial<SettingsCookieData>) => {
      setSettings((settings) => {
        const mergedSettings = { ...settings, ...newSettings }
        updateSettingsCookie(mergedSettings)
        return mergedSettings
      })
    }
  )

  useEffect(() => {
    const listener = () => {
      if (!cachedSettings) return
      setSettings(cachedSettings)
    }
    eventTarget.addEventListener(SETTINGS_UPDATED_EVENT, listener)

    return () => {
      eventTarget.removeEventListener(SETTINGS_UPDATED_EVENT, listener)
    }
  }, [])

  const value = useMemo(
    () => ({ settings, updateSettings }),
    [settings, updateSettings]
  )

  return (
    <UserSettingsContext.Provider value={value}>
      {children}
    </UserSettingsContext.Provider>
  )
}

export const useUserSettings = () => {
  const settings = useContext(UserSettingsContext)

  if (!settings) {
    throw new Error('Settings not found in UserSettingsProvider')
  }

  return settings
}
