/**
 * Takes an img element and a frame object, and creates a canvas
 * of that image projected into that frame
 */
export const projectImage = async (
  image: HTMLImageElement,
  frame: number[][]
) => {
  const imgW: number = image.naturalWidth
  const imgH: number = image.naturalHeight
  const before: number[] = [0, 0, imgW, 0, 0, imgH, imgW, imgH]
  let top: number
  let left: number
  let right: number
  let bottom: number
  top = 0
  left = 0
  bottom = imgH
  right = imgW

  const project: number[][] = [frame[0], frame[1], frame[3], frame[2]]

  project.forEach((p) => {
    top = Math.min(p[1], top)
    left = Math.min(p[0], left)
    bottom = Math.max(p[1], bottom)
    right = Math.max(p[0], right)
  })
  const texture: HTMLCanvasElement = document.createElement('canvas')
  const ctx: CanvasRenderingContext2D | null = texture.getContext('2d')
  texture.width = Math.ceil(right - left)
  texture.height = Math.ceil(bottom - top)
  ctx?.setTransform(1, 0, 0, 1, left, top) // put origin so image is at 0,0
  ctx?.drawImage(image, 0, 0)
  ctx?.setTransform(1, 0, 0, 1, 0, 0) // reset transform
  const after: number[] = []
  project.forEach((p) => after.push(...p))

  after.forEach((_p, i) => {
    if (i % 2) {
      before[i] += -top
      after[i] += -top
    } else {
      before[i] += -left
      after[i] += -left
    }
  })

  let fx

  try {
    // @ts-ignore
    fx = await import('glfx')
  } catch (e) {
    console.error(`Failed importing glfx`, e)
    return null
  }

  // create a fx canvas
  const canvas = fx.canvas()
  // create the texture
  const glfxTexture = canvas.texture(texture)
  // apply the filter
  canvas.draw(glfxTexture).perspective(before, after).update()
  // show the result on the page

  glfxTexture.destroy()

  return canvas
}

// The frame can be skewed so we are taking the bounding rect of the frame and normalize it
// to be relative to the top left of the bounding rect frame
export const normalizeFrame = (
  frame: number[][]
): {
  normalizedFrame: number[][]
  top: number
  left: number
  width: number
  height: number
} => {
  const minX: number = Math.min(...frame.map((f: number[]) => f[0]))
  const minY: number = Math.min(...frame.map((f: number[]) => f[1]))
  const maxX: number = Math.max(...frame.map((f: number[]) => f[0]))
  const maxY: number = Math.max(...frame.map((f: number[]) => f[1]))

  const normalizedFrame: number[][] = frame.map((f: number[]) => [
    f[0] - minX,
    f[1] - minY,
  ])
  const width: number = maxX - minX
  const height: number = maxY - minY

  return {
    normalizedFrame,
    top: minY,
    left: minX,
    width,
    height,
  }
}

export const initFrameCanvas = (
  canvas: HTMLCanvasElement,
  frame: number[][],
  containerSize: number,
  backgroundSize: number,
  position: { top: string; left: string }
): number[][] => {
  const containerRatio: number = containerSize / backgroundSize
  const dpr: number = window.devicePixelRatio || 1

  const { width, height, normalizedFrame } = normalizeFrame(frame)

  canvas.width = width * dpr
  canvas.height = height * dpr
  canvas.style.width = `${width * containerRatio}px`
  canvas.style.height = `${height * containerRatio}px`
  canvas.style.position = 'absolute'
  canvas.style.top = position.top
  canvas.style.left = position.left

  return normalizedFrame
}

export const getTextWidth = (
  canvas: HTMLCanvasElement,
  text: string,
  fontSize: number,
  fontFamily: string,
  canvasWidth: number
) => {
  const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d')
  if (ctx) {
    ctx.font = `${fontSize}px ${fontFamily}`
    const estimatedTextWidth: number = ctx.measureText(text).width
    const widthOverlap: number = estimatedTextWidth - canvasWidth
    // Scenario in which there is width overlap is a scenario where our calculation has resulted
    // in a width longer than our container. In this case we will take the max canvas width and reduce it a bit
    // in order to have a "padding" effect.
    if (widthOverlap > 0) {
      return canvasWidth * 0.85
    }
    return estimatedTextWidth
  }
}

export function releaseCanvas(canvas: HTMLCanvasElement) {
  canvas.width = 1
  canvas.height = 1
  const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d')
  ctx && ctx.clearRect(0, 0, 1, 1)
}

export const canvasToBlobUrl = async (
  canvas: HTMLCanvasElement,
  type?: string,
  quality?: any
): Promise<{ blobUrl: string; blob: Blob }> => {
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob: Blob | null) => {
        if (!blob) {
          return reject(new Error('Image blob could not be created'))
        }

        const blobUrl: string = URL.createObjectURL(blob)
        resolve({ blobUrl, blob })
      },
      type,
      quality
    )
  })
}
