import {
  type FRAME_COLORS,
  type MATERIAL_TYPES,
  type TILE_SIZES,
} from '@mixtiles/web-backend-shared'
import {
  type MIXED_COLORS,
  type MIXED_MATERIALS,
  type MIXED_SIZES,
} from '../../../pages/PhotoStyler/TileDesignerConsts'

// Discounts which are returned from /discounts. Skip type migration, in order to make people only use Discount type
export type DiscountsAPI = any[]

export type FetchDiscountsResponse = {
  discounts: DiscountsAPI
}

export type DiscountTileSize = TILE_SIZES | typeof MIXED_SIZES
export type DiscountTileColor = FRAME_COLORS | typeof MIXED_COLORS

export enum DiscountType {
  PERCENT = 'PERCENT',
  BUYXGETY = 'BUYXGETY',
  BUNDLE = 'BUNDLE',
  BUYOVER = 'BUYOVER',
}

type DiscountBase = {
  type: DiscountType
  tileSize: DiscountTileSize
  popular: boolean
  showPromoCodeBadge: boolean
  promoCode: string | false
  metadata: {
    discountId: string
    discountType: DiscountType
    materialType: MATERIAL_TYPES
  }
}

export type DiscountPercent = DiscountBase & {
  type: DiscountType.PERCENT
  discount: {
    type: DiscountType.PERCENT
    percent_off: number
  }
  metadata: {
    discountType: DiscountType.PERCENT
    percentOff: number
  }
}

export type DiscountBuyXGetY = DiscountBase & {
  type: DiscountType.BUYXGETY
  metadata: {
    discountType: DiscountType.BUYXGETY
    buy: number
    get: number
  }
}

export type DiscountBundle = DiscountBase & {
  type: DiscountType.BUNDLE
  metadata: {
    discountType: DiscountType.BUNDLE
    bundleTiles: number
    bundlePrice: number
    bundleCurrency: string
  }
}

export type DiscountBuyOver = DiscountBase & {
  type: DiscountType.BUYOVER
  metadata: {
    discountType: DiscountType.BUYOVER
    buyOverPrice: number
    buyOverDiscount: number
    bundleCurrency: string
  }
}

export type Discount =
  | DiscountPercent
  | DiscountBuyXGetY
  | DiscountBundle
  | DiscountBuyOver

export type DiscountMaterialType = MATERIAL_TYPES | typeof MIXED_MATERIALS
