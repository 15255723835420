/* global Blob */
// the line above meant to prevent standard.js to decline using new Blob
import { translateManager as t } from '../services/TranslateManager'
import round from 'lodash/round'
import toPairs from 'lodash/toPairs'
import fromPairs from 'lodash/fromPairs'
import orderBy from 'lodash/orderBy'
import writtenNumber from 'written-number'
import { CURRENCY_CONVERSIONS } from '../config/currency-conversion'
import FileSaver from 'file-saver'
import { formatPrice as sharedFormatPrice } from '@mixtiles/web-backend-shared'
import { getUserBrowser } from './browserUtils'
import sumBy from 'lodash/sumBy'
import deJson from '../locale/written-number-addons/de.json'
import jaJson from '../locale/written-number-addons/ja.json'
import { remixNavigationManager } from 'services/remixNavigationManager'
import { useEffect, useRef } from 'react'

const minimumDesktopWidth = '1024px'

export { getUserBrowser }

export { isSmallScreen, quicklookSupportExists } from './browserUtils'

// add german support (this package has no german support by default)
writtenNumber.i18n.de = deJson
writtenNumber.i18n.ja = jaJson

export const tagExists = (id) => {
  return document.getElementById(id)
}

export const addScript = (src, options, callback) => {
  let exists = false

  const script = document.createElement('script')

  script.src = src

  if (options.async) {
    script.async = true
  }

  if (options.id) {
    const id = `SCRIPT_${options.id}`
    script.id = id
    exists = tagExists(id)
  }

  if (!exists) {
    document.body.appendChild(script)
  }

  if (callback) {
    script.onload = () => {
      callback()
    }
  }
}

/* Returns [firstName, lastName] */
export const splitFullName = (fullName) => {
  let [firstName, ...lastName] = fullName.split(' ')
  lastName = lastName.join(' ') || undefined
  return [firstName, lastName]
}

export const mergeFirtLastNames = (firstName, lastName) => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`
  }
  if (firstName && !lastName) {
    return firstName
  }
  if (!firstName && lastName) {
    return lastName
  }
  return null
}

export function formatPriceWithPrecision(price, currency) {
  return formatPrice(price, currency, true)
}

export function formatPrice(
  price,
  currency,
  roundingPrecision,
  shouldRound = true
) {
  return sharedFormatPrice({
    price,
    currency,
    roundingPrecision,
    shouldRound,
    country: window.KEYS.ipCountry,
  })
}

export function convertUSDPriceToCurrency(usdPrice, currency) {
  return usdPrice * CURRENCY_CONVERSIONS[currency]
}

export function convertPriceToUSDCurrency(price, currency) {
  return price / CURRENCY_CONVERSIONS[currency]
}

export function convertUSDPriceToCurrencyWithVat(
  usdPrice,
  currency,
  vatPercentage
) {
  const convertedPrice = convertUSDPriceToCurrency(usdPrice, currency)
  return convertedPrice + (convertedPrice * vatPercentage) / 100
}

const ZERO_DECIMAL_CURRENCIES = [
  'BIF',
  'CLP',
  'DJF',
  'GNF',
  'JPY',
  'KMF',
  'KRW',
  'MGA',
  'PYG',
  'RWF',
  'VND',
  'VUV',
  'XAF',
  'XOF',
  'XPF',
]
export function getStripeAmountToCharge({ totalCost, currency }) {
  if (ZERO_DECIMAL_CURRENCIES.includes(currency.toUpperCase())) {
    return round(totalCost, 0)
  }

  return round(totalCost * 100, 0)
}

export function numberToText(number) {
  writtenNumber.defaults.lang = t.getLanguage()

  return writtenNumber(number)
}

export function isFacebookBrowser() {
  return (
    navigator.userAgent.includes('FBAN') || navigator.userAgent.includes('FBAV')
  )
}

export function getDeviceHeight() {
  return window.innerHeight
}

export function getDeviceWidth() {
  return window.innerWidth
}

/* https://stackoverflow.com/a/12646864 */
export function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1)) // keep this ;
    ;[array[i], array[j]] = [array[j], array[i]]
  }
}

export const simplePluralize = (str, value) => (value === 1 ? str : `${str}s`)
export const isChromeIOS = () => /CriOS\/[\d]+/.test(navigator.userAgent)

export function saveFileFromResponse(response, filename, isPdf = true) {
  // chrome in ios opens in the same tab, we do this to avoid it
  if (isChromeIOS()) {
    const blob = new Blob(
      [response.data],
      isPdf ? { type: 'application/pdf' } : {}
    )
    const urlObj = window.URL.createObjectURL(blob)
    window.open(urlObj, '_blank')
  } else {
    const blob = new Blob([response.data])
    FileSaver.saveAs(blob, filename)
  }
}

/* Removes any attributes with null or undefined values from the given object */
export function filterObjectBlankAttributes(obj) {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value === null || value === undefined) {
      return acc
    }

    acc[key] = value
    return acc
  }, {})
}

export function getUserBrowserProperties() {
  return {
    Browser: getUserBrowser(),
    'Full URL': window.location.href,
    'Screen Size': `${window.innerWidth}x${window.innerHeight}`,
    'Screen Width': window.innerWidth,
    'Screen Height': window.innerHeight,
    'Window Screen Height': window.screen.height,
  }
}

export function hasDesktopWidth() {
  return window.innerWidth >= minimumDesktopWidth
}

export function isProduction() {
  return (window.KEYS.runtimeEnv || 'staging') === 'production'
}

export function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value)
}

export function sortHashByKeys(hash) {
  return Object.keys(hash)
    .sort()
    .reduce(function (result, key) {
      result[key] = hash[key]
      return result
    }, {})
}

export function sortedHashKeysByValue(object, order = 'desc') {
  const asPairs = toPairs(object)
  const ordered = orderBy(asPairs, [1], [order])
  const newObjectValue = fromPairs(ordered)
  return Object.keys(newObjectValue)
}

// TODO: Remove this once we change search to hooks and use the useQueryParamState hook on 'search'
export function setQueryParam(key, value) {
  key = encodeURIComponent(key)
  value = encodeURIComponent(value)

  // urlParams looks like ['key1=value1', 'key2=value2', ...]
  const location = remixNavigationManager.getLocation()
  const urlParams = location.search.includes('&')
    ? location.search.substr(1).split('&')
    : []

  let i = 0
  for (; i < urlParams.length; i++) {
    if (urlParams[i].startsWith(`${key}=`)) {
      if (value) {
        const pair = urlParams[i].split('=')
        pair[1] = value
        urlParams[i] = pair.join('=')
      } else {
        urlParams.splice(i, 1)
      }
      break
    }
  }

  if (i >= urlParams.length && value) {
    urlParams[urlParams.length] = [key, value].join('=')
  }

  const params = urlParams.join('&')

  if (!params) {
    remixNavigationManager.navigate(location, { state: {}, replace: true })
    return
  }
  remixNavigationManager.navigate(
    { ...location, search: `?${params}` },
    { state: {}, replace: true }
  )
}

export function setOrAdd(obj, key, val) {
  obj[key] = obj[key] ? obj[key] + val : val
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const hiddenElement = (shouldHide, restStyles = {}) => {
  return {
    ...{ display: `${shouldHide ? 'none' : 'initial'}` },
    ...restStyles,
  }
}

/**
 * This function is used instead of _.startCase() because _.startCase() removes
 * non-alphabetic characters. For example: 'black & white' -> 'Black White'
 */
export function upperEveryFirstLetter(phrase) {
  const words = phrase.split(' ')
  return words
    .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}

export function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function nestMapValues(map, nestedValueKey) {
  const result = {}
  for (const [key, val] of Object.entries(map)) {
    result[key] = { [nestedValueKey]: val }
  }
  return result
}

export function calculateDiscountPercent(actualPrice, originalPrice) {
  return Math.round(((originalPrice - actualPrice) / originalPrice) * 100)
}

export function roundFloat(value, precision = 2) {
  return parseFloat(value.toFixed(precision))
}

export function sin(deg) {
  return Math.sin((deg * Math.PI) / 180)
}

export function roundDecimal(number, digits = 2) {
  return Number(number.toFixed(digits))
}

// Returns the rounded sum of all item in the supplied collection
export function roundedDecimalSumBy(collection, iteratee) {
  return roundDecimal(sumBy(collection, iteratee))
}

export function getHtmlElementAttributes(element) {
  return Object.fromEntries(
    Object.values(element.attributes).map((a) => [a.name, a.value])
  )
}

export function svgToString(svg) {
  // eslint-disable-next-line no-undef
  const serializer = new XMLSerializer()
  return serializer.serializeToString(svg)
}

export function parseSvgTransform(transformString) {
  const translateRegex = /translate\(([^,]+),([^)]+)\)/
  // Examples the regex supports: rotate(0) rotate(10.5) rotate(-3.5 10 20)
  const rotateRegex = /rotate\(([\d-.]+)(?: ([\d-.]+))?(?: ([\d-.]+))?\)/

  const translateValues = transformString.match(translateRegex)
  const rotateValues = transformString.match(rotateRegex)

  return {
    translateX: translateValues ? parseFloat(translateValues[1]) : undefined,
    translateY: translateValues ? parseFloat(translateValues[2]) : undefined,
    rotateAngle: rotateValues ? parseFloat(rotateValues[1]) : undefined,
    rotateAxisX:
      rotateValues && rotateValues[2] !== undefined
        ? parseFloat(rotateValues[2])
        : undefined,
    rotateAxisY:
      rotateValues && rotateValues[3] !== undefined
        ? parseFloat(rotateValues[3])
        : undefined,
  }
}

export const INCH_TO_METER = 0.0254

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

// A hook that returns true only if the value changed in the last render
export function useDidChangeLastRender(value) {
  const previousValue = useRef(value)
  const hasChanged = value !== previousValue.current

  useEffect(() => {
    previousValue.current = value
  })

  return hasChanged
}
