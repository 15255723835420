import { type StateCreator } from 'zustand/vanilla'
import {
  type SetShippingOptionsParams,
  type ShippingSlice,
  type ShippingOption,
} from './shippingSlice.types'
import { SHIPPING_OPTION_FREE } from './shippingConsts'
import { getUrlParam } from '../../utils/UrlUtils'
import {
  shouldUseCachedShippingOptions,
  isOptionExpress,
} from './shippingUtils'
import { fetchDeliveryPromise } from '../../services/OrderUtils'
import { analytics } from '../../services/Analytics/Analytics'
import { type Store } from '../store.types'

export const createShippingSlice: StateCreator<
  Store,
  [['zustand/persist', unknown]],
  [],
  ShippingSlice
> = (set, get) => ({
  shippingOptionsMetadata: {},
  setShippingOptionsMetadata: async ({
    shippingOptionsMetadata,
    variantsIds,
  }: SetShippingOptionsParams) => {
    shippingOptionsMetadata.topQueue = !!getUrlParam('tq')
    shippingOptionsMetadata.requestTime = new Date().getTime()
    if (
      !shouldUseCachedShippingOptions(
        shippingOptionsMetadata,
        get().shippingOptionsMetadata
      )
    ) {
      return
    }

    const delivery = await fetchDeliveryPromise(
      shippingOptionsMetadata.address,
      shippingOptionsMetadata.orderType,
      shippingOptionsMetadata.topQueue,
      shippingOptionsMetadata.uploads,
      variantsIds,
      shippingOptionsMetadata.productType
    )
    const freeShippingOptions = delivery.shippingOptions.filter(
      (opt: ShippingOption) => !isOptionExpress(opt)
    )

    const shouldShowExpressShipping =
      shippingOptionsMetadata.address && delivery.shippingOptions.length

    const shippingOptions = shouldShowExpressShipping
      ? delivery.shippingOptions
      : freeShippingOptions
    if (shippingOptions.length > freeShippingOptions.length) {
      analytics.setUserProperties({ 'Has Seen Express Shipping': true })
    }
    shippingOptionsMetadata.shippingOptions = shippingOptions
    return set(() => ({
      shippingOptionsMetadata,
    }))
  },

  shippingPreference: SHIPPING_OPTION_FREE,
  selectedShippingOptionName: SHIPPING_OPTION_FREE,
  clearShippingOptionsMetadata: () => {
    return set(() => ({
      shippingOptionsMetadata: {},
      shippingPreference: SHIPPING_OPTION_FREE,
    }))
  },
})
