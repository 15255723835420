import useOnMount from 'hooks/useOnMount'
import useUsercentrics from 'hooks/useUsercentrics'
import { analytics } from 'services/Analytics/Analytics'
import { useNoSSR } from 'utils/NoSSR'

export default function RootClientInit() {
  const isClient = useNoSSR()

  useOnMount(() => {
    analytics.trackFirstOpen()
  })

  if (!isClient) return null

  return <ClientInit />
}

function ClientInit() {
  useUsercentrics()

  return null
}
