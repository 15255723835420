import { CouponErrorCodes } from '@mixtiles/web-backend-shared'

export const DISCOUNT_COUPON_KEY = 'discountCoupon'
export const GIFT_CARD_CODES_KEY = 'giftCardCodes'
export const ALL_DISCOUNT_COUPON_KEY = 'allDiscountCoupon'
export const TILES_CREDITS_KEY = 'tilesCredits'
export const SIZES_BUNDLE_CODE = 'SIZES12FOR99'
export const WELCOME_COUPON = 'WELCOME'
export const DISCOUNT_TYPES = {
  UNIT: 'UNIT',
  PERCENT: 'PERCENT',
  BUYXGETY: 'BUYXGETY',
  BUNDLE: 'BUNDLE',
  BUYOVER: 'BUYOVER',
  MIX: 'MIX',
  GIFT_CARD: 'GIFT_CARD',
  SHIPPING_BUY_OVER: 'SHIPPING_BUY_OVER',
  GET_AMOUNT_OVER_AMOUNT: 'GET_AMOUNT_OVER_AMOUNT', //  for example: Get 10$ on order over 70$
  EARN_CASH_OVER_AMOUNT: 'EARN_CASH_OVER_AMOUNT', // Example: earn 100$ for spending after purchasing more than 100$
}
export const DISCOUNT_TYPE_LARGE_ORDER = 'LARGE_ORDER'

export const PHOTOS_SUPPORTED_DISCOUNTS_PROMO_CODE_BOX = [
  DISCOUNT_TYPES.PERCENT,
  DISCOUNT_TYPES.BUYXGETY,
  DISCOUNT_TYPES.BUNDLE,
  DISCOUNT_TYPES.BUYOVER,
  DISCOUNT_TYPES.GET_AMOUNT_OVER_AMOUNT,
  DISCOUNT_TYPES.EARN_CASH_OVER_AMOUNT,
]

export const PROMO_CODE_ERRORS_MESSAGES = {
  DEFAULT: 'order.promo_code_errors.messages.default',
  NON_EXISTING: 'order.promo_code_errors.messages.non_existing',
  SELF_REFERRAL: 'order.promo_code_errors.messages.self_referral',
  ALREADY_A_USER: 'order.promo_code_errors.messages.already_a_user',
  QUANTITY_EXCEEDED: 'order.promo_code_errors.messages.quantity_exceeded',
  REDEMPTIONS_PER_CUSTOMER_EXCEEDED:
    'order.promo_code_errors.messages.redemptions_per_customer_exceeded',
  EXPIRED: 'order.promo_code_errors.messages.expired',
  SERVER_ERROR: 'order.promo_code_errors.messages.server_error',
  ALREADY_REGISTERED: 'order.promo_code_errors.messages.already_registered',
  [CouponErrorCodes.GIFT_CARD_EMPTY]:
    'order.promo_code_errors.titles.gift_card_empty',
  [CouponErrorCodes.GIFT_CARD_INACTIVE]:
    'order.promo_code_errors.titles.gift_card_inactive',
}

// Error codes that appear on the client. Therefore, they do not appear in CouponErrorCodes
export const CLIENT_PROMO_CODE_ERRORS_TYPES = {
  ALREADY_REGISTERED: 'ALREADY_REGISTERED',
}

/* Coupon revalidation will only occur once every X minutes (when invoked) to prevent server load.
 * Page refresh will reset the timeout */
export const COUPON_REVALIDATION_BACKOFF_MINUTES = 30

export const PROMO_CODE_SOURCE = {
  CHECKOUT: 'Checkout',
  ACCOUNT: 'Account',
  BANNER: 'Banner',
  SIDE_MENU: 'Side Menu',
}
