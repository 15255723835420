import { createContext, useContext } from 'react'

export type DeviceType = 'mobile' | 'tablet' | 'browser'
export type UserDeviceData = {
  // Web View inside another mobile app
  isInApp: boolean
  // Mobile/Tablet
  isMobile: boolean
  // Mobile only
  isMobileOnly: boolean
  isTablet: boolean
  isDesktop: boolean
  isSmartTV: boolean
  isIOS: boolean
  isAndroid: boolean
  isWindows: boolean
  isMacOS: boolean
  isLinux: boolean
  isSafari: boolean
  isMobileSafari: boolean
  osName: string
  isSamsungBrowser: boolean
  browserName: string
  browserVersion: string
  deviceType: DeviceType
}
export const UserDeviceContext = createContext<UserDeviceData | null>(null)

export const useUserDeviceData = () => {
  const device = useContext(UserDeviceContext)

  if (!device) {
    throw new Error('Nothing not found in UserDeviceContext')
  }

  return device
}
