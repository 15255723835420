import { type StateCreator } from 'zustand/vanilla'
import { type Store } from '../store.types'
import {
  type AmountOverAmountCoupon,
  type BundleCoupon,
  type Coupon,
  type PercentCoupon,
  type PromoCodeHandlersSlice,
  type UnitCoupon,
} from './promoCodeSlice.types'
import {
  DISCOUNT_TYPES,
  CLIENT_PROMO_CODE_ERRORS_TYPES,
} from './promoCodeConsts'
import { logger } from '../../services/logger'
import { skipPricingPopup } from '../../pages/DiscoveryPage/PricingPopup/pricingPopupService'
import { analytics, EVENT_NAMES } from '../../services/Analytics/Analytics'
import { CouponErrorCodes, TILE_SIZES } from '@mixtiles/web-backend-shared'
import {
  PRODUCT_TYPES,
  productTypeState,
} from '../../services/ProductTypeState'
import { getCurrency } from '../../services/PricingUtils'
import { CURRENCY_CONVERSIONS } from '../../config/currency-conversion'
import { trackPromoCodeAdded } from './promoCodeUtils'

export const createPromoCodeHandlersSlice: StateCreator<
  Store,
  [['zustand/persist', unknown]],
  [],
  PromoCodeHandlersSlice
> = (_, get) => {
  function handleOtherDiscountTypes(
    couponData: Coupon,
    code: string,
    persist: boolean
  ) {
    const discountType = couponData?.metadata?.discountType

    switch (discountType) {
      case DISCOUNT_TYPES.BUNDLE:
        return handleBundleDiscount(couponData as BundleCoupon, code, persist)
      case DISCOUNT_TYPES.MIX:
      case DISCOUNT_TYPES.BUYXGETY:
      case DISCOUNT_TYPES.BUYOVER:
      case DISCOUNT_TYPES.EARN_CASH_OVER_AMOUNT:
        get().addDiscountCoupon(couponData, persist)
        return {
          discountType,
          metadata: couponData.metadata,
          code: couponData.code,
        }
      case DISCOUNT_TYPES.GET_AMOUNT_OVER_AMOUNT:
        return handleAmountOverAmountDiscount(
          couponData as AmountOverAmountCoupon,
          persist
        )
      default:
        logger.error('Unsupported discount type', null, { discountType })
        return {}
    }
  }

  function handlePercentDiscount(couponData: PercentCoupon, persist: boolean) {
    const percentCoupon = couponData as PercentCoupon
    const percentOff = percentCoupon.discount.percent_off
    if (
      get().discountCoupon?.discount?.type === DISCOUNT_TYPES.PERCENT &&
      percentOff >= (get().discountCoupon?.discount?.percent_off || 0)
    ) {
      // Only set new coupon if it has a higher or equal discount
      get().addDiscountCoupon(percentCoupon, persist)
    } else {
      return {
        discountType: percentCoupon.discount.type,
        amount:
          get().discountCoupon?.discount?.type === DISCOUNT_TYPES.PERCENT
            ? get().discountCoupon?.discount?.percent_off
            : 0,
        code: percentCoupon.code,
      }
    }

    return {
      discountType: couponData.discount.type,
      amount: percentOff,
      code: couponData.code,
    }
  }

  async function handleUnitDiscount(couponData: UnitCoupon) {
    await get().fetchTileCredits()
    return {
      discountType: couponData.discount.type,
      amount: couponData.discount.unit_off,
      referringUser: couponData.referringUser,
      metadata: couponData.metadata,
      code: couponData.code,
    }
  }

  function handleBundleDiscount(
    couponData: BundleCoupon,
    code: string,
    persist: boolean
  ) {
    couponData.discount.amount_off = 0

    const sizesBundle = !couponData.metadata.tileSizesList.includes(
      TILE_SIZES.SQUARE_8X8
    )

    if (
      sizesBundle &&
      productTypeState.getProductType() !== PRODUCT_TYPES.CLASSIC
    ) {
      analytics.track('Sizes Promo Code Attempted Without Sizes Experiment', {
        Code: code,
        Error: 'UNKNOWN_ERROR',
      })
      return { error: 'NON_EXISTING' } // Sometimes server returns no errorCode
    }
    get().addDiscountCoupon(couponData, persist)
    return {
      discountType: DISCOUNT_TYPES.BUNDLE,
      metadata: couponData.metadata,
      code: couponData.code,
    }
  }

  function handleAmountOverAmountDiscount(
    couponData: AmountOverAmountCoupon,
    persist: boolean
  ) {
    const clientCurrency = getCurrency() as keyof typeof CURRENCY_CONVERSIONS
    const formattedAmountOff = Math.ceil(
      (couponData.discount.amount_off / 100) *
        CURRENCY_CONVERSIONS[clientCurrency]
    )
    const formattedMinOrderAmount = Math.ceil(
      couponData.metadata.minOrderAmount * CURRENCY_CONVERSIONS[clientCurrency]
    )
    couponData.discount.amount_off = formattedAmountOff
    couponData.metadata.minOrderAmount = formattedMinOrderAmount
    // TODO: in order to keep the voucher structure unified, im not overwrite the coupon discount (couponData.discount.type still 'AMOUNT' , and also not remove the new discount type from metadata.) we'll should consider to do that someday.
    get().addDiscountCoupon(couponData, persist)
    return {
      discountType: DISCOUNT_TYPES.GET_AMOUNT_OVER_AMOUNT,
      amount: formattedAmountOff,
      metadata: couponData.metadata,
      code: couponData.code,
    }
  }

  function handleGiftCardDiscount(couponData: Coupon) {
    get().addGiftCardCreditsCode(couponData.code || '')
    skipPricingPopup()
    analytics.setUserProperties({ 'Used Gift Card': true })
    get().handlePromoCodeChange()
    return {
      discountType: couponData?.discount?.type,
      metadata: couponData.metadata,
      code: couponData.code,
    }
  }

  return {
    handleInvalidCoupon: (couponData: Coupon, source: string, code: string) => {
      if (couponData.errorCode === CouponErrorCodes.ALREADY_LOADED_COUPON) {
        // should happen only in units coupon.
        return {
          discountType: couponData?.discount?.type,
          amount: couponData?.discount?.unit_off,
          code: couponData.code,
        }
      }
      analytics.track('Invalid Promo Code Attempted', {
        Code: code,
        Source: source,
        Error: couponData.errorCode || 'UNKNOWN_ERROR',
      })
      return { error: couponData.errorCode || 'UNKNOWN_ERROR' } // Sometimes server returns no errorCode
    },
    handleValidCoupon: async (
      couponData: Coupon,
      code: string,
      source: string,
      persist: boolean,
      pseudonym?: string
    ) => {
      couponData.pseudonym = pseudonym

      if (get().isDiscountCouponRegistered(code)) {
        if (!couponData.pseudonym) {
          analytics.track(EVENT_NAMES.PROMO_CODE_ALREADY_REGISTERED, {
            Code: code,
          })
          return { error: CLIENT_PROMO_CODE_ERRORS_TYPES.ALREADY_REGISTERED }
        } else {
          get().removeDiscountCouponFromList(code)
        }
      }
      trackPromoCodeAdded(couponData, code, source)
      switch (couponData?.discount?.type) {
        case DISCOUNT_TYPES.UNIT:
          return handleUnitDiscount(couponData as UnitCoupon)
        case DISCOUNT_TYPES.GIFT_CARD:
          return handleGiftCardDiscount(couponData)
        case DISCOUNT_TYPES.PERCENT:
          return handlePercentDiscount(couponData as PercentCoupon, persist)
        default:
          return handleOtherDiscountTypes(couponData, code, persist)
      }
    },
  }
}
