import {
  DEFAULT_PAYPAL_ACCOUNT_NAME,
  DEFAULT_STRIPE_ACCOUNT_NAME,
  KEY_PAYPAL_ACCOUNT_NAME,
  KEY_STRIPE_ACCOUNT_NAME,
  KEY_STRIPE_CUSTOMER,
  OLD_PAYPAL_ACCOUNT_NAME,
  PAYPAL_ACCOUNTS_DATA,
  STRIPE_ACCOUNTS_DATA,
} from './paymentConsts'
import { countryManager } from '../../services/CountryManager'
import { type StripeAccountName } from './paymentSlice.types'
import { PaymentMethodType } from '../../services/PaymentManager.types'
import { normalizeAddress } from '../addressSlice/addressUtils'
import { mixtilesAxios } from '../../utils/ApiUtils'
import { translateManager as t } from '../../services/TranslateManager'
import { getStripeAmountToCharge } from '../../utils/utils'
import { logger } from '../../services/logger'
import StorageManager from '../../services/StorageManager'

export function initializeStripeAccountName(): StripeAccountName {
  let stripeAccountName = StorageManager.get(
    KEY_STRIPE_ACCOUNT_NAME
  ) as StripeAccountName
  if (stripeAccountName) {
    return stripeAccountName
  }

  if (StorageManager.get(KEY_STRIPE_CUSTOMER)) {
    // Stripe customer exists in a specific stripe account, so if stripe customer is already created, but
    // no stripe account name is cached in the local storage, should use old default stripe account.
    return DEFAULT_STRIPE_ACCOUNT_NAME as StripeAccountName
  }

  // Choose Stripe account based on the pricing currency, in order to avoid high exchange rates in Stripe.
  stripeAccountName = DEFAULT_STRIPE_ACCOUNT_NAME
  const userCountry = countryManager.getPricingCountry()
  for (const accountName of Object.keys(STRIPE_ACCOUNTS_DATA) as Array<
    keyof typeof STRIPE_ACCOUNTS_DATA
  >) {
    if (
      STRIPE_ACCOUNTS_DATA[accountName].userCountries.includes(
        userCountry.toUpperCase()
      )
    ) {
      stripeAccountName = accountName
      break
    }
  }

  return stripeAccountName
}

export function initializePayPalAccountName() {
  // Get cached paypal account if exists. Otherwise, will choose paypal account by user's country.
  let paypalAccountName = StorageManager.get(KEY_PAYPAL_ACCOUNT_NAME)
  if (paypalAccountName && paypalAccountName !== OLD_PAYPAL_ACCOUNT_NAME) {
    return paypalAccountName
  }

  // Choose PayPal account based on the pricing currency, in order to avoid high exchange rates in PayPal.
  paypalAccountName = DEFAULT_PAYPAL_ACCOUNT_NAME

  const userCountry = countryManager.getPricingCountry()
  for (const accountName of Object.keys(PAYPAL_ACCOUNTS_DATA) as Array<
    keyof typeof PAYPAL_ACCOUNTS_DATA
  >) {
    if (
      PAYPAL_ACCOUNTS_DATA[accountName].userCountries.includes(
        userCountry.toUpperCase()
      )
    ) {
      paypalAccountName = accountName
      break
    }
  }

  return paypalAccountName
}

export function isPaymentMethodGoogleOrApple(
  paymentMethodType: PaymentMethodType
) {
  return [PaymentMethodType.GOOGLE_PAY, PaymentMethodType.APPLE_PAY].includes(
    paymentMethodType
  )
}

export function shouldRoundForPaypal(currency: string, value: number) {
  if (currency === 'JPY') {
    return Math.round(value)
  } else {
    return parseFloat(value.toFixed(2))
  }
}

export async function initiatePaypalPayment(
  orderPriceSummary: any,
  address: any,
  paypalAccountName: string
) {
  const shippingTotal = shouldRoundForPaypal(
    orderPriceSummary.currency,
    orderPriceSummary.shippingCost || 0
  )
  const taxTotal = shouldRoundForPaypal(
    orderPriceSummary.currency,
    orderPriceSummary.tax
  )
  const amount = shouldRoundForPaypal(
    orderPriceSummary.currency,
    orderPriceSummary.totalPrice
  )
  const itemTotal = amount - shippingTotal - taxTotal

  const params = {
    order_size: orderPriceSummary.totalItems,
    shipping_total: shippingTotal,
    tax_total: taxTotal,
    amount,
    item_total: itemTotal,
    currency_code: orderPriceSummary.currency,
    address: normalizeAddress(address, false),
    paypalAccountName,
    product_type: orderPriceSummary.productType || 'mixtiles',
  }
  const { paypal_transaction_id: transactionId } = await mixtilesAxios
    .post('v1/paypal/payment', params)
    .then((response) => response.data)

  return transactionId
}

// Returns whether the payment method can be saved for future use: https://stripe.com/docs/payments/payment-methods#usage
// For example some BNPL payment methods cannot be attached to Customers.
export function isReusablePaymentMethod(paymentMethodType: PaymentMethodType) {
  return [
    PaymentMethodType.CREDIT_CARD,
    PaymentMethodType.PAYPAL,
    PaymentMethodType.APPLE_PAY,
    PaymentMethodType.GOOGLE_PAY,
    PaymentMethodType.LINK,
  ].includes(paymentMethodType)
}

export function updatePaymentRequest({
  paymentRequest,
  orderPriceSummary,
  displayItems,
}: {
  paymentRequest: any
  orderPriceSummary: any
  displayItems: any
}) {
  const totalLabel = t.get('order.checkout.payment_summary_rows.total')
  paymentRequest.update({
    currency: orderPriceSummary.currency.toLowerCase(),
    total: {
      label: totalLabel,
      amount: getStripeAmountToCharge({
        totalCost: orderPriceSummary.totalPrice,
        currency: orderPriceSummary.currency,
      }),
    },
    displayItems,
  })
}

// Look later
export function getPayPalKey(paypalAccountName: string) {
  switch (paypalAccountName) {
    case 'US':
      return window.KEYS.paypalUSClientId
    case 'US_2':
      return window.KEYS.paypalUS2ClientId
    case 'NL':
      return window.KEYS.paypalNLClientId
    default:
      logger.warning('Missing paypal key for given paypal account name', {
        paypalAccountName,
      })
      return window.KEYS.paypalUSClientId
  }
}
