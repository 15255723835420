import isEqual from 'lodash/isEqual'
import {
  CACHE_TIMEOUT_MS,
  PROMISE_DATE_RANGE_SIZE_DAYS,
  SHIPPING_OPTION_EXPRESS,
  SHIPPING_OPTION_FREE,
} from './shippingConsts'
import {
  type ShippingOption,
  type ShippingOptionsMetadata,
} from './shippingSlice.types'
import * as Sentry from '@sentry/remix'
import { logger } from '../../services/logger'
import { addDaysToIsoDate } from '../../utils/DateTimeUtils'
import { ORDER_TYPE_ART, ORDER_TYPE_PRODUCT } from '../../utils/OrderType'
import { getTileStyleData } from '../../services/tilesStyling'
import { getTileMaterialType } from '../../services/tileMaterial'
import { getTileSize } from '../../components/PhotoBank/tileUtils'
import { FRAME_COLORS } from '../../core/Tile/consts'
import { isFramedMaterial } from '../../utils/materialTypeUtils'
import { MATERIAL_TYPES } from '@mixtiles/web-backend-shared'
import { type Tile } from '../../components/TilesProvider/TilesProvider.types'

export function shouldUseCachedShippingOptions(
  newShippingOptionsMetadata: ShippingOptionsMetadata,
  cachedShippingOptionsMetadata: ShippingOptionsMetadata
) {
  return !!(
    isEqual(
      newShippingOptionsMetadata.address,
      cachedShippingOptionsMetadata.address
    ) &&
    isEqual(
      newShippingOptionsMetadata.orderType,
      cachedShippingOptionsMetadata.orderType
    ) &&
    isEqual(
      newShippingOptionsMetadata.topQueue,
      cachedShippingOptionsMetadata.topQueue
    ) &&
    isEqual(
      newShippingOptionsMetadata.uploads,
      cachedShippingOptionsMetadata.uploads
    ) &&
    isEqual(
      newShippingOptionsMetadata.productType,
      cachedShippingOptionsMetadata.productType
    ) &&
    newShippingOptionsMetadata.requestTime -
      cachedShippingOptionsMetadata.requestTime <
      CACHE_TIMEOUT_MS
  )
}

export function getShippingOptionPricing(shippingOption: any) {
  // currencyCode may only appear in pricing for a short period of time until the backend is also deployed (and then this can be removed)
  return shippingOption.pricing.currencyCode
    ? shippingOption.pricing
    : Object.values(shippingOption.pricing)[0]
}

export function doesOptionHaveCorrectPricing({
  shippingOption,
  currency,
}: any) {
  const backendCurrency =
    getShippingOptionPricing(shippingOption).currencyCode.toUpperCase()
  if (backendCurrency !== currency.toUpperCase()) {
    Sentry.captureMessage(
      `Mismatch in currency between web pricing (${currency}) and shipping pricing from backend (${backendCurrency})`,
      'warning'
    )
    logger.warning(
      'Mismatch in currency between web pricing and shipping pricing from backend',
      { currency, backendCurrency }
    )
    return false
  }
  return true
}

export function isOptionExpress(shippingOption: ShippingOption) {
  return (
    shippingOption &&
    shippingOption.shippingOptionName === SHIPPING_OPTION_EXPRESS
  )
}

/**
 * Calculate range of dates for shipping options based on an end date and a number
 * of days before. Adjust the date if it falls on a weekend.
 * @param endDateISO
 */
export function calculateShippingRange(endDateISO: string) {
  let startDateISO = addDaysToIsoDate(endDateISO, -PROMISE_DATE_RANGE_SIZE_DAYS)

  const dayOfWeek = new Date(startDateISO).getDay()
  if (dayOfWeek === 0) {
    // If the lower bound of the range falls on Sunday, set it to be Monday (push 1 day forward).
    startDateISO = addDaysToIsoDate(startDateISO, 1)
  } else if (dayOfWeek === 6) {
    // If the lower bound of the range falls on Saturday, set it to be Friday (push 1 day backward).
    startDateISO = addDaysToIsoDate(startDateISO, -1)
  }

  // Update startDateISO if it is less than 3 days from now (even if it falls on Saturday/Sunday)
  const minStartDateISO = addDaysToIsoDate(new Date(), 3)
  if (startDateISO < minStartDateISO) {
    startDateISO = minStartDateISO
  }

  return { startDateISO, endDateISO }
}

export function shouldShowDeliveryRange(deliveryPromise: ShippingOption) {
  // Only show range for free shipping
  return deliveryPromise.shippingOptionName === SHIPPING_OPTION_FREE
}

/**
 * Returns the necessary information about the order's tiles for an accurate delivery promise calculation
 */
export function getPartialUploadsInformation({
  orderType,
  set,
  orderItems,
}: any) {
  if (orderType === ORDER_TYPE_ART) {
    const tiles = set.items.map((item: any) => item.tiles).flat()
    return tiles.map((tile: Tile) => {
      return {
        tileSize: tile.size,
        tileType:
          tile.frameStyle.frameColor === 'FRAMELESS' ? 'classic' : 'framed',
        frameColor: tile?.frameStyle?.frameColor?.toLowerCase(),
      }
    })
  }

  if (orderType === ORDER_TYPE_PRODUCT) {
    return orderItems
      .map((orderItem: any) => {
        return orderItem.orderTiles.map((tile: Tile) => {
          const frameColor =
            getTileStyleData(tile).frameColor || tile?.style?.color // tile?.style?.color is used for gallery walls
          const materialType = getTileMaterialType(tile)
          return {
            tileSize: getTileSize(tile),
            tileType:
              frameColor === FRAME_COLORS.NONE ||
              !isFramedMaterial(materialType)
                ? 'classic'
                : 'framed',
            frameColor: isFramedMaterial(materialType)
              ? frameColor
              : FRAME_COLORS.NONE,
            materialType:
              materialType === MATERIAL_TYPES.FRAMELESS
                ? MATERIAL_TYPES.CLASSIC
                : materialType,
          }
        })
      })
      .flat()
  }
}
