import { transformImageUrl } from '../../services/CloudinaryService'
import { cropImagePromise, scaleCropParams } from '../../utils/cropUtils'
import {
  getDPI,
  isCenterpiece,
  MATERIAL_TYPES,
  TILE_SIZES,
  TILESIZES_TO_INCHES,
} from '@mixtiles/web-backend-shared'
import sortBy from 'lodash/sortBy'
import countBy from 'lodash/countBy'
import {
  getSelectedSize,
  isSizes,
  SIZES_SHORT_NAMES,
} from '../../services/SizesManager'
import omit from 'lodash/omit'
import { getDPIThresholdWrapper } from '../../pages/PhotoStyler/TileWarning/tileWarning.utils'
import { getImageDataByUrl } from '../../utils/ImageUtils'

export async function getThumbCroppedImageUrl(photo, cropParams) {
  const { thumbDimensions, lowResUrl, highResUrl, width, isLocal } = photo
  if (isLocal) {
    const cropScale = thumbDimensions.low.width / width
    const { image } = await getImageDataByUrl(lowResUrl)
    return cropImagePromise(image, scaleCropParams(cropParams, cropScale))
  } else {
    const cropScale = thumbDimensions.high.width / width
    return transformImageUrl(highResUrl, scaleCropParams(cropParams, cropScale))
  }
}

export function getTileCropParams(tile) {
  return tile.cropParams[tile.currentAspectRatio]
}

export function getRelativeCropParams(tile, cropParams) {
  return {
    x: cropParams.x / tile.width,
    y: cropParams.y / tile.height,
    width: cropParams.width / tile.width,
    height: cropParams.height / tile.height,
  }
}

export function getTileCropType(tile) {
  const cropParams = getTileCropParams(tile) || {}
  return cropParams.cropType
}

export function findLargestFittingSize(tile, supportedSizes) {
  // Convert the sizes object into an array and sort by area in descending order.
  const sortedSizes = sortBy(
    supportedSizes.map((size) => {
      const sizeInInches = TILESIZES_TO_INCHES[size]
      return { ...sizeInInches, sizeKey: size }
    }),
    [
      (entry) => {
        return -(entry.width * entry.height)
      },
    ]
  )

  // Find the largest size that fits the tile.
  for (const { sizeKey } of sortedSizes) {
    const { widthDPI, heightDPI } = getDPI(tile, sizeKey)
    const DPIThreshold = getDPIThresholdWrapper(sizeKey)
    if (widthDPI > DPIThreshold && heightDPI >= DPIThreshold) {
      return sizeKey
    }
  }

  return null // No fitting size found
}

/**
 * Return the size of a tile.
 * NOTE: Currently, there are multiple different Tile object implementations
 * with different ways to determine its size. Can be removed once we have a
 * single Tile implementation with 'size'
 *
 * @param tile
 * @returns {string} Size of a tile, default tile size is 8X8.
 */
export function getTileSize(tile) {
  return (
    tile.size ||
    tile.tileSize ||
    tile.frameSize ||
    (isSizes() && getSelectedSize()) || // getSelectedSize() is relevant only for isSizes()
    TILE_SIZES.SQUARE_8X8
  )
}

export function getTileSizesCount(tiles) {
  return countBy(tiles.map(getTileSize))
}

export function isSingleSizeOrder(tiles) {
  const uniqueSizes = Object.keys(getTileSizesCount(tiles))
  return uniqueSizes.length === 1
}

function getColorSizesCount(tiles) {
  return countBy(tiles.map((tile) => tile.customStyle?.frameColor))
}

export function isSingleColorOrder(tiles) {
  const uniqueColors = Object.keys(getColorSizesCount(tiles))
  return uniqueColors.length === 1
}

export function getTilesSizesNames(tiles = []) {
  const sizesList = tiles.map(getTileSize)
  const shortNames = sizesList.map((tileSize) => SIZES_SHORT_NAMES[tileSize])
  return [...new Set(shortNames)]
}

export function getWidestTile(tiles) {
  const tilesWithoutCenterpiece = Object.values(tiles).filter(
    (tile) => !isCenterpiece(tile)
  )

  if (!tilesWithoutCenterpiece.length) {
    return tiles[0]
  }

  return tilesWithoutCenterpiece.reduce((a, b) => {
    const aSize = TILESIZES_TO_INCHES[a.tileSize]
    const bSize = TILESIZES_TO_INCHES[b.tileSize]

    return aSize.width > bSize.width ? a : b
  })
}

export function filterTileParams(tile) {
  return omit(tile, ['ref'])
}

// It returns the relative crop rect according to the tile crop params and relative to
// input image
export function calcTileCropParamsForImage(
  tile,
  imageNaturalWidth,
  imageNaturalHeight
) {
  const tileCropParams = getTileCropParams(tile)

  return {
    x: Math.round((tileCropParams.x / tile.width) * imageNaturalWidth),
    y: Math.round((tileCropParams.y / tile.height) * imageNaturalHeight),
    width: Math.round((tileCropParams.width / tile.width) * imageNaturalWidth),
    height: Math.round(
      (tileCropParams.height / tile.height) * imageNaturalHeight
    ),
  }
}

// If all tiles are 8x8 (or with centerpiece) we estimate it'll be a plastic order
export function isProjectedPlasticOrder(tiles) {
  return tiles.every((tile) => {
    const isClassicTile =
      tile.tileSize === TILE_SIZES.SQUARE_8X8 &&
      (tile.materialType === MATERIAL_TYPES.CLASSIC ||
        tile.materialType === MATERIAL_TYPES.FRAMELESS)
    return isClassicTile || isCenterpiece(tile)
  })
}
