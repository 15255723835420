import { type PickerFileMetadata, type Security } from 'filestack-js'
import { logger } from '../services/logger'
import axios from 'axios'
import { runAsyncWithRetries } from './promises'

const SIZES = '60vw'
const SRCSET_IMAGE_WIDTHS = [
  180, 360, 540, 720, 900, 1080, 1296, 1512, 1728, 1944, 2160, 2376, 2512, 2808,
  3024,
]

const FILESTACK_URL = 'https://cdn.filestackcontent.com'
const FILESTACK_API_URL = 'https://www.filestackapi.com/api'

export const getImgAttributes = (handle: string) => {
  const src = `${FILESTACK_URL}/${handle}`
  const srcSet = SRCSET_IMAGE_WIDTHS.map(
    (width) => `${FILESTACK_URL}/resize=width:${width}/${handle} ${width}w`
  ).join(', ')

  return {
    sizes: SIZES,
    src,
    srcSet,
  }
}

export async function getFilestackImageDimensions(
  id: string
): Promise<{ width: number; height: number }> {
  try {
    const result = await runAsyncWithRetries({
      op: () => axios.get(`${FILESTACK_URL}/imagesize/${id}`),
      opName: 'getImgWithAndHeight',
    })
    return result.data
  } catch (error) {
    logger.error('Failed to get filestack image width and height', error)
    throw error
  }
}

/**
 * Get file exif metadata using the File API: https://www.filestack.com/docs/api/file/#metadata
 * Therefore, we must generate security params, "policy" and "signature", that explicitly request access to the exif data.
 * Explanation for it with example appears in https://www.filestack.com/docs/security/policies/#policies-and-signatures
 *
 * To generate the exif data you can access our filestack account - for example this is the staging link https://dev.filestack.com/apps/Afh8UtFkTeaGp2xeYWtk2z/security/
 * Decoded policy example: {"call":["stat","exif"],"expiry":1707861600}
 */
export async function getFilestackUploadMetadata(
  upload: PickerFileMetadata
): Promise<object> {
  try {
    if (!window.KEYS.filestackExifSecurityParams) {
      logger.warning('filestack exif policy or signature not found')
      return {}
    }
    const exifSecurityParams: Security = JSON.parse(
      window.KEYS.filestackExifSecurityParams
    )

    const response = await runAsyncWithRetries({
      op: () =>
        axios.get(`${FILESTACK_API_URL}/file/${upload.handle}/metadata`, {
          params: {
            exif: true,
            uploaded: true,
            ...exifSecurityParams,
          },
        }),
      opName: 'getFilestackUploadMetadata',
    })

    return response.data || {}
  } catch (error) {
    logger.error('Failed to get filestack upload metadata', error)
    return {}
  }
}
