import { type StateCreator } from 'zustand/vanilla'
import { type Store } from '../../store.types'
import { type CreditSlice } from './creditSlice.types'
import { type TileCredits } from '../promoCodeSlice.types'
import { mixtilesAxios } from '../../../utils/ApiUtils'

export const createCreditSlice: StateCreator<
  Store,
  [['zustand/persist', unknown]],
  [],
  CreditSlice
> = (set, get) => ({
  tileCredits: { credits: [], total_amount: 0 },
  setTileCredits: (tileCredits: TileCredits) => {
    set(() => ({ tileCredits }))
    get().handlePromoCodeChange({ freeTiles: true })
  },
  fetchTileCredits: async () => {
    const response = await mixtilesAxios.get('v1/tileCredits')
    get().setTileCredits(response.data)
  },
  getCreditsFromCoupon: (coupon: string) => {
    return get().tileCredits.credits.find(
      (credit) => credit.sourceCoupon === coupon
    )
  },
  getTilesCreditsToRedeem: (freeTilesCount: number) => {
    if (!get().tileCredits || !freeTilesCount) {
      return []
    }

    const creditsToRedeem = []
    let freeTilesLeftToRedeem = freeTilesCount

    for (const tilesCredit of get().tileCredits.credits) {
      freeTilesLeftToRedeem -= tilesCredit.amount
      if (freeTilesLeftToRedeem > 0) {
        creditsToRedeem.push({
          creditId: tilesCredit.credit_id,
          amount: tilesCredit.amount,
        })
      } else {
        creditsToRedeem.push({
          creditId: tilesCredit.credit_id,
          amount: tilesCredit.amount - Math.abs(freeTilesLeftToRedeem),
        })
        break
      }
    }

    return creditsToRedeem
  },
  getAllTileCredits: () => {
    return get().tileCredits?.credits || []
  },
  getTilesCreditsAmount: () => {
    return get().tileCredits ? get().tileCredits?.total_amount : 0
  },
  isTileCreditsCouponRegistered: (code: string) => {
    const allCoupons = get().getAllTileCredits()
    return allCoupons.findIndex((coupon) => coupon.sourceCoupon === code) !== -1
  },
})
