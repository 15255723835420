import { CURRENCY_CONVERSIONS as conversions } from '@mixtiles/web-backend-shared'
import { mixtilesAxios } from '../utils/ApiUtils'
import { isClient } from '../utils/runtimeUtils'
import { logger } from '../services/logger'

export const CURRENCY_CONVERSIONS = conversions

// Key to store data in localStorage
const LOCAL_STORAGE_KEY = 'currency_conversions'
const TIMESTAMP_KEY = 'currency_conversions_timestamp'
const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000 // 1 day in milliseconds

// Function to fetch live rates and update CURRENCY_CONVERSIONS
async function fetchLiveCurrencyConversions() {
  try {
    const response = await mixtilesAxios.get('/currency/conversions')
    const liveRates = response.data.currencyConversions
    const timestamp = Date.now()

    if (isClient()) {
      // Store rates and timestamp in localStorage
      window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(liveRates))
      window.localStorage.setItem(TIMESTAMP_KEY, timestamp.toString())
    }

    // Update CURRENCY_CONVERSIONS
    Object.assign(CURRENCY_CONVERSIONS, liveRates)
  } catch (error) {
    logger.error(
      'Failed to fetch live currency conversions. Using cached or hardcoded values.',
      error
    )
  }
}

// Function to initialize currency conversions
export function initializeCurrencyConversions() {
  if (!isClient()) {
    return
  }

  const storedRates = window.localStorage.getItem(LOCAL_STORAGE_KEY)
  const storedTimestamp = window.localStorage.getItem(TIMESTAMP_KEY)
  const now = Date.now()

  if (
    storedRates &&
    storedTimestamp &&
    now - parseInt(storedTimestamp, 10) < ONE_DAY_IN_MS
  ) {
    // Use cached rates if they are less than 1 day old
    const cachedRates = JSON.parse(storedRates)
    Object.assign(CURRENCY_CONVERSIONS, cachedRates)
  } else {
    // Fetch live rates if no valid cache or cache is stale
    fetchLiveCurrencyConversions()
  }
}

// Initialize the currency conversions on app startup (only in the browser)
initializeCurrencyConversions()
