export function viewportMeta({
  interactiveWidget,
}: {
  interactiveWidget?: string
}) {
  return [
    'width=device-width',
    'height=device-height',
    'initial-scale=1.0',
    'maximum-scale=1.0',
    'user-scalable=no',
    'shrink-to-fit=no',
    ...(interactiveWidget ? [`interactive-widget=${interactiveWidget}`] : []),
  ].join(', ')
}
