import { TILE_SIZES, TILE_SIZES_ROTATION } from '@mixtiles/web-backend-shared'
import StorageManager from '../../services/StorageManager'
import { MIXED_SIZES } from '../../pages/PhotoStyler/TileDesignerConsts'
import type { TILES_SOURCE } from '../../components/TilesProvider/TilesProvider.consts'
import { getStorageKeyBySource } from '../../components/TilesProvider/TilesSourceContext'

export const SELECTED_SIZE_KEY = 'SelectedSize'

export function setSelectedSize(
  size: string | null,
  tilesSource: TILES_SOURCE
) {
  if (!size) {
    return
  }

  const upperCaseSize = size.toUpperCase()
  if (size === MIXED_SIZES) {
    StorageManager.set(
      getStorageKeyBySource(SELECTED_SIZE_KEY, tilesSource),
      MIXED_SIZES
    )
  } else if (Object.values(TILE_SIZES).includes(upperCaseSize as TILE_SIZES)) {
    StorageManager.set(
      getStorageKeyBySource(SELECTED_SIZE_KEY, tilesSource),
      upperCaseSize
    )
  }
}

export function isRotated(size1: TILE_SIZES, size2: TILE_SIZES) {
  return size1 === TILE_SIZES_ROTATION[size2]
}

export function getDefaultTileSize(): TILE_SIZES {
  return TILE_SIZES.SQUARE_8X8
}

export function getDefaultArtTileSize(): TILE_SIZES {
  return TILE_SIZES.RECTANGLE_12X16
}
