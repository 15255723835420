import { type PhotoFaceData } from '../components/PhotoBank/PhotoBank.types'

export type HappyData = {
  score: number
  faces: number
}

export function parseHappyData(result: PhotoFaceData[]): HappyData {
  if (result.length > 0) {
    const sum: number = result.reduce(
      (acc, cur) => acc + (cur.emotionsScore?.happy ?? 0),
      0
    )
    return { score: sum / result.length, faces: result.length }
  }

  return { score: 0, faces: 0 }
}
