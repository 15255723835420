import { createContext, useContext } from 'react'
import type { KeysConfig } from 'server/keys.server'

export type KeysValue = KeysConfig & {
  ipCountry: string
  ipAddress: string
  ipState: string
  countrySource: string
  forcedCountry?: string
}
export type KeysKey = keyof KeysValue
export const KeysContext = createContext<KeysValue | null>(null)

export const useKeys = () => {
  const keys = useContext(KeysContext)

  if (!keys) {
    throw new Error('Keys not found in KeysContext')
  }

  return keys
}
