import React from 'react'
import StorageManager from '../services/StorageManager'

/**
 * A custom hook for using persistent state
 * @param key - the key to be stored in localStorage
 * @param defaultValue can either be a string or a function returning a string (In case there is heavy computation involved)
 * @returns [state, setState] - The same structure that React.setState returns
 */
function useLocalStorageState(key, defaultValue = '') {
  const [state, setState] = React.useState(() => {
    const valueInLocalStorage = window.localStorage.getItem(key)
    if (valueInLocalStorage) {
      // the try/catch is here in case the localStorage value was set before
      try {
        return StorageManager.get(key)
      } catch (_error) {
        StorageManager.remove(key)
      }
    }
    return typeof defaultValue === 'function' ? defaultValue() : defaultValue
  })

  const prevKeyRef = React.useRef(key)

  React.useEffect(() => {
    const prevKey = prevKeyRef.current
    if (prevKey !== key) {
      StorageManager.remove(key)
    }
    prevKeyRef.current = key
    StorageManager.set(key, state)
  }, [key, JSON.stringify(state)])

  return [state, setState]
}

export default useLocalStorageState
