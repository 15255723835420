import * as queryString from 'query-string'
import {
  TILE_SIZES,
  MATERIAL_TYPES,
  FILTERS,
  FRAME_COLORS,
  MAT_COLORS,
  MATTING,
} from '@mixtiles/web-backend-shared'

import { type ParsedQuery } from 'query-string'
import { type Location } from '@remix-run/router'
import { type NavigateFunction } from '@remix-run/react'
import { PROD_URL } from '../config/config'
import { isClient } from './runtimeUtils'

export function getUrlParams(): ParsedQuery {
  return queryString.parse(window.location.search)
}

export function getUrlParam(param: string): string | null {
  const result = getUrlParams()[param]
  if (Array.isArray(result)) {
    // if multiple params exist with same key, take only the first one
    return result[0]
  }
  return result
}

export function urlParamContains(param: string, substrings: string[]): boolean {
  const urlParam = getUrlParam(param)
  if (!urlParam) {
    return false
  }
  return substrings.some((substring) =>
    urlParam.toLowerCase().includes(substring)
  )
}

const CENTERPIECE_QUERY_PARAM = 'centerpiece_image'
const CENTERPIECE_QUERY_PARAM_TEXT = 'centerpiece_text'
export function parseCenterpieceQueryParams(location: Location) {
  const queryParams = new URLSearchParams(location.search)
  if (!queryParams.has(CENTERPIECE_QUERY_PARAM)) {
    return { centerpieceImage: null, centerpieceText: null }
  }
  const centerpieceImage = queryParams.get(CENTERPIECE_QUERY_PARAM)
  const centerpieceText = queryParams.get(CENTERPIECE_QUERY_PARAM_TEXT)
  return {
    centerpieceImage,
    centerpieceText,
  }
}

// We enable the user to upload photos to the app by sending them in the URL.
// Params is ?photos=URL1|SIZE1|MATIRIAL1|FRAME_COLOR1|MATTING1|MATTING_COLOR1|FILTER1&photos=URL2|SIZE2|MATIRIAL2|FRAME_COLOR2|MATTING2|MATTING_COLOR2|FILTER2
// Example: http://localhost:3000/photos?photos=https://res.cloudinary.com/mixtiles-test/image/upload/v1711954250/gallery_wall_demo/efo6d6xwhimfsp1n4jrc.png&photos=https://res.cloudinary.com/mixtiles-test/image/upload/v1711954250/gallery_wall_demo/efo6d6xwhimfsp1n4jrc.png|RECTANGLE_12X16|wide_frame|white|deep|LIGHT_RED|noir
const PHOTOS_QUERY_PARAM = 'photos'
export function parsePhotosQueryParams(
  location: Location,
  navigate: NavigateFunction
) {
  const queryParams = new URLSearchParams(location.search)
  if (!queryParams.has(PHOTOS_QUERY_PARAM)) {
    return []
  }

  const photosToUpload = queryParams
    .getAll(PHOTOS_QUERY_PARAM)
    .map((photo) => {
      const splitParam = photo.split('|')
      const [
        url,
        tileSize,
        material,
        frameColor,
        matting,
        mattingColor,
        filter,
      ] = splitParam
      if (url === undefined) {
        return null
      }
      return {
        url,
        tileSize:
          tileSize &&
          Object.values(TILE_SIZES).includes(
            tileSize.toUpperCase() as TILE_SIZES
          )
            ? tileSize.toUpperCase()
            : undefined,
        material:
          material &&
          Object.values(MATERIAL_TYPES).includes(material as MATERIAL_TYPES)
            ? material
            : undefined,
        customStyle: {
          filter:
            filter &&
            Object.values(FILTERS).includes(filter.toLowerCase() as FILTERS)
              ? filter
              : FILTERS.NONE,
          frameColor:
            frameColor &&
            Object.values(FRAME_COLORS).includes(frameColor as FRAME_COLORS)
              ? frameColor
              : undefined,
          matting:
            matting && Object.values(MATTING).includes(matting as MATTING)
              ? matting
              : undefined,
          mattingColor:
            mattingColor &&
            Object.keys(MAT_COLORS).includes(mattingColor.toUpperCase())
              ? mattingColor.toUpperCase()
              : undefined,
        },
      }
    })
    .filter((photo) => !!photo)
  queryParams.delete(PHOTOS_QUERY_PARAM)
  navigate(
    {
      search: queryParams.toString(),
    },
    { replace: true }
  )
  return photosToUpload
}

export function getOrigin(): string {
  // used because we also have mixtiles.de. fallback to PROD_URL
  return isClient() && window.location.origin
    ? window.location.origin
    : PROD_URL
}
